interface Props {
    color?: string;
    size?: number;
    onClick?: () => void;
}

const NumberIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
            onClick={props.onClick}
        >
            <path
                d="M3 9V7h4v10H5V9H3zm5-2h4c.547 0 1.017.197 1.41.59.393.393.59.863.59 1.41v2c0 .547-.197 1.017-.59 1.41-.393.393-.863.59-1.41.59h-2v2h4v2H8v-4c0-.553.195-1.025.585-1.415.39-.39.862-.585 1.415-.585h2V9H8V7zm7 0h4c.547 0 1.017.197 1.41.59.393.393.59.863.59 1.41v1.5c0 .413-.147.767-.44 1.06-.293.293-.647.44-1.06.44.413 0 .767.147 1.06.44.293.293.44.647.44 1.06V15c0 .547-.197 1.017-.59 1.41-.393.393-.863.59-1.41.59h-4v-2h4v-2h-2v-2h2V9h-4V7z"
                fillRule="evenodd"
                fill={props.color || '#565454'}
            />
        </svg>
    );
};

export default NumberIcon;
