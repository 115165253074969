import styles from './toast.module.scss';
import CircleCheckIcon from '../../icons/circle-check-icon/circle-check.icon';
import CircleErrorIcon from '../../icons/circle-error-icon/circle-error.icon';
import Loader from '../loader/loader';
import classNames from 'classnames';

export enum ToastType {
    loading = 'loading',
    error = 'error',
    success = 'success',
    warning = 'warning',
}

interface Props {
    state: ToastType;
    text: string;
    shown: boolean;
}

const mapState: Record<ToastType, JSX.Element> = {
    loading: <Loader size={16} color={'white'} />,
    error: <CircleErrorIcon size={20} color={'#fa6c6c'} />,
    success: <CircleCheckIcon size={20} color={'#5CCDAD'} />,
    warning: <span />,
};

const Toast = ({ state, text, shown }: Props) => {
    return (
        <div
            className={classNames({
                [styles.toast]: true,
                [styles.shown]: shown,
            })}
        >
            {mapState[state]}
            <span>{text}</span>
        </div>
    );
};

export default Toast;
