import { CSSProperties } from 'react';

interface Props {
    color?: string;
    size?: number;
    styles?: CSSProperties;
    onClick?: () => void;
}

const NarrowRightIcon = (props: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-arrow-narrow-right"
            width={props.size || 44}
            height={props.size || 44}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke={props.color || '#565454'}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={props.styles}
            onClick={props.onClick}
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12l14 0" />
            <path d="M15 16l4 -4" />
            <path d="M15 8l4 4" />
        </svg>
    );
};

export default NarrowRightIcon;
