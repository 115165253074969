import styles from './module-summary.module.scss';

interface Props {
    mode: string;
    summary: { label: string; value: any }[];
}

const ModuleSummary = (props: Props) => {
    return (
        <aside className={styles.wrapper}>
            {props.summary.map((item, idx) => (
                <div key={idx} className={styles.item}>
                    <label>{item.label}</label>
                    <b>{item.value}</b>
                </div>
            ))}
        </aside>
    );
};

export default ModuleSummary;
