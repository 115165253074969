import { add, format } from 'date-fns';

export default class DateUtil {
    static MONTHS = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agusto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ];

    static getFirstDayOfWeek(d?: Date): Date {
        const date = d ? new Date(d.getTime()) : new Date();
        date.setDate(date.getDate() - date.getDay());
        date.setHours(0, 0, 0, 0);

        return date;
    }

    static getLastDayOfWeek(d?: Date): Date {
        // If it's actually the last day of the week (6th day: saturday), don't do anything
        if (d && d.getDay() === 6) return d;

        // Else, get first day of this week and +6 days to it
        const date = DateUtil.getFirstDayOfWeek(d);
        date.setDate(date.getDate() + 6);
        date.setHours(0, 0, 0, 0);

        return date;
    }

    static getFirstDayOfMonth(d?: Date): Date {
        const date = d ? new Date(d.getTime()) : new Date();
        date.setDate(1);
        date.setHours(0, 0, 0, 0);

        return date;
    }

    static getLastDayOfMonth(d?: Date): Date {
        const date = d ? new Date(d.getTime()) : new Date();
        date.setMonth(date.getMonth() + 1);
        date.setDate(0);
        date.setHours(0, 0, 0, 0);

        return date;
    }

    static get12MonthsFromNow(): string[] {
        const now = new Date();
        const months = [];
        for (let i = 1; i <= 13; i++) {
            months.push(format(add(now, { months: -i }), 'MM-yyyy'));
        }

        return months.reverse();
    }

    static formatDate(date: Date): string {
        return format(date, 'yyyy-MM-dd');
    }
}
