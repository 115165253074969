import { PropsWithChildren } from 'react';

import NarrowRightIcon from '../../icons/narrow-right/narrow-right.icon';

import styles from './link-with-icon.module.scss';

interface Props extends PropsWithChildren {
    link: string;
    backgroundColor?: string;
}

const LinkWithIcon = (props: Props) => {
    const onClick = () => {
        window.open(props.link, '_blank');
    };

    return (
        <div className={styles.link} onClick={onClick}>
            <span>{props.children}</span>
            <div
                className={styles.icon}
                style={{ backgroundColor: props.backgroundColor ?? '#dfdeff' }}
            >
                <NarrowRightIcon
                    size={18}
                    color={'#635bff'}
                    styles={{ transform: 'rotate(-45deg)' }}
                />
            </div>
        </div>
    );
};

export default LinkWithIcon;
