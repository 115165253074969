const InvoiceStatus = {
    CREATED: 0,
    UNPAID: 1,
    PARTIAL_PAYMENT: 2,
    PAID: 3,
    CANCELLED: 4,
    PAYMENT_EXCEEDED: 5,
};

export default InvoiceStatus;
