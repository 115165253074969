import styles from './new-invoice-item-modal.module.scss';
import { useEffect, useState } from 'react';
import { number, object, string } from 'yup';
import NumberUtil from '../../utils/number.util';
import { v4 as uuidv4 } from 'uuid';
import { EditableInvoiceItem } from '../../types/editable-invoice-item.type';

const itemSchema = object().shape({
    description: string().min(3).required(),
    quantity: number().min(1).required(),
    unitPrice: number().required(),
    subtotal: number().required(),
    tax: number().required(),
    total: number().required(),
});

interface Props {
    onDiscard: () => void;
    onConfirm: (item: EditableInvoiceItem) => void;
}

const NewInvoiceItemModal = (props: Props) => {
    const [item, setItem] = useState<EditableInvoiceItem>({
        id: 0,
        temporalId: uuidv4(),
        description: '',
        quantity: 0,
        unitPrice: 0,
        subtotal: 0,
        tax: 0,
        total: 0,
        added: true,
        removed: false,
        createdAt: '',
    });

    useEffect(() => {
        calculateTotals();
    }, [item.unitPrice]);

    const addItemField = (key: string, value: string | undefined) => {
        setItem((prev) => ({
            ...prev,
            [key]: !isNaN(Number(value)) ? Number(value) : value,
        }));
    };

    const calculateTotals = () => {
        if (item.unitPrice === 0 || item.quantity === 0) return;

        const subtotal = NumberUtil.formatAmount(
            item.unitPrice * item.quantity
        );
        const tax = NumberUtil.formatAmount(subtotal * item.quantity * 0.16);
        const total = NumberUtil.formatAmount(subtotal + tax);

        setItem((prev) => ({
            ...prev,
            subtotal,
            tax,
            total,
        }));
    };

    const addNewItem = () => {
        const isValid = itemSchema.isValidSync(item);
        if (!isValid) return;

        props.onConfirm(item);
        props.onDiscard();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.fade} onClick={props.onDiscard} />
            <div className={styles.modal}>
                <form>
                    <h3>Datos del nuevo item para lal factura</h3>
                    <div className={styles.row}>
                        <div className={styles.fieldContainer}>
                            <label>Descripción</label>
                            <input
                                type={'text'}
                                placeholder={'Descripción'}
                                onChange={(ev) =>
                                    addItemField('description', ev.target.value)
                                }
                            />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label>Cantidad</label>
                            <input
                                type={'number'}
                                placeholder={'Cant.'}
                                onChange={(ev) =>
                                    addItemField('quantity', ev.target.value)
                                }
                            />
                        </div>
                        <div className={styles.fieldContainer}>
                            <label>Precio en $</label>
                            <input
                                type={'number'}
                                placeholder={'$$$'}
                                onChange={(ev) =>
                                    addItemField('unitPrice', ev.target.value)
                                }
                            />
                        </div>
                    </div>
                </form>
                <div className={styles.buttons}>
                    <button className={styles.active} onClick={addNewItem}>
                        Añadir nuevo item
                    </button>
                    <button onClick={props.onDiscard}>Descartar</button>
                </div>
            </div>
        </div>
    );
};

export default NewInvoiceItemModal;
