import { User } from '../types/user.type';
import SubscriptionStatus from '../constants/subscription.status';

export const mapSubscriptionStatus = (status: number) => {
    const mapper: {
        [key: number]: { text: string; color: string; background: string };
    } = {
        0: {
            text: 'Expirada',
            color: '#af8d07',
            background: '#f7f1d5',
        },
        1: {
            text: 'Activa',
            color: '#217005',
            background: '#d1fab3',
        },
        2: {
            text: 'Suspendida',
            color: '#af8d07',
            background: '#f7f1d5',
        },
        3: {
            text: 'Inactiva',
            color: '#707270',
            background: '#ececec',
        },
    };
    return mapper[status];
};

export const mapUserHasSubscription = (
    user: User
): {
    value: string;
    txtColor: string;
    bgColor: string;
} => {
    const noSubscription = {
        value: 'No posee',
        txtColor: '#707270',
        bgColor: '#ececec',
    };
    const inactiveSubscription = {
        value: 'Vencida',
        txtColor: '#af8d07',
        bgColor: '#f7f1d5',
    };

    const hasSubscription = {
        value: 'Activa',
        txtColor: '#217005',
        bgColor: '#d1fab3',
    };

    if (!user.subscriptions || user.subscriptions.length === 0)
        return noSubscription;

    const activeSubscriptions = user.subscriptions.filter(
        (subscription) => subscription.status === SubscriptionStatus.ACTIVE
    );

    if (activeSubscriptions.length === 0) return inactiveSubscription;

    return hasSubscription;
};

export const mapUserSubscriptionDescription = (user: User): string => {
    if (!user.subscriptions || user.subscriptions.length === 0)
        return '😵 Sin suscripción';

    const activeSubscriptions = user.subscriptions.filter(
        (subscription) => subscription.status === SubscriptionStatus.ACTIVE
    );

    if (activeSubscriptions.length === 0) return '😞 Suscripción vencida';

    return '😀 Suscripción activa';
};
