import { ReactElement } from 'react';
import styles from './radio-selector.module.scss';

interface Option {
    name: string;
    value: any;
    checked?: boolean;
}

interface Props {
    mode?: string;
    name: string;
    options: Option[];
    direction?: 'row' | 'column';
    onChange?: (value: any) => void;
}

const RadioSelector = (props: Props) => {
    const buildOptions = (options: Option[]): ReactElement[] => {
        return options.map((option, idx) => (
            <label key={idx} className={styles.option}>
                <input
                    type={'radio'}
                    value={option.value}
                    name={props.name}
                    checked={option.checked}
                    onChange={() => onSelect(option.value)}
                />
                {option.name}
            </label>
        ));
    };

    const onSelect = (value: any) => {
        props.onChange?.(value);
    };

    return (
        <div
            className={styles.container}
            style={{ flexDirection: props.direction || 'row' }}
        >
            {buildOptions(props.options)}
        </div>
    );
};

export default RadioSelector;
