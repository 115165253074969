import styles from './gift-card-graphic.module.scss';
import Logo from '../../assets/img/logo-gift-card.svg';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

interface Props {
    data: {
        email: string;
        code?: string;
    };
    turnAround: boolean;
}

const GiftCardGraphic = (props: Props) => {
    const [flip, setFlip] = useState(false);
    const [backZIndex, setBackZIndex] = useState(0);

    useEffect(() => {
        flipCard(props.turnAround);
    }, [props.turnAround]);

    const flipCard = (turn: boolean) => {
        if (turn) {
            setFlip(true);
            setTimeout(() => {
                setBackZIndex(1);
            }, 450);
        } else {
            setFlip(false);
            setTimeout(() => {
                setBackZIndex(0);
            }, 480);
        }
    };

    return (
        <div
            className={classNames({
                [styles.giftCard]: true,
                [styles.flip]: flip,
            })}
        >
            {/* Gift card back side */}
            <div className={styles.back} style={{ zIndex: backZIndex }}>
                <div className={styles.content}>
                    <span className={styles.code}>
                        {props.data.code?.toUpperCase()}
                    </span>
                </div>
            </div>
            {/* Gift card front side */}
            <div className={styles.front}>
                <div className={styles.content}>
                    <img
                        className={styles.logo}
                        src={Logo}
                        alt={'gift-card-graphic-logo'}
                    />
                    <span className={styles.userEmail}>
                        {props.data.email.toLowerCase()}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default GiftCardGraphic;
