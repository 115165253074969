import { useEffect, useState } from 'react';
import { number, object } from 'yup';

import Header from '../../components/header/header.component';
import HDivider from '../../components/h-divider/h-divider.component';
import GoBackView from '../../components/go-back-view/go-back-view';
import SearchUserInput from '../../components/search-user-input/search-user-input.component';
import { userSdk } from '../../services/user.service';
import ResponseCodes from '../../constants/response.codes';
import CompanySearchInput from '../../components/company-search-input/company-search-input';
import { subscriptionSdk } from '../../services/subscription.service';
import CustomSelect from '../../components/custom-select/custom-select.component';
import { Plan } from '../../types/plan.type';
import BasicButton from '../../components/basic-button/basic-button.component';

import styles from './add-user-to-company.module.scss';
import Toast, { ToastType } from '../../components/toast/toast';

type FormData = {
    userId: number;
    companyId: number;
    planId: number;
};

const formSchema = object().shape({
    userId: number().min(1).required(),
    companyId: number().min(1).required(),
    planId: number().min(1).required(),
});

const AddUserToCompanyView = () => {
    const [usersList, setUsersList] = useState([]);
    const [companiesList, setCompaniesList] = useState([]);
    const [plans, setPlans] = useState<Plan[]>([]);
    const [form, setForm] = useState<FormData>({
        userId: 0,
        companyId: 0,
        planId: 0,
    });
    const [isValidForm, setIsValidForm] = useState(false);
    const [toast, setToast] = useState({
        state: ToastType.loading,
        text: '',
        shown: false,
    });

    useEffect(() => {
        getActivePlans();
    }, []);

    useEffect(() => {
        checkForm(form);
    }, [form]);

    const onSearchUser = async (query: string) => {
        const response = await userSdk.search(query);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('error fetching users:', response);
        }
        setUsersList(response.data.result);
    };

    const onSearchCompany = async (query: string) => {
        const response = await userSdk.searchCompanyByName(query);
        if (response.code === ResponseCodes.PROCESS_OK) {
            setCompaniesList(response.data);
        }
    };

    const getActivePlans = async () => {
        const response = await subscriptionSdk.getPlans({
            isForBusiness: true,
            active: true,
        });
        if (response.code !== ResponseCodes.PROCESS_OK) return;
        setPlans(response.data);
    };

    const addFormField = (key: keyof FormData, value: number) => {
        setForm((prev) => ({ ...prev, [key]: value }));
    };

    const checkForm = (form: FormData) => {
        setIsValidForm(formSchema.isValidSync(form));
    };

    const includeUserToCompany = async () => {
        setIsValidForm(false);
        setToast({
            state: ToastType.loading,
            text: 'Cargando...',
            shown: true,
        });
        const response = await subscriptionSdk.includeUserToCompany(form);
        setIsValidForm(true);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return setToast({
                state: ToastType.error,
                text: response.message,
                shown: true,
            });
        }

        return setToast({
            state: ToastType.success,
            text: 'Usuario incluido exitosamente',
            shown: true,
        });
    };

    return (
        <main className={styles.view}>
            <Header
                title={'Incluir usuario'}
                subtitle={'Añade un usuario pre-existente a una compañía'}
            />
            <HDivider />
            <GoBackView />
            <form>
                <div className={styles.formRow}>
                    <label>Busar usuario</label>
                    <p className={styles.formFieldDescription}>
                        Puedes buscar por nombre, correo, cédula o teléfono
                    </p>
                    <SearchUserInput
                        results={usersList}
                        onSearch={onSearchUser}
                        onSelect={(user) => addFormField('userId', user.id)}
                    />
                </div>

                <div className={styles.formRow}>
                    <label>Busar compañía</label>
                    <p className={styles.formFieldDescription}>
                        Puedes buscar por nombre
                    </p>
                    <CompanySearchInput
                        companies={companiesList}
                        onSearch={onSearchCompany}
                        onSelect={(companyId) =>
                            addFormField('companyId', companyId)
                        }
                    />
                </div>
                <div className={styles.formRow}>
                    <label>Plan de suscripción</label>
                    <CustomSelect
                        placeholder={'Selecciona un plan'}
                        options={plans.map((plan) => ({
                            displayText: plan.description,
                            value: plan.id,
                        }))}
                        onSelect={(planId) => addFormField('planId', planId)}
                        width={200}
                    />
                </div>
                <BasicButton
                    text={'Incluir usuario'}
                    style={{ marginTop: 20 }}
                    disabled={!isValidForm}
                    onClick={includeUserToCompany}
                />
            </form>
            <Toast state={toast.state} text={toast.text} shown={toast.shown} />
        </main>
    );
};

export default AddUserToCompanyView;
