import axios, { AxiosError } from 'axios';
import { getAuthToken, handleResponseError } from './service';
import { ServiceResponse } from '../types/service-response.type';

const REMOTE_CONFIG_BASE_URL = process.env.REACT_APP_REMOTE_CONFIG_API;

const getAll = async (): Promise<ServiceResponse> => {
    console.log('RemoteConfigService: getAll');
    try {
        const response = await axios.get(`${REMOTE_CONFIG_BASE_URL}/config`, {
            headers: { Authorization: getAuthToken() },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const update = async (id: string, data: any): Promise<ServiceResponse> => {
    console.log('RemoteConfigService: getAll');
    try {
        const response = await axios.put(
            `${REMOTE_CONFIG_BASE_URL}/config/${id}`,
            data,
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const deleteParam = async (id: string): Promise<ServiceResponse> => {
    console.log('RemoteConfigService: getAll');
    try {
        const response = await axios.delete(
            `${REMOTE_CONFIG_BASE_URL}/config/${id}`,
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const remoteConfigSdk = {
    getAll,
    update,
    deleteParam,
};
