import PaymentStatus from '../constants/payment.status';

export const mapPaymentStatus = (
    status?: number
): {
    value: string;
    txtColor: string;
    bgColor: string;
} => {
    switch (status) {
        case PaymentStatus.APPROVED:
            return {
                value: 'Aprobado',
                txtColor: '#07AF25',
                bgColor: '#D5F7DB',
            };
        case PaymentStatus.REJECTED:
            return {
                value: 'Rechazado',
                txtColor: '#af1507',
                bgColor: '#f7d5d5',
            };
        case PaymentStatus.CANCELLED:
            return {
                value: 'Cancelado',
                txtColor: '#af1507',
                bgColor: '#f7d5d5',
            };
        case PaymentStatus.REFUNDED:
            return {
                value: 'Reembolsado',
                txtColor: '#635bff',
                bgColor: '#dfdeff',
            };
        case PaymentStatus.CREATED:
        case PaymentStatus.WAITING_APPROVAL:
        default:
            return {
                value: 'En aprobación',
                txtColor: '#af6107',
                bgColor: '#f7e4d5',
            };
    }
};

export const mapPaymentStatusColor = (status: number): string => {
    switch (status) {
        case PaymentStatus.APPROVED:
            return '#82fb70';
        case PaymentStatus.REJECTED:
        case PaymentStatus.CANCELLED:
            return '#fb7070';
        case PaymentStatus.REFUNDED:
            return '#635bff';
        case PaymentStatus.CREATED:
        case PaymentStatus.WAITING_APPROVAL:
        default:
            return '#fbbc70';
    }
};

export const mapPaymentStatusDescription = (status?: number): string => {
    switch (status) {
        case PaymentStatus.APPROVED:
            return 'Aprobado';
        case PaymentStatus.REJECTED:
            return 'Rechazado';
        case PaymentStatus.CANCELLED:
            return 'Cancelado';
        case PaymentStatus.REFUNDED:
            return 'Reintegrado';
        case PaymentStatus.CREATED:
        case PaymentStatus.WAITING_APPROVAL:
        default:
            return 'Esperando aprobación';
    }
};

export const mapPaymentStatusToComponent = (status?: number) => {
    const attrs: { text: string; textColor: string; backgroundColor: string } =
        { text: '', textColor: '', backgroundColor: '' };

    switch (status) {
        case PaymentStatus.APPROVED:
            attrs.text = 'Aprobado';
            attrs.textColor = '#07AF25';
            attrs.backgroundColor = '#D5F7DB';
            break;
        case PaymentStatus.REJECTED:
            attrs.text = 'Rechazado';
            attrs.textColor = '#af1507';
            attrs.backgroundColor = '#f7d5d5';
            break;
        case PaymentStatus.CANCELLED:
            attrs.text = 'Cancelado';
            attrs.textColor = '#af1507';
            attrs.backgroundColor = '#f7d5d5';
            break;
        case PaymentStatus.REFUNDED:
            attrs.text = 'Reembolsado';
            attrs.textColor = '#635bff';
            attrs.backgroundColor = '#dfdeff';
            break;
        case PaymentStatus.CREATED:
        case PaymentStatus.WAITING_APPROVAL:
        default:
            attrs.text = 'En aprobación';
            attrs.textColor = '#af6107';
            attrs.backgroundColor = '#f7e4d5';
            break;
    }

    return (
        <span
            style={{
                color: attrs.textColor,
                backgroundColor: attrs.backgroundColor,
                padding: '0 6px',
                borderRadius: 4,
                fontWeight: 400,
                fontSize: 15,
                userSelect: 'none',
            }}
        >
            {attrs.text}
        </span>
    );
};
