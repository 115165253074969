import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { compareAsc, format } from 'date-fns';
import classNames from 'classnames';

import DateIcon from '../../icons/date-icon/date-icon';

import styles from './date-range-picker.module.scss';
import IDate from './date.type';
import { Day } from './day.component';
import GoNextIcon from './go-next.icon';
import GoBackIcon from './go-back.icon';

type DateRange = {
    from?: Date;
    to?: Date;
};

const DAYS_OF_THE_WEEK = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];
const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];

interface Props {
    mode?: string;
    defaultRange?: { from: Date; to: Date };
    onSelect?: (date: { from: Date; to: Date }) => void;
}

export const DateRangePicker = (props: Props) => {
    const datepickerRef = useRef<HTMLDivElement | null>(null);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [dateRange, setDateRange] = useState<DateRange>({});
    const [daysOfMonth, setDaysOfMonth] = useState<IDate[]>([]);
    const [showPicker, setShowPicker] = useState(false);

    useEffect(() => {
        if (props.defaultRange) {
            setDateRange(props.defaultRange);
        } else {
            setDateRange({ from: new Date(), to: new Date() });
        }
    }, []);

    useEffect(() => {
        getDaysOfCurrentMonth();
    }, [currentMonth]);

    const showDatePicker = () => {
        setShowPicker(true);
        setTimeout(() => {
            datepickerRef.current?.focus();
        }, 50);
    };

    const hideDatePicker = () => {
        setShowPicker(false);
    };

    const getDaysOfCurrentMonth = () => {
        const date = new Date();
        date.setDate(1); // Set it to the first day of the month
        date.setMonth(currentMonth.getMonth());
        date.setFullYear(currentMonth.getFullYear());
        date.setHours(0, 0, 0, 0); // Set date to midnight: timestamp 00:00:00

        const listOfDays = [];
        while (date.getMonth() === currentMonth.getMonth()) {
            listOfDays.push({
                day: date.getDay(),
                date: date.getDate(),
                month: date.getMonth(),
                year: date.getFullYear(),
            });

            date.setDate(date.getDate() + 1);
        }
        setDaysOfMonth(listOfDays);
    };

    const selectDate = (date: IDate) => {
        const newDate = new Date(date.year, date.month, date.date);
        newDate.setHours(0, 0, 0, 0);
        if (dateRange.to) {
            setDateRange({ from: newDate, to: undefined });
        } else {
            setDateRange((prev) => ({ ...prev, to: newDate }));
        }
    };

    const checkIfDateIsSelected = (d: IDate): boolean => {
        let date: Date | string = new Date(d.year, d.month, d.date);
        date.setHours(0, 0, 0, 0);
        date = date.toDateString();
        return (
            dateRange.from?.toDateString() === date ||
            dateRange.to?.toDateString() === date
        );
    };

    const checkIfDateIsInterval = (d: IDate): boolean => {
        const date = new Date(d.year, d.month, d.date);

        if (!dateRange.from || !dateRange.to) return false;

        return (
            compareAsc(date, dateRange.from) === 1 &&
            compareAsc(date, dateRange.to) === -1
        );
    };

    const checkIfIsFrom = (d: IDate): boolean => {
        if (!dateRange.to) return false;

        const date = new Date(d.year, d.month, d.date);
        date.setHours(0, 0, 0, 0);

        return dateRange.from?.toDateString() === date.toDateString();
    };

    const checkIfIsTo = (d: IDate): boolean => {
        const date = new Date(d.year, d.month, d.date);
        date.setHours(0, 0, 0, 0);

        return dateRange.to?.toDateString() === date.toDateString();
    };

    const goPreviousMonth = () => {
        const newMonth = new Date(currentMonth.getTime());
        newMonth.setDate(1);
        newMonth.setMonth(newMonth.getMonth() - 1);
        setCurrentMonth(newMonth);
    };

    const goNextMonth = () => {
        const newMonth = new Date(currentMonth.getTime());
        newMonth.setDate(1);
        newMonth.setMonth(newMonth.getMonth() + 1);
        setCurrentMonth(newMonth);
    };

    const submitRange = () => {
        props.onSelect?.({
            from: dateRange.from!,
            to: dateRange.to!,
        });
        hideDatePicker();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.iconContainer} onClick={showDatePicker}>
                <DateIcon size={16} color={'#8d4b2a'} />
            </div>
            <section className={styles.dateRange} onClick={showDatePicker}>
                <h4>FECHAS</h4>
                <div className={styles.dates}>
                    <span>
                        {dateRange.from
                            ? format(dateRange.from, 'dd, MMM yyyy')
                            : '-'}
                    </span>
                    <span>/</span>
                    <span>
                        {dateRange.to
                            ? format(dateRange.to, 'dd, MMM yyyy')
                            : '-'}
                    </span>
                </div>
            </section>
            {showPicker && (
                <div
                    className={styles.datepicker}
                    ref={datepickerRef}
                    tabIndex={1}
                    onBlur={hideDatePicker}
                >
                    <header>
                        <h5>
                            {MONTHS[currentMonth.getMonth()]}&nbsp;
                            {currentMonth.getFullYear()}
                        </h5>
                        <div className={styles.buttonsContainer}>
                            <div className={styles.buttonWrapper}>
                                <GoBackIcon
                                    size={12}
                                    onClick={goPreviousMonth}
                                />
                            </div>
                            <div className={styles.buttonWrapper}>
                                <GoNextIcon size={12} onClick={goNextMonth} />
                            </div>
                        </div>
                    </header>

                    <ul className={styles.daysOfWeekList}>
                        {DAYS_OF_THE_WEEK.map((day, idx) => (
                            <li key={idx}>{day}</li>
                        ))}
                    </ul>

                    <div className={styles.daysContainer}>
                        {daysOfMonth.map((day, idx) => {
                            return (
                                <Day
                                    key={idx}
                                    day={day}
                                    idx={idx}
                                    isFrom={checkIfIsFrom(day)}
                                    isTo={checkIfIsTo(day)}
                                    isSelected={checkIfDateIsSelected(day)}
                                    isInterval={checkIfDateIsInterval(day)}
                                    onSelect={selectDate}
                                />
                            );
                        })}
                    </div>

                    <div
                        onClick={submitRange}
                        className={classNames({
                            [styles.sendDatesButton]: true,
                            [styles.disabled]: !dateRange.from || !dateRange.to,
                        })}
                    >
                        Aplicar
                    </div>
                </div>
            )}
        </div>
    );
};
