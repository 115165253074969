interface Props {
    color?: string;
    size?: number;
    onClick?: () => void;
}

const PaperIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            fill="none"
            onClick={() => props.onClick?.()}
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.5 1H4.5C3.39999 1 2.5 1.89999 2.5 3V17H4.5V3H16.5V1ZM15.5 5H8.5C7.4 5 6.51 5.89999 6.51 7L6.5 21C6.5 22.1 7.39 23 8.49 23H19.5C20.6 23 21.5 22.1 21.5 21V11L15.5 5ZM8.5 7V21H19.5V12H14.5V7H8.5Z"
                    fill={props.color || '#565454'}
                />
            </g>
        </svg>
    );
};

export default PaperIcon;
