const PaymentStatus = {
    CREATED: 0,
    APPROVED: 1,
    REJECTED: 2,
    CANCELLED: 3,
    WAITING_APPROVAL: 5,
    REFUNDED: 6,
};

export default PaymentStatus;
