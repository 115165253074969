import ReactDOM from 'react-dom/client';
import {
    Chart,
    ArcElement,
    Tooltip,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
} from 'chart.js';

import './index.scss';
import './styles/styles.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import App from './app';

// Registering Chart.JS components before initialization
Chart.register(
    ArcElement,
    Tooltip,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement
);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
