import { Vehicle } from '../../types/vehicle.type';

import styles from './vehicles.module.scss';
import BasicButton from '../../components/basic-button/basic-button.component';
import { generateUUID } from '../../utils/uuid.util';

interface Props {
    vehicles?: Vehicle[];
    onAdd: () => void;
}

const Vehicles = ({ vehicles, onAdd }: Props) => {
    const renderVehiclesList = (vehicles: Vehicle[]) => {
        return (
            <ul>
                {vehicles.map((vehicle) => (
                    <li key={generateUUID()}>
                        <span>
                            {vehicle.brand} - {vehicle.model}
                            <i>|</i>
                            Color: {vehicle.color}
                        </span>
                        <span>
                            Place: {vehicle.plate}
                            <i>|</i>
                            Seguro: {vehicle.insurance ?? <i>No posee</i>}
                        </span>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <section className={styles.wrapper}>
            <h3>
                Vehículos
                <BasicButton
                    text={'+ Añadir vehículo'}
                    style={{ height: 28 }}
                    onClick={onAdd}
                />
            </h3>
            {!vehicles || vehicles.length === 0 ? (
                <p className={styles.noVehicles}>
                    El usuario no tiene vehículos agregados
                </p>
            ) : (
                renderVehiclesList(vehicles)
            )}
        </section>
    );
};

export default Vehicles;
