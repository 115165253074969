import { LegacyRef } from 'react';

interface Props {
    inputRef: LegacyRef<HTMLInputElement>;
    onChange: (value: string) => void;
}

const TextFilter = ({ inputRef, onChange }: Props) => {
    return (
        <span>
            es igual a{' '}
            <input
                ref={inputRef}
                type={'text'}
                onChange={(ev) => onChange(ev.target.value.trim())}
            />
        </span>
    );
};

export default TextFilter;
