import classNames from 'classnames';
import Avatar from 'boring-avatars';
import { useState } from 'react';

import { User } from '../../../types/user.type';
import CustomInput from '../../../components/custom-input/custom-input.component';
import PhoneInput from '../../../components/phone-input/phone-input.component';
import BasicButton from '../../../components/basic-button/basic-button.component';
import { UpdateUserBasicInfo } from '../../../types/update-user-basic-info.type';

import styles from './user-basic-info.module.scss';

interface Props {
    user: User;
    onUpdate: (data: UpdateUserBasicInfo) => void;
    updating?: boolean;
}

const UserBasicInfo = ({ user, onUpdate, updating }: Props) => {
    const [updatedProfile, setUpdatedProfile] =
        useState<UpdateUserBasicInfo>(user);

    const updateProfileInfo = (
        key: keyof UpdateUserBasicInfo,
        value: string
    ) => {
        setUpdatedProfile((prev) => ({ ...prev, [key]: value }));
    };

    const updateProfile = () => {
        onUpdate({
            firstName: updatedProfile.firstName,
            lastName: updatedProfile.lastName,
            phoneNumber: updatedProfile.phoneNumber,
            email: updatedProfile.email,
            document: updatedProfile.document,
        });
    };

    return (
        <section id={styles.basicInfo}>
            <div className={styles.avatarContainer}>
                <Avatar size={80} name={user?.firstName ?? '-'} />
            </div>

            <div className={'column'}>
                <label>Nombre completo</label>
                <div
                    className={classNames({
                        [styles.row]: true,
                        [styles.inputContainer]: true,
                    })}
                >
                    <CustomInput
                        placeholder={'Nombre'}
                        value={updatedProfile.firstName}
                        onChange={(value) =>
                            updateProfileInfo('firstName', value)
                        }
                        hideClear={true}
                    />
                    <CustomInput
                        placeholder={'Apellido'}
                        value={updatedProfile.lastName}
                        onChange={(value) =>
                            updateProfileInfo('lastName', value)
                        }
                        hideClear={true}
                    />
                </div>

                <label>Dirección de correo</label>
                <div
                    className={classNames({
                        [styles.row]: true,
                        [styles.inputContainer]: true,
                    })}
                >
                    <CustomInput
                        placeholder={'Email'}
                        width={'100%'}
                        value={updatedProfile.email}
                        onChange={(value) => updateProfileInfo('email', value)}
                        hideClear={true}
                    />
                </div>

                <label>Teléfono</label>
                <div
                    className={classNames({
                        [styles.row]: true,
                        [styles.inputContainer]: true,
                    })}
                >
                    <PhoneInput
                        placeholder={'Teléfono'}
                        value={updatedProfile.phoneNumber}
                        width={'100%'}
                        countryISOCode={'VE'}
                        onChange={(phone) =>
                            updateProfileInfo('phoneNumber', phone)
                        }
                    />
                </div>
                <label>Documento de identidad</label>
                <div
                    className={classNames({
                        [styles.row]: true,
                        [styles.inputContainer]: true,
                    })}
                >
                    <CustomInput
                        width={'100%'}
                        placeholder={'Documento de identidad'}
                        value={updatedProfile.document}
                        onChange={(value) =>
                            updateProfileInfo('document', value)
                        }
                        hideClear={true}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <BasicButton
                        text={updating ? 'Actualizando...' : 'Actualizar'}
                        width={'fit-content'}
                        onClick={updateProfile}
                        disabled={updating}
                    />
                </div>
            </div>
        </section>
    );
};

export default UserBasicInfo;
