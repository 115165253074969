import styles from './user-search-filter.module.scss';
import DateIcon from '../../icons/date-icon/date-icon';
import UserIcon from '../../icons/user-icon/user.icon';
import CustomInput from '../custom-input/custom-input.component';

interface Props {
    mode: string;
    onSearch: (value: string) => void;
}

const UserSearchFilter = (props: Props) => {
    let searchTimeout: NodeJS.Timeout;

    const onSearch = (filter: string) => {
        if (searchTimeout) clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
            props.onSearch(filter);
        }, 1500);
    };

    return (
        <section className={styles.wrapper}>
            <CustomInput
                placeholder={'Nombre, email, cédula, tlf...'}
                mode={props.mode}
                icon={<UserIcon size={18} color={'#bcbec0'} />}
                onChange={onSearch}
            />
            <CustomInput
                placeholder={'Fecha de creación'}
                mode={props.mode}
                icon={<DateIcon size={18} color={'#bcbec0'} />}
            />
        </section>
    );
};

export default UserSearchFilter;
