interface Props {
    color?: string;
    size?: number;
    onClick?: () => void;
    className?: string;
}

const DeleteIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => props.onClick?.()}
            className={props.className}
        >
            <g id="delete_24px">
                <path
                    id="icon/action/delete_24px"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM16 9V19H8V9H16ZM6 7H18V19C18 20.1 17.1 21 16 21H8C6.90002 21 6 20.1 6 19V7Z"
                    fill={props.color || '#565454'}
                />
            </g>
        </svg>
    );
};

export default DeleteIcon;
