import styles from './filtering-button.module.scss';
import { useState, MouseEvent, useEffect, useRef, LegacyRef } from 'react';
import classNames from 'classnames';
import TextFilter from './text-filter';
import DateFilter from './date-filter';

type FilterType = 'text' | 'date';

interface Props {
    text: string;
    onSetFilter: (filter: string) => void;
    type?: FilterType;
}

const inputByTypeMap: Record<FilterType, (...data: any) => JSX.Element> = {
    text: (inputRef, onChange) => (
        <TextFilter inputRef={inputRef} onChange={onChange} />
    ),
    date: (inputRef, onChange) => (
        <DateFilter inputRef={inputRef} onChange={onChange} />
    ),
};

const FilteringButton = (props: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [filteringValue, setFilteringValue] = useState('');
    const [modalShown, setModalShown] = useState(false);
    const [canClear, setCanClear] = useState(false);

    useEffect(() => {
        setCanClear(filteringValue.trim().length > 0);
    }, [filteringValue]);

    const toggleModal = () => {
        setModalShown(!modalShown);
    };

    const updateFilteringValue = (value: string) => {
        setFilteringValue(value);
    };

    const ifExistsValue = (): boolean => {
        return filteringValue.trim().length > 0;
    };

    const applyFilter = (ev: MouseEvent<HTMLButtonElement>) => {
        ev.preventDefault();
        props.onSetFilter(filteringValue);
        setModalShown(false);
    };

    const clearFilter = (ev: MouseEvent<HTMLElement>) => {
        if (filteringValue.trim().length === 0) {
            return toggleModal();
        }

        ev.stopPropagation();
        setFilteringValue('');
        inputRef.current!.value = '';
        props.onSetFilter('');
    };

    const renderInputByType = (
        type: FilterType,
        inputRef: LegacyRef<any>,
        onChange: (value: string) => void
    ): JSX.Element => {
        return inputByTypeMap[type](inputRef, onChange);
    };

    const displaySelectedValue = (value: string, type: FilterType) => {
        switch (type) {
            case 'date':
                if (Number(value) < 2) return 'Último día';
                return `Últimos ${value} días`;
            case 'text':
            default:
                return value;
        }
    };

    return (
        <div className={styles.container}>
            <span className={styles.button} onClick={toggleModal}>
                <i
                    className={classNames({ [styles.canClear]: canClear })}
                    onClick={clearFilter}
                >
                    +
                </i>{' '}
                {props.text}
                {ifExistsValue() && (
                    <div className={styles.valueContainer}>
                        <div className={styles.separator} />
                        <span className={styles.value}>
                            {displaySelectedValue(
                                filteringValue,
                                props.type ?? 'text'
                            )}
                        </span>
                    </div>
                )}
            </span>
            {/* Modal */}
            <div
                className={classNames({
                    [styles.filteringModal]: true,
                    [styles.shown]: modalShown,
                })}
            >
                <header>
                    <h5>Filtrar por {props.text}</h5>
                    <i onClick={toggleModal} style={{ fontSize: 14 }}>
                        ✖
                    </i>
                </header>
                {renderInputByType(
                    props.type ?? 'text',
                    inputRef,
                    updateFilteringValue
                )}
                <button onClick={applyFilter}>Aplicar filtro</button>
            </div>
        </div>
    );
};

export default FilteringButton;
