interface Props {
    mode?: string;
    thickness?: number;
    color?: string;
}

const HDivider = (props: Props) => {
    return (
        <span
            style={{
                width: '100%',
                height: props.thickness ?? 1,
                backgroundColor: props.color ?? '#d8dee7',
            }}
        />
    );
};

export default HDivider;
