import styles from './go-back-view.module.scss';
import ArrowLeftIcon from '../../icons/arrow-left-icon/arrow-left.icon';
import { useNavigate } from 'react-router-dom';

const GoBackView = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.goBack} onClick={() => navigate(-1)}>
            <ArrowLeftIcon size={16} />
            Volver
        </div>
    );
};

export default GoBackView;
