import styles from './invoice-details.module.scss';
import NumberUtil from '../../utils/number.util';
import MoneyImage from '../../assets/img/money.png';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import classNames from 'classnames';
import { Invoice } from '../../types/invoice.type';
import { mapInvoiceStatus } from '../../utils/invoice-status.util';
import EditIcon from '../../icons/edit-icon/edit.icon';
import SendIcon from '../../icons/send-icon/send.icon';
import PaymentDetailsCard from '../payment-details-card/payment-details-card.component';
import { useNavigate } from 'react-router-dom';

interface Props {
    invoice: Invoice;
    onEditing: () => void;
}

const InvoiceDetails = (props: Props) => {
    const navigate = useNavigate();

    const mapStatus = (status: number) => {
        const statusObj = mapInvoiceStatus(status);
        return (
            <span
                className={styles.status}
                style={{
                    color: statusObj.txtColor,
                    backgroundColor: statusObj.bgColor,
                }}
            >
                {statusObj.value}
            </span>
        );
    };

    return (
        <main className={styles.wrapper}>
            <section>
                <div className={styles.invoice}>
                    <header>
                        <div>
                            <h5>Factura para</h5>
                            <h4>
                                {props.invoice.payer.firstName}{' '}
                                {props.invoice.payer.lastName ?? ''}
                            </h4>
                        </div>
                        <div>
                            <h5>Total de</h5>
                            <h4>
                                {NumberUtil.formatCurrency(
                                    props.invoice.total || 0,
                                    'USD'
                                )}
                            </h4>
                        </div>
                    </header>

                    <img src={MoneyImage} className={styles.moneyImage} />

                    <div className={styles.details}>
                        <h5>📝 Detalles de la factura</h5>

                        {/* Payer */}
                        <div className={styles.row}>
                            <div
                                className={styles.col}
                                style={{
                                    borderRight: '3px dotted #d8dee7',
                                    width: '50%',
                                }}
                            >
                                <label>Estatus:</label>
                                {mapStatus(props.invoice.status || 0)}
                            </div>
                            <div className={styles.col}>
                                <label>Creada el:</label>
                                <span>
                                    {format(
                                        new Date(
                                            props.invoice.createdAt ||
                                                Date.now()
                                        ),
                                        'dd MMMM, yyyy',
                                        { locale: es }
                                    )}
                                </span>
                            </div>
                        </div>

                        {/* Items */}
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <label>Items:</label>
                                {props.invoice.items.map((item, idx) => (
                                    <span key={idx}>
                                        {item.description || item.itemableType}
                                    </span>
                                ))}
                            </div>
                            <div className={styles.col}>
                                <label>Precio:</label>
                                {props.invoice.items.map((item, idx) => (
                                    <b key={idx}>
                                        {NumberUtil.formatCurrency(
                                            item.total,
                                            'USD'
                                        )}
                                    </b>
                                ))}
                            </div>
                        </div>

                        {/* Totals */}
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <span>Subtotal</span>
                                <span>Impuestos</span>
                                <span className={styles.total}>Total</span>
                            </div>
                            <div className={styles.col}>
                                <b>
                                    {NumberUtil.formatCurrency(
                                        props.invoice.subtotal || 0,
                                        'USD'
                                    )}
                                </b>
                                <b>
                                    {NumberUtil.formatCurrency(
                                        props.invoice.tax || 0,
                                        'USD'
                                    )}
                                </b>
                                <b
                                    className={classNames({
                                        [styles.total]: true,
                                        [styles.totalAmount]: true,
                                    })}
                                >
                                    {NumberUtil.formatCurrency(
                                        props.invoice.total || 0,
                                        'USD'
                                    )}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Payment list */}
                {props.invoice.payments.length > 0 && (
                    <section className={styles.payments}>
                        <h3>Pagos:</h3>
                        {props.invoice.payments.map((payment, idx) => (
                            <PaymentDetailsCard
                                key={idx}
                                payment={payment}
                                onLink={(id) => navigate(`/payments/${id}`)}
                            />
                        ))}
                    </section>
                )}
            </section>

            <footer className={styles.actionButtons}>
                <button
                    className={styles.active}
                    onClick={() => props.onEditing()}
                >
                    <EditIcon color={'#1f206c'} size={18} />
                    <span>Editar</span>
                </button>
                <button>
                    <SendIcon size={18} color={'#5c636b'} />
                    <u>Enviar factua</u>
                </button>
            </footer>
        </main>
    );
};

export default InvoiceDetails;
