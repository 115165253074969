import { Route, Routes } from 'react-router-dom';

import LoginView from './views/login/login.view';
import ProtectedRoute from './components/protected-route/protected-route.component';
import NotFoundView from './views/not-found/not-found.view';
import { screens } from './views';
import { generateUUID } from './utils/uuid.util';
import styles from './app.module.scss';

const App = () => {
    return (
        <div id={styles.app}>
            <Routes>
                <Route path={'login'} element={<LoginView />} />
                {/* Authenticated routes*/}
                <Route element={<ProtectedRoute />}>
                    {screens.map((screen) => (
                        <Route
                            key={generateUUID()}
                            path={screen.path}
                            element={screen.element}
                        />
                    ))}
                </Route>
                <Route path={'*'} element={<NotFoundView />} />
            </Routes>
        </div>
    );
};

export default App;
