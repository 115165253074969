import { getAuthToken, handleResponseError, addQueryParams } from './service';
import axios, { AxiosError } from 'axios';
import { ServiceResponse } from '../types/service-response.type';
import { PaginatedQuery } from '../types/paginated-query.type';
import { EditableInvoiceItem } from '../types/editable-invoice-item.type';

type QueryParams = {
    payerId?: number;
    status?: number;
    createdAt?: string;
} & PaginatedQuery;

const PAYMENTS_BASE_URL = process.env.REACT_APP_PAYMENTS_API;

const getAll = async (queryParams?: QueryParams): Promise<ServiceResponse> => {
    console.log('Invoice Service: getting all invoices with query params');
    try {
        const inlineParams = addQueryParams(queryParams);
        const response = await axios.get(
            `${PAYMENTS_BASE_URL}/invoices${inlineParams}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getById = async (id: number): Promise<ServiceResponse> => {
    console.log('Invoice Service: getting invoice by id');
    try {
        const response = await axios.get(
            `${PAYMENTS_BASE_URL}/invoices/${id}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const updateStatus = async (
    id: number,
    status: number
): Promise<ServiceResponse> => {
    console.log('Invoice Service: update invoice status');
    try {
        const response = await axios.patch(
            `${PAYMENTS_BASE_URL}/invoices/${id}/status`,
            { status },
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const updateItems = async (
    id: number,
    items: EditableInvoiceItem[]
): Promise<ServiceResponse> => {
    console.log('Invoice Service: update invoice status');
    try {
        const response = await axios.patch(
            `${PAYMENTS_BASE_URL}/invoices/${id}/items`,
            { items },
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const removeItem = async (itemId: number): Promise<ServiceResponse> => {
    console.log('Invoice Item Service: removing item');
    try {
        const response = await axios.get(
            `${PAYMENTS_BASE_URL}/invoice-items/${itemId}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const invoiceSdk = {
    getAll,
    getById,
    updateStatus,
    updateItems,
    removeItem,
};
