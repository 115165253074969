import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/header/header.component';
import BasicButton from '../../components/basic-button/basic-button.component';
import Table from '../../components/table/table.component';
import { TableRow } from '../../types/table-row.type';
import { userSdk } from '../../services/user.service';
import ResponseCodes from '../../constants/response.codes';
import { Company } from '../../types/company.type';
import FilteringButton from '../../components/filtering-button/filtering-button.component';
import { cleanObject } from '../../utils/object.util';
import CreateCompany from '../../components/create-company/create-company';
import { CompanyCreation } from '../../types/company-creation.type';
import Toast, { ToastType } from '../../components/toast/toast';
import Modal from '../../components/modal/modal.component';
import FileInput from '../../components/file-input/file-input';
import CustomSelect, {
    SelectOption,
} from '../../components/custom-select/custom-select.component';
import { useServiceStore } from '../../store';
import { Plan } from '../../types/plan.type';
import { subscriptionSdk } from '../../services/subscription.service';
import CompanySearchInput from '../../components/company-search-input/company-search-input';

import styles from './companies.module.scss';

type SubmitBulkUpload = {
    company?: number;
    action: number;
    planId?: number;
    file: File;
};

const CompaniesView = () => {
    const navigate = useNavigate();

    const { plans, fetchPlans } = useServiceStore();
    const [filters, setFilters] = useState<Record<any, any>>({});
    const [tableRows, setTableRows] = useState<TableRow[]>([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        count: 10,
    });
    const [createCompanyShown, setCreateCompanyShown] = useState(false);
    const [toast, setToast] = useState({
        state: ToastType.loading,
        text: 'Cargando...',
        shown: false,
    });
    const [bulkUploadShown, setBulkUploadShown] = useState(false);
    const [searchedCompanies, setSearchedCompanies] = useState<Company[]>([]);

    useEffect(() => {
        fetchCompanies({
            page: pagination.currentPage,
            count: pagination.count,
        });
    }, []);

    useEffect(() => {
        fetchCompanies({
            page: pagination.currentPage,
            count: 10,
            ...cleanObject(filters),
        });
    }, [filters]);

    useEffect(() => {
        bulkUploadShown && fetchPlans({ active: true });
    }, [bulkUploadShown]);

    const goToPage = (page: number) => {
        if (page < 1 || page > pagination.totalPages) return;

        fetchCompanies({
            ...filters,
            page: page,
            count: 10,
        });
    };

    const fetchCompanies = async (params: any) => {
        const response = await userSdk.getCompanies(params);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('Error getting companies:', response);
        }
        mapTableRows(response.data);
    };

    const mapTableRows = (data: any) => {
        setPagination((pagination) => ({
            ...pagination,
            currentPage: Number(data.page),
            totalPages:
                data.total === 0 ? 1 : Math.ceil(data.total / pagination.count),
        }));
        setTableRows(
            data.result.map((company: Company) => ({
                id: company.id,
                data: [
                    { type: 'number', value: company.id },
                    {
                        type: 'string',
                        value: company.displayName ?? company.name,
                    },
                    { type: 'string', value: company.contact?.name ?? '--' },
                    { type: 'string', value: company.contact?.address ?? '--' },
                    {
                        type: 'date',
                        value: new Date(company.createdAt),
                    },
                ],
            }))
        );
    };

    const addFilter = (attr: string, value: string) => {
        if (!value || value.toString().trim().length === 0) {
            setFilters((prev: any) => ({ ...prev, [attr]: undefined }));
            return;
        }
        setFilters((prev: any) => ({ ...prev, [attr]: value }));
    };

    const goToCompanyDetails = (id: number) => navigate(`/companies/${id}`);

    const createCompany = async (data: CompanyCreation) => {
        setCreateCompanyShown(false);
        setToast({
            text: 'Cargando...',
            state: ToastType.loading,
            shown: true,
        });
        const response = await userSdk.createCompany(data);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.error('Error:', response);
            setToast({
                text: `Error cargando la empresa: ${response.message}`,
                state: ToastType.error,
                shown: true,
            });
        } else {
            setToast({
                text: 'Empresa creada existosamente',
                state: ToastType.success,
                shown: true,
            });
            fetchCompanies({
                ...filters,
                page: 1,
                count: 10,
            });
        }
        setTimeout(() => {
            setToast((toast) => ({ ...toast, shown: false }));
        }, 4000);
    };

    const submitBulkUpload = async (data: SubmitBulkUpload) => {
        setBulkUploadShown(false);
        setToast({
            state: ToastType.loading,
            text: 'Cargando archivo...',
            shown: true,
        });
        const response = await subscriptionSdk.bulkUpload(data);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.error('Error en carga masiva:', response);
            setToast({
                state: ToastType.error,
                text: `Error cargando archivo: ${response.message}`,
                shown: true,
            });
        } else {
            setToast({
                state: ToastType.success,
                text: `Archivo cargado exitosamente`,
                shown: true,
            });
        }
        setTimeout(() => {
            setToast((toast) => ({ ...toast, shown: false }));
        }, 4000);
    };

    const searchCompanyByName = async (query: string) => {
        const response = await userSdk.searchCompanyByName(query);
        if (response.code === ResponseCodes.PROCESS_OK) {
            setSearchedCompanies(response.data);
        }
    };

    return (
        <main className={styles.view}>
            <Header
                title={'Compañías'}
                subtitle={'Lista de las compañias registradas en PANA'}
            />
            <article className={styles.mainButtonsContainer}>
                <BasicButton
                    text={'Crear compañía'}
                    onClick={() => setCreateCompanyShown(true)}
                />
                <BasicButton
                    text={'Incluir usuario corporativo'}
                    onClick={() => navigate('/companies/add-user')}
                />
                <BasicButton
                    text={'Carga masiva de usuarios'}
                    onClick={() => setBulkUploadShown(true)}
                />
            </article>
            <div className={'view-filtering-section'}>
                <span className={'filtering-label'}>Buscar por:</span>
                <FilteringButton
                    text={'ID'}
                    onSetFilter={(id) => addFilter('id', id)}
                />
                <FilteringButton
                    text={'Nombre'}
                    onSetFilter={(amount) => addFilter('name', amount)}
                />
            </div>
            <Table
                header={[
                    'ID',
                    'Nombre',
                    'Persona contacto',
                    'Dirección',
                    'Creada el',
                ]}
                data={tableRows}
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                onPageChange={goToPage}
                onItemSelected={goToCompanyDetails}
            />
            {createCompanyShown && (
                <CreateCompany
                    onDiscard={() => setCreateCompanyShown(false)}
                    onConfirm={createCompany}
                />
            )}
            {bulkUploadShown && (
                <BulkUploadModal
                    companies={searchedCompanies}
                    plans={plans}
                    onSearchCompany={searchCompanyByName}
                    onDiscard={() => setBulkUploadShown(false)}
                    onSubmit={submitBulkUpload}
                />
            )}
            <Toast state={toast.state} text={toast.text} shown={toast.shown} />
        </main>
    );
};

const BulkUploadModal = ({
    plans,
    companies,
    onSearchCompany,
    onDiscard,
    onSubmit,
}: {
    plans: Plan[];
    companies: Company[];
    onSearchCompany: (query: string) => void;
    onDiscard: () => void;
    onSubmit: (data: SubmitBulkUpload) => void;
}) => {
    const [company, setCompany] = useState<number>();
    const [action, setAction] = useState(0);
    const [plan, setPlan] = useState<number>();
    const [file, setFile] = useState<File>();
    const [errorMessage, setErrorMessage] = useState('');

    const mapPlansToOptions = (plans: Plan[]): SelectOption[] => {
        return plans.map((plan) => ({
            value: plan.id,
            displayText: plan.description,
        }));
    };

    const submitData = () => {
        if (!file) {
            return setErrorMessage('Debes seleccionar un archivo');
        }
        if (!action) {
            return setErrorMessage('Debes seleccionar una acción');
        }
        if (action === 1 && !plan) {
            return setErrorMessage('Debes seleccionar un plan');
        }
        if (!company) {
            return setErrorMessage('Debes seleccionar una compañía');
        }
        setErrorMessage('');
        onSubmit({
            planId: plan,
            action: action,
            file: file,
            company: company,
        });
    };

    return (
        <Modal onConfirm={submitData} onDiscard={onDiscard}>
            <div className={styles.bulkUploadModal}>
                <h4>Carga masiva de usuarios corporativos</h4>
                <span>
                    Escoge el archivo que contenga los usuarios que quieres
                    activar/desactivar
                </span>
                <FileInput
                    accept={['text/csv']}
                    placeholder={'Haz click para escoger un archivo'}
                    onFile={setFile}
                />
                <label>Qué acción deseas realizar</label>
                <CustomSelect
                    options={[
                        { value: 1, displayText: 'Activar' },
                        { value: 2, displayText: 'Desactivar' },
                    ]}
                    placeholder={'Escoge la acción'}
                    width={'100%'}
                    onSelect={setAction}
                />
                {action === 1 && (
                    <>
                        <label>Escoge un plan</label>
                        <CustomSelect
                            options={mapPlansToOptions(plans)}
                            placeholder={'Escoge el plan'}
                            width={'100%'}
                            onSelect={setPlan}
                        />
                    </>
                )}
                <label>Selecciona la compañía</label>
                <CompanySearchInput
                    placeholder={'Escribe el nombre de la compañía'}
                    companies={companies}
                    onSearch={onSearchCompany}
                    onSelect={setCompany}
                />
                {errorMessage && (
                    <span className={styles.errorMessage}>{errorMessage}</span>
                )}
            </div>
        </Modal>
    );
};

export default CompaniesView;
