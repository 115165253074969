interface Props {
    color?: string;
    size?: number;
    onClick?: () => void;
}

const ObjectIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
        >
            <path
                d="M8 8v1.68c0 .4-.075.795-.225 1.185a3.62 3.62 0 01-.625 1.055c.567.58.85 1.273.85 2.08v2c0 .273.098.508.295.705A.962.962 0 009 17h1v2H9a2.89 2.89 0 01-2.12-.88A2.89 2.89 0 016 16v-2a.962.962 0 00-.295-.705A.962.962 0 005 13H4v-2h1c.26 0 .492-.147.695-.44.203-.293.305-.587.305-.88V8c0-.827.293-1.533.88-2.12A2.89 2.89 0 019 5h1v2H9a.962.962 0 00-.705.295A.962.962 0 008 8zm11 3h1v2h-1a.962.962 0 00-.705.295A.962.962 0 0018 14v2a2.89 2.89 0 01-.88 2.12A2.89 2.89 0 0115 19h-1v-2h1a.962.962 0 00.705-.295A.962.962 0 0016 16v-2c0-.807.283-1.5.85-2.08a3.62 3.62 0 01-.625-1.055A3.277 3.277 0 0116 9.68V8a1.008 1.008 0 00-1-1h-1V5h1a2.89 2.89 0 012.12.88A2.89 2.89 0 0118 8v1.68c0 .293.102.587.305.88.203.293.435.44.695.44z"
                fillRule="evenodd"
                fill={props.color || '#565454'}
            />
        </svg>
    );
};

export default ObjectIcon;
