import classNames from 'classnames';

import SubscriptionStatus from '../../../constants/subscription.status';
import styles from './subscription-details-popover.module.scss';

const POSSIBLE_ACTIVE_STATUS = [
    SubscriptionStatus.ACTIVE,
    SubscriptionStatus.SUSPENDED,
];

export enum SubscriptionAction {
    UPDATE_DATA = 'UPDATE_DATA',
    SUSPEND = 'SUSPEND',
    INACTIVATE = 'INACTIVATE',
}

interface Props {
    shown: boolean;
    top: number;
    subscriptionStatus?: number;
    onDiscard: () => void;
    onAction: (action: SubscriptionAction) => void;
}

const SubscriptionDetailsPopover = ({
    shown,
    top,
    subscriptionStatus,
    onAction,
    onDiscard,
}: Props) => {
    const isActive = subscriptionStatus
        ? POSSIBLE_ACTIVE_STATUS.includes(subscriptionStatus)
        : false;
    const isSuspended = subscriptionStatus === SubscriptionStatus.SUSPENDED;

    return (
        <div>
            <div
                className={classNames({
                    [styles.subscriptionPopoverOverlay]: true,
                    [styles.shown]: shown,
                })}
                onClick={onDiscard}
            />
            <div
                className={classNames({
                    [styles.subscriptionMoreDetailsPopover]: true,
                    [styles.shown]: shown,
                })}
                style={{ top }}
            >
                <ul>
                    <li className={!isActive ? styles.disabled : undefined}>
                        <a>{isSuspended ? 'Reanudar' : 'Pausar'} suscripción</a>
                    </li>
                    <li>
                        <a>Actualizar suscripción</a>
                    </li>
                    <div className={styles.separator} />
                    <li className={!isActive ? styles.disabled : undefined}>
                        <a
                            className={styles.danger}
                            onClick={() =>
                                onAction(SubscriptionAction.INACTIVATE)
                            }
                        >
                            Cancelar suscripción
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export { SubscriptionDetailsPopover };
