interface Props {
    color?: string;
    size?: number;
    onClick?: () => void;
}

const StringIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
        >
            <path
                d="M6 7c.553 0 1.025.195 1.415.585.39.39.585.862.585 1.415v8H6v-2H4v2H2V9c0-.553.195-1.025.585-1.415C2.975 7.195 3.447 7 4 7h2zM4 9v4h2V9H4zm11 1.5c0 .413-.147.767-.44 1.06-.293.293-.647.44-1.06.44.413 0 .767.147 1.06.44.293.293.44.647.44 1.06V15c0 .553-.195 1.025-.585 1.415-.39.39-.862.585-1.415.585H9V7h4c.553 0 1.025.195 1.415.585.39.39.585.862.585 1.415v1.5zM11 13v2h2v-2h-2zm0-4v2h2V9h-2zm5 6V9c0-.553.195-1.025.585-1.415.39-.39.862-.585 1.415-.585h2c.553 0 1.025.195 1.415.585.39.39.585.862.585 1.415v1.5h-2V9h-2v6h2v-1.5h2V15c0 .547-.197 1.017-.59 1.41-.393.393-.863.59-1.41.59h-2a1.924 1.924 0 01-1.41-.59A1.924 1.924 0 0116 15z"
                fillRule="evenodd"
                fill={props.color || '#565454'}
            />
        </svg>
    );
};

export default StringIcon;
