interface Props {
    color?: string;
    size?: number;
}

const SendIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.21 4.5L21.5 12L3.21 19.5L2.5 18.79L5.5 12L2.5 5.21L3.21 4.5ZM5.8 16.28L16.23 12L5.8 7.72L7.33 11.19L7.69 12L7.33 12.81L5.8 16.28Z"
                    fill={props.color || '#565454'}
                />
            </g>
        </svg>
    );
};

export default SendIcon;
