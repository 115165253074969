interface Props {
    color?: string;
    size?: number;
    onClick?: () => void;
}

const ThreeDotsIcon = ({ color, size, onClick }: Props) => {
    return (
        <svg
            aria-hidden="true"
            height={size}
            width={size}
            viewBox="0 0 16 16"
            fill="none"
            onClick={onClick}
        >
            <path
                fill={color || '#565454'}
                d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm5.5 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm-11 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
            />
        </svg>
    );
};

export default ThreeDotsIcon;
