import { useState } from 'react';
import { object, string } from 'yup';

import CustomInput from '../../../components/custom-input/custom-input.component';
import NidInput from '../../../components/nid-input/nid-input';
import PhoneInput from '../../../components/phone-input/phone-input.component';
import Modal from '../../../components/modal/modal.component';
import { UserCreation } from '../../../types/user.type';

import styles from './create-user-modal.module.scss';

const formSchema = object().shape({
    firstName: string().min(3).required(),
    lastName: string().min(3).required(),
    email: string().email().required(),
    phoneNumber: string().min(7).required(),
    documentType: string().oneOf(['V', 'E', 'P']).required(),
    document: string().min(6).required(),
});

interface Props {
    loading: boolean;
    onCreate: (data: UserCreation) => void;
    onDiscard: () => void;
}

const CreateUserModal = ({ loading, onCreate, onDiscard }: Props) => {
    const [form, setForm] = useState<UserCreation>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        documentType: '',
        document: '',
    });

    const addForm = (field: string, value: string) => {
        setForm((prev) => ({ ...prev, [field]: value }));
    };

    const requestCreation = () => {
        const isValid = formSchema.isValidSync(form);
        if (!isValid) return;
        onCreate(form);
    };

    return (
        <Modal
            onConfirm={requestCreation}
            confirmText={'Crear usuario'}
            confirmLoading={loading}
            onDiscard={onDiscard}
        >
            <div className={styles.header}>
                <h4>Crear usuario</h4>
            </div>
            <form className={styles.userCreationForm}>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <label>Nombre</label>
                        <CustomInput
                            type={'text'}
                            width={'100%'}
                            onChange={(firstName) =>
                                addForm('firstName', firstName)
                            }
                        />
                    </div>
                    <div className={styles.column}>
                        <label>Apellido</label>
                        <CustomInput
                            type={'text'}
                            width={'100%'}
                            onChange={(lastName) =>
                                addForm('lastName', lastName)
                            }
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <label>Correo electrónico</label>
                        <CustomInput
                            type={'email'}
                            width={'100%'}
                            onChange={(email) => addForm('email', email)}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <label>Número de documento</label>
                        <NidInput
                            onInput={({ type, document }) => {
                                addForm('documentType', type);
                                addForm('document', document);
                            }}
                        />
                    </div>
                    <div className={styles.column}>
                        <label>Teléfono</label>
                        <PhoneInput
                            onChange={(phoneNumber) =>
                                addForm('phoneNumber', phoneNumber)
                            }
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default CreateUserModal;
