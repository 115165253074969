const removeEmptyFields = (entity: any) => {
    const newModel: any = {};
    const keys = Object.keys(entity);
    for (const key of keys) {
        const stringifyField = entity[key]?.toString() ?? '';
        if (!stringifyField || stringifyField.length === 0) continue;
        newModel[key] = entity[key];
    }
    return newModel;
};

export { removeEmptyFields };
