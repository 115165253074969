import styles from './invoices.module.scss';
import Header from '../../components/header/header.component';
import Table from '../../components/table/table.component';
import { invoiceSdk } from '../../services/invoice.service';
import { useEffect, useState } from 'react';
import ResponseCodes from '../../constants/response.codes';
import { Invoice } from '../../types/invoice.type';
import NumberUtil from '../../utils/number.util';
import { mapInvoiceStatus } from '../../utils/invoice-status.util';
import { useNavigate } from 'react-router-dom';
import ModuleSummary from '../../components/module-summary/module-summary.component';
import { TableRow } from '../../types/table-row.type';
import BasicButton from '../../components/basic-button/basic-button.component';
import { useThemeStore } from '../../store';
import FilteringButton from '../../components/filtering-button/filtering-button.component';
import { cleanObject } from '../../utils/object.util';

const InvoicesView = () => {
    const { theme } = useThemeStore();
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        count: 10,
    });
    const [filters, setFilters] = useState<Record<any, any>>({});
    const [invoiceTableRows, setInvoiceTableRows] = useState<TableRow[]>([]);

    useEffect(() => {
        fetchInvoices({
            page: pagination.currentPage,
            count: pagination.count,
            ...cleanObject(filters),
        });
    }, [filters]);

    const fetchInvoices = async (queryParams?: any) => {
        const response = await invoiceSdk.getAll(queryParams);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.error('Error getting invoices:', response);
            return;
        }
        setPagination((pagination) => ({
            ...pagination,
            currentPage: Number(response.data.page),
            totalPages:
                response.data.total === 0
                    ? 1
                    : Math.ceil(response.data.total / pagination.count),
        }));
        mapRows(response.data.result);
    };

    const mapRows = (invoices: Invoice[]) => {
        const invoiceRows = invoices.map((invoice) => {
            return {
                id: invoice.id,
                data: [
                    { type: 'number', value: invoice.id },
                    {
                        type: 'string',
                        value: `${invoice.payer.firstName} ${
                            invoice.payer.lastName ?? ''
                        }`,
                    },
                    {
                        type: 'string',
                        value: NumberUtil.formatCurrency(invoice.total, 'USD'),
                    },
                    { type: 'status', value: mapInvoiceStatus(invoice.status) },
                    {
                        type: 'date',
                        value: new Date(invoice.createdAt),
                    },
                ],
            };
        });

        setInvoiceTableRows(invoiceRows);
    };

    const goToPage = (page: number) => {
        if (page < 1 || page > pagination.totalPages) return;

        fetchInvoices({
            page: page,
            count: 10,
        });
    };

    const goToInvoiceDetails = (id: number) => navigate(`/invoices/${id}`);

    const goToCreateInvoice = () => {
        navigate('/invoices/create');
    };

    const addFilter = (attr: string, value: string) => {
        if (!value || value.toString().trim().length === 0) {
            setFilters((prev: any) => ({ ...prev, [attr]: undefined }));
            return;
        }
        setFilters((prev: any) => ({ ...prev, [attr]: value }));
    };

    return (
        <main className={styles.view}>
            <Header
                mode={theme}
                title={'Facturas'}
                subtitle={'Aquí puedes ver el estado de todas las facturas'}
            />
            <ModuleSummary
                mode={theme}
                summary={[
                    { label: 'Total pagos pendientes', value: '-' },
                    { label: 'Facturas pendientes del mes', value: '-' },
                    { label: 'Total facturas del mes', value: '-' },
                    { label: 'Fecha última factura', value: '-' },
                ]}
            />
            <article className={styles.mainButtonsContainer}>
                <BasicButton
                    text={'Crear nueva factura'}
                    onClick={goToCreateInvoice}
                />
            </article>
            <div className={'view-filtering-section'}>
                <span className={'filtering-label'}>Buscar por:</span>
                <FilteringButton
                    text={'ID de factura'}
                    onSetFilter={(id) => addFilter('id', id)}
                />
            </div>
            <Table
                mode={theme}
                header={[
                    'N° Factura',
                    'Pagador',
                    'Total',
                    'Estado',
                    'Fecha creación',
                ]}
                data={invoiceTableRows}
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                onItemSelected={goToInvoiceDetails}
                onPageChange={goToPage}
            />
        </main>
    );
};

export default InvoicesView;
