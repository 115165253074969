import styles from './download-excel-button.module.scss';
import { useEffect, useState } from 'react';
import RightArrowIcon from '../../icons/arrow-right-icon/arrow-right.icon';
import { biSdk } from '../../services/bi.service';
import classNames from 'classnames';
import CircleWarningIcon from '../../icons/circle-warning.icon';
import { generateDownloadableFile } from '../../utils/download.util';

interface Props {
    entity: string;
}

const DownloadExcelButton = ({ entity }: Props) => {
    const [buttonText, setButtonText] = useState('Descargar excel');
    const [loading, setLoading] = useState(false);
    const [showPeriodList, setShowPeriodList] = useState(false);
    const [period, setPeriod] = useState<number | undefined>();

    useEffect(() => {
        if (!period) return;
        requestDownloadExcel();
    }, [period]);

    const togglePeriodList = () => setShowPeriodList(!showPeriodList);

    const requestDownloadExcel = async () => {
        setShowPeriodList(false);
        setButtonText('Cargando...');
        setLoading(true);

        const response = await biSdk.downloadExcel(entity, period!);

        generateDownloadableFile(response, 'pagos.xlsx');
        setButtonText('Descargar excel');
        setLoading(false);
    };

    return (
        <div className={styles.container}>
            <button
                className={styles.button}
                disabled={loading}
                onClick={togglePeriodList}
            >
                {buttonText}
                {!loading && (
                    <RightArrowIcon
                        color={'white'}
                        size={14}
                        className={styles.icon}
                    />
                )}
            </button>
            {showPeriodList && (
                <ul className={styles.periodList}>
                    <li onClick={() => setPeriod(30)}>Últimos 30 días</li>
                    <li onClick={() => setPeriod(90)}>Últimos 2 meses</li>
                    <li onClick={() => setPeriod(120)}>Últimos 3 meses</li>
                </ul>
            )}
            <div
                className={classNames({
                    [styles.toast]: true,
                    [styles.shown]: loading,
                })}
            >
                <CircleWarningIcon color={'#4cda64'} />
                Espera mientras se descarga el archivo
            </div>
        </div>
    );
};

export default DownloadExcelButton;
