import styles from './coupon-details.module.scss';
import { useParams } from 'react-router-dom';
import { couponSdk } from '../../services/coupon.service';
import { useEffect, useState } from 'react';
import ResponseCodes from '../../constants/response.codes';
import { Coupon } from '../../types/coupon.type';
import Header from '../../components/header/header.component';
import CouponGraphic from '../../components/coupon-graphic/coupon-graphic.component';
import CustomInput from '../../components/custom-input/custom-input.component';
import DateInput from '../../components/date-input/date-input.component';
import GoBackView from '../../components/go-back-view/go-back-view';
import { useThemeStore } from '../../store';

const CouponDetailsView = () => {
    const { ['id']: couponId } = useParams();
    const { theme } = useThemeStore();
    const [coupon, setCoupon] = useState<Coupon>();

    useEffect(() => {
        fetchCouponDetails(Number(couponId));
    }, [couponId]);

    const fetchCouponDetails = async (id: number) => {
        const response = await couponSdk.getById(id);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.log('Error getting coupon details:', response);
            return;
        }
        console.log('coupon:', response.data);

        setCoupon(response.data as Coupon);
    };

    return (
        <main className={styles.view}>
            <Header
                mode={theme}
                title={'Detalles del cupón'}
                subtitle={'Aquí puedes el detalle completo de este cupón'}
            />
            <section className={styles.content}>
                <GoBackView />

                <div className={styles.row}>
                    <form>
                        <div className={styles.block}>
                            <label>¿Quieres cambiar el código?</label>
                            <CustomInput
                                placeholder={'Código'}
                                defaultValue={coupon?.code}
                            />
                        </div>
                        <div className={styles.block}>
                            <label>Modifica el % de descuento</label>
                            <CustomInput
                                placeholder={'Descuento'}
                                defaultValue={coupon?.discount.toString()}
                            />
                        </div>
                        <div className={styles.block}>
                            <label>Modifica la fecha de exp</label>
                            <DateInput />
                        </div>
                    </form>

                    <div className={styles.separator} />

                    <div className={styles.couponContainer}>
                        <CouponGraphic
                            code={coupon?.code || ''}
                            discount={coupon?.discount || 0}
                        />
                    </div>
                </div>
            </section>
        </main>
    );
};

export default CouponDetailsView;
