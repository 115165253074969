import { AxiosError } from 'axios';
import { ServiceResponse } from '../types/service-response.type';

export const getAuthToken = (): string => {
    const user = localStorage.getItem('user');
    if (!user) return '';

    const { accessToken } = JSON.parse(user);

    return `Bearer ${accessToken}`;
};

export const handleResponseError = (error: AxiosError): ServiceResponse => {
    if (!error.response) {
        console.error('[!] Internet connection error');
        return {
            code: -999,
            message: 'Internet connection error',
        };
    }

    return error.response.data as ServiceResponse;
};

export const addQueryParams = (
    params: { [key: string]: any } | undefined
): string => {
    let inlineParams = '';
    if (!params) return inlineParams;
    const keys = Object.keys(params);
    let idx = 0;

    for (const key of keys) {
        if (!params[key] || params[key].toString().trim() === '') {
            continue;
        }

        if (idx === 0) {
            inlineParams += `?${key}=${params[key]}`;
        } else {
            inlineParams += `&${key}=${params[key]}`;
        }

        idx++;
    }

    return inlineParams;
};
