import axios, { AxiosError } from 'axios';
import { ServiceResponse } from '../types/service-response.type';
import { PaginatedQuery } from '../types/paginated-query.type';
import { addQueryParams, getAuthToken, handleResponseError } from './service';
import { CompanyCreation } from '../types/company-creation.type';
import { VehicleCreation } from '../types/vehicle.type';

type QueryParams = {
    status: number;
} & PaginatedQuery;

const USERS_BASE_URL = process.env.REACT_APP_USERS_API;
const USERS_ADMIN_URL = process.env.REACT_APP_USERS_ADMIN_API;

const getAll = async (queryParams?: QueryParams): Promise<ServiceResponse> => {
    console.log('User Service: getting all users with query params');
    try {
        const inlineParams = addQueryParams(queryParams);
        const response = await axios.get(
            `${USERS_BASE_URL}/users${inlineParams}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getById = async (
    id: number,
    includes?: string[]
): Promise<ServiceResponse> => {
    console.log('User Service: getting user by id');
    try {
        const params = addQueryParams({ includes: includes?.join(',') });
        const response = await axios.get(
            `${USERS_ADMIN_URL}/users/${id}${params}`,
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const update = async (id: number, data: any): Promise<ServiceResponse> => {
    console.log('User Service: updating user by id');
    try {
        const response = await axios.put(
            `${USERS_ADMIN_URL}/users/${id}`,
            data,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const search = async (name: string): Promise<ServiceResponse> => {
    console.log('User Service: searching by name');
    try {
        const response = await axios.get(
            `${USERS_BASE_URL}/users?search=${name}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const searchCompanyByName = async (name: string): Promise<ServiceResponse> => {
    console.log('User Service: searching company by name');
    try {
        const response = await axios.get(
            `${USERS_ADMIN_URL}/companies/search?name=${name}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getCompanies = async (params: any): Promise<ServiceResponse> => {
    console.log('User Service: fetch companies');
    try {
        const inlineParams = addQueryParams(params);
        const response = await axios.get(
            `${USERS_ADMIN_URL}/companies${inlineParams}`,
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getCompanyDetails = async (id: number): Promise<ServiceResponse> => {
    console.log('User Service: fetch company details');
    try {
        const response = await axios.get(`${USERS_ADMIN_URL}/companies/${id}`, {
            headers: { Authorization: getAuthToken() },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const createCompany = async (
    data: CompanyCreation
): Promise<ServiceResponse> => {
    console.log('User Service: fetch company createCompany');
    try {
        const response = await axios.post(
            `${USERS_ADMIN_URL}/companies`,
            data,
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getCompanyUsers = async (id: number): Promise<ServiceResponse> => {
    console.log('User Service: fetch company getCompanyUsers');
    try {
        const response = await axios.get(
            `${USERS_ADMIN_URL}/companies/${id}/users`,
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const addVehicle = async (
    userId: number,
    vehicle: VehicleCreation
): Promise<ServiceResponse> => {
    console.log('User Service: add vehicle to user');
    try {
        const response = await axios.post(
            `${USERS_ADMIN_URL}/users/${userId}/vehicles`,
            vehicle,
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

type UserCreation = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    documentType: string;
    document: string;
};

const createUserByAdmin = async (
    data: UserCreation
): Promise<ServiceResponse> => {
    console.log('User Service: create user by admin');
    try {
        const response = await axios.post(`${USERS_ADMIN_URL}/users`, data, {
            headers: { Authorization: getAuthToken() },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const userSdk = {
    getAll,
    getById,
    update,
    search,
    getCompanies,
    getCompanyDetails,
    createCompany,
    getCompanyUsers,
    addVehicle,
    searchCompanyByName,
    createUserByAdmin,
};
