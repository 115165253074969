import styles from './remove-invoice-item-modal.module.scss';
import { EditableInvoiceItem } from '../../types/editable-invoice-item.type';

interface Props {
    item: EditableInvoiceItem;
    onDiscard: () => void;
    onConfirm: (itemId: number | string) => void;
}

const RemoveInvoiceItemModal = (props: Props) => {
    return (
        <div className={styles.modalWrapper}>
            <div className={styles.fade} onClick={props.onDiscard} />
            <div className={styles.removeItemModal}>
                <div className={styles.text}>
                    <p>¿Deseas eliminar este item de la factura?</p>
                    <b>{props.item.description || props.item.itemableType}</b>
                </div>
                <div className={styles.buttons}>
                    <button
                        className={styles.active}
                        onClick={() =>
                            props.onConfirm(
                                props.item.temporalId || props.item.id
                            )
                        }
                    >
                        Eliminar item
                    </button>
                    <button onClick={props.onDiscard}>No, mantener</button>
                </div>
            </div>
        </div>
    );
};

export default RemoveInvoiceItemModal;
