import styles from './toggle.module.scss';
import { useState } from 'react';
import classNames from 'classnames';

interface Props {
    default: boolean;
    onToggle: (value: boolean) => void;
    disabled?: boolean;
}

const Toggle = (props: Props) => {
    const [active, setActive] = useState(props.default);

    const toggle = () => {
        if (props.disabled) return;
        setActive(!active);
        props.onToggle(!active);
    };

    return (
        <div
            className={classNames({
                [styles.container]: true,
                [styles.active]: active,
                [styles.disabled]: props.disabled,
            })}
            onClick={toggle}
        >
            <div
                className={classNames({
                    [styles.circle]: true,
                    [styles.disabled]: props.disabled,
                })}
            />
        </div>
    );
};

export default Toggle;
