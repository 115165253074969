import styles from './create-subscription.module.scss';
import { useState, useEffect } from 'react';
import Header from '../../components/header/header.component';
import HDivider from '../../components/h-divider/h-divider.component';
import SearchUserInputComponent from '../../components/search-user-input/search-user-input.component';
import UserIcon from '../../icons/user-icon/user.icon';
import DatePicker from '../../components/date-picker/date-picker.component';
import BasicButton from '../../components/basic-button/basic-button.component';
import { userSdk } from '../../services/user.service';
import ResponseCodes from '../../constants/response.codes';
import GoBackView from '../../components/go-back-view/go-back-view';
import { subscriptionSdk } from '../../services/subscription.service';
import { Service } from '../../types/service.type';
import CustomSelect from '../../components/custom-select/custom-select.component';
import { format } from 'date-fns';
import { number, object, string } from 'yup';
import PaperIcon from '../../icons/paper-icon/paper-icon';
import DateIcon from '../../icons/date-icon/date-icon';
import Toggle from '../../components/toggle/toggle';
import { useThemeStore } from '../../store';

const CreateSubscriptionView = () => {
    const { theme } = useThemeStore();
    const [validForm, setValidForm] = useState(false);

    const [subscriptionData, setSubscriptionData] = useState({
        userId: 0,
        planId: 0,
        from: '',
    });
    const [users, setUsers] = useState([]);
    const [services, setServices] = useState<Service[]>([]);
    const [selectedService, setSelectedService] = useState<
        Service | undefined
    >();
    const [clearPlanList, setClearPlanList] = useState(false);
    const [existsInvoice, setExistsInvoice] = useState(false);

    useEffect(() => {
        fetchServices();
    }, []);

    useEffect(() => {
        validateSubscriptionData();
    }, [subscriptionData]);

    const fetchServices = async () => {
        const response = await subscriptionSdk.getServices();
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('error getting services:', response);
        }
        setServices(response.data);
    };

    const searchUsers = async (value: string) => {
        const response = await userSdk.search(value);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('error fetching users:', response);
        }
        setUsers(response.data);
    };

    const onSelectService = (id: number) => {
        const service = services.find((service) => service.id === id);
        setSelectedService(service!);
        setClearPlanList(true);
        setTimeout(() => {
            setClearPlanList(false);
        }, 100);
    };

    const addSubscriptionData = (key: string, value: any) => {
        setSubscriptionData((prev) => ({ ...prev, [key]: value }));
    };

    const submitSubscriptionData = () => {
        console.log(subscriptionData);
    };

    const validateSubscriptionData = async () => {
        const schema = object({
            userId: number().min(1).required(),
            planId: number().min(1).required(),
            from: string().required(),
        });
        const valid = await schema.isValid(subscriptionData);
        setValidForm(valid);
    };

    return (
        <main className={styles.view}>
            <Header mode={theme} title={'Crear una suscripción'} />
            <HDivider />
            <GoBackView />
            <form>
                <div className={styles.formElement}>
                    <label>
                        <div className={styles.iconWrapper}>
                            <UserIcon size={16} color={'#07AF25'} />
                        </div>
                        <span>Busquemos al cliente</span>
                    </label>
                    <SearchUserInputComponent
                        mode={theme}
                        results={users}
                        placeholder={'Buscar cliente por nombre'}
                        width={280}
                        onSearch={searchUsers}
                        onSelect={(user) =>
                            addSubscriptionData('userId', user.id)
                        }
                    />
                </div>
                <HDivider />
                <div className={styles.formElement}>
                    <label>
                        <div className={styles.iconWrapper}>
                            <UserIcon size={16} color={'#07AF25'} />
                        </div>
                        <span>Selecciona el Plan</span>
                    </label>
                    <div className={styles.planRow}>
                        <CustomSelect
                            width={200}
                            options={services.map((service) => ({
                                value: service.id,
                                displayText: service.name,
                            }))}
                            placeholder={'Selecciona el servicio'}
                            onSelect={onSelectService}
                        />
                        {selectedService && (
                            <CustomSelect
                                width={200}
                                placeholder={'Selecciona el tipo de plan'}
                                options={selectedService.plans!.map((plan) => ({
                                    value: plan.id,
                                    displayText: plan.description,
                                }))}
                                clear={clearPlanList}
                                onSelect={(planId) =>
                                    addSubscriptionData('planId', planId)
                                }
                            />
                        )}
                    </div>
                    {/*
                    <table className={styles.plansTable}>
                        <tbody>
                            <tr className={styles.headRow}>
                                <td className={styles.planName}>Plan</td>
                                <td className={styles.planCount}>Cantidad</td>
                                <td className={styles.planTotal}>Total</td>
                                <td className={styles.planActions} />
                            </tr>
                            {planItems.map((item, idx) => (
                                <PlanItem
                                    key={idx}
                                    id={item.id}
                                    plans={DUMMY_PLANS}
                                    onRemove={removePlanItem}
                                />
                            ))}
                            <tr className={styles.footerRow}>
                                <td>
                                    <button
                                        className={styles.addPlanButton}
                                        onClick={addPlanItem}
                                    >
                                        Agregar otro plan
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    */}
                </div>
                <HDivider />
                <div className={styles.formElement}>
                    <label>
                        <div className={styles.iconWrapper}>
                            <DateIcon size={16} color={'#07AF25'} />
                        </div>
                        <span>Programación</span>
                    </label>
                    <p>Fecha de inicio de la suscripción</p>
                    <DatePicker
                        onDate={(date) =>
                            addSubscriptionData(
                                'from',
                                format(date, 'yyyy-MM-dd')
                            )
                        }
                    />
                </div>
                <HDivider />
                <div className={'flex align-center'} style={{ gap: 14 }}>
                    <Toggle
                        default={existsInvoice}
                        onToggle={setExistsInvoice}
                    />
                    <p>¿Existe un recibo asociado?</p>
                </div>
                {existsInvoice && (
                    <div className={styles.formElement}>
                        <label>
                            <div className={styles.iconWrapper}>
                                <PaperIcon size={16} color={'#07AF25'} />
                            </div>
                            <span>Recibo asociada</span>
                        </label>
                        <p>Toda suscripción debe estar atada a un recibo</p>
                    </div>
                )}
                <div className={styles.formElement}>
                    <BasicButton
                        text={'Crear suscripción'}
                        disabled={!validForm}
                        style={{ marginTop: 20 }}
                        onClick={submitSubscriptionData}
                    />
                </div>
            </form>
        </main>
    );
};

export default CreateSubscriptionView;
