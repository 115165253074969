export type Currency = 'USD' | 'EUR' | 'VES';

export default class NumberUtil {
    static formatCurrency(
        amount: number | bigint,
        currency: Currency = 'USD'
    ): string {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
        });

        return formatter.format(amount);
    }

    static formatAmount(amount: number): number {
        return Number(amount.toFixed(2));
    }
}
