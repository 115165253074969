import * as yup from 'yup';
import { MouseEvent, useEffect, useState } from 'react';

import RadioSelector from '../radio-selector/radio-selector.component';
import CustomInput from '../custom-input/custom-input.component';
import CustomSelect, {
    SelectOption,
} from '../custom-select/custom-select.component';
import { Plan } from '../../types/plan.type';
import { CouponRequest } from '../../types/coupon-request.type';
import { CouponTypes } from '../../constants/coupon.types';

import styles from './coupon-creation-form.module.scss';

type CodeTypes = 'random' | 'defined';

type CouponCode = {
    value?: string;
    type: CodeTypes;
};

interface Props {
    plans: Plan[];
    clearForm?: boolean;
    onChangeCode: (code?: string) => void;
    onChangeDiscount: (discount: number) => void;
    onRequestCreation: (coupon: CouponRequest) => void;
    hasError?: boolean;
}

const formSchema = yup.object({
    code: yup.string().optional(),
    discount: yup.number().min(1).required(),
    quantity: yup.number().min(1).required(),
    planId: yup.number().min(1).required(),
});

const CouponCreationForm = (props: Props) => {
    const [discount, setDiscount] = useState(0);
    const [code, setCode] = useState<CouponCode>({
        type: 'random',
        value: '',
    });
    const [quantity, setQuantity] = useState(0);
    const [planId, setPlanId] = useState(0);
    const [validForm, setValidForm] = useState(false);
    const [creationButtonText, setCreationButtonText] = useState('Crear');

    useEffect(() => {
        validateForm();
        props.onChangeCode(code.value);
    }, [code.value]);

    useEffect(() => {
        props.hasError && setValidForm(true);
    }, [props.hasError]);

    useEffect(() => {
        validateForm();
        props.onChangeDiscount(discount);
    }, [quantity, discount, planId]);

    useEffect(() => {
        props.clearForm && clearForm();
    }, [props.clearForm]);

    const clearForm = () => {
        setCode({
            type: 'random',
            value: undefined,
        });
        setCreationButtonText('Crear');
    };

    const onSetCodeType = (type: CodeTypes) => {
        setCode((code) => ({ ...code, type }));
        if (type === 'random') {
            updateCodeValue(undefined);
        }
    };

    const updateCodeValue = (value?: string) => {
        setCode((code) => ({ ...code, value }));
    };

    const validateForm = () => {
        setValidForm(
            formSchema.isValidSync({
                code: code.value,
                discount: discount,
                quantity: quantity,
                planId: planId,
            })
        );
    };

    const sanitizeCode = (code: string): string => {
        return code.replace(/ /g, '').trim().toUpperCase();
    };

    const requestCouponCreation = (ev: MouseEvent<HTMLButtonElement>) => {
        ev.preventDefault();
        setCreationButtonText('Cargando...');
        setValidForm(false);

        props.onRequestCreation({
            discount,
            quantity,
            planId,
            type: CouponTypes.PROMOTIONAL,
            code: code.value ? sanitizeCode(code.value) : undefined,
        });
    };

    const mapPlansToOptions = (plans: Plan[]): SelectOption[] => {
        return plans.map((plan) => ({
            value: plan.id,
            displayText: plan.description,
        }));
    };

    return (
        <form className={styles.form}>
            <div className={styles.block}>
                <label className={styles.formLabel}>Código</label>
                <RadioSelector
                    name={'code-type'}
                    options={[
                        {
                            value: 'random',
                            name: 'Aleatorio',
                            checked: code.type === 'random',
                        },
                        {
                            value: 'defined',
                            name: 'Quiero definirlo',
                            checked: code.type === 'defined',
                        },
                    ]}
                    onChange={onSetCodeType}
                />
                {code.type === 'defined' && (
                    <CustomInput
                        placeholder={'Código'}
                        width={'100%'}
                        clear={props.clearForm}
                        inputColor={'#fff'}
                        defaultValue={code.value}
                        onChange={updateCodeValue}
                    />
                )}
            </div>

            <div className={styles.block}>
                <label className={styles.formLabel}>¿Qué plan ponemos?</label>
                <CustomSelect
                    options={mapPlansToOptions(props.plans)}
                    placeholder={'Escoge el plan'}
                    width={'100%'}
                    clear={props.clearForm}
                    onSelect={setPlanId}
                />
            </div>

            <div className={styles.tableRow}>
                <div className={styles.block}>
                    <label className={styles.formLabel}>
                        Cantidad (máx. 100)
                    </label>
                    <CustomInput
                        placeholder={'Cantidad en N°'}
                        max={100}
                        inputColor={'#fff'}
                        type={'number'}
                        clear={props.clearForm}
                        onChange={(quantity) => setQuantity(Number(quantity))}
                    />
                    <span className={styles.inputLink}>
                        ¿Necesitas más? Consulta aquí
                    </span>
                </div>

                <div className={styles.block}>
                    <label className={styles.formLabel}>
                        ¿De cuánto será el escuento?
                    </label>
                    <CustomInput
                        placeholder={'Descuento %'}
                        type={'number'}
                        inputColor={'#fff'}
                        max={100}
                        clear={props.clearForm}
                        onChange={(discount) => setDiscount(Number(discount))}
                    />
                </div>
            </div>
            <button
                disabled={!validForm}
                className={styles.createCouponButton}
                onClick={requestCouponCreation}
            >
                {creationButtonText}
            </button>
        </form>
    );
};

export default CouponCreationForm;
