import React, { useState, MouseEvent, useRef, useEffect } from 'react';
import styles from './date-picker.module.scss';
import { DatePickerModal } from './modal';
import DateIcon from '../../icons/date-icon/date-icon';
import { format as formatDate } from 'date-fns';
import { es } from 'date-fns/locale';

interface IDate {
    day: number;
    date: number;
    month: number;
    year: number;
}

interface Props {
    onDate?: (date: Date) => void;
}

const DatePicker = (props: Props) => {
    const mainButtonRef = useRef<HTMLButtonElement | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);

    const [date, setDate] = useState(new Date());
    const [modalShown, setModalShown] = useState(false);
    const [modalPos, setModalPos] = useState({ x: 34, y: 0 });
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [daysOfMonth, setDaysOfMonth] = useState<IDate[]>([]);

    useEffect(() => {
        onModalFocus();
        onModalBlur();
    }, []);

    useEffect(() => {
        getDaysOfCurrentMonth();
    }, [currentMonth]);

    useEffect(() => {
        props.onDate?.(date);
    }, [date]);

    const onModalBlur = () => {
        modalRef.current!.onblur = () => {
            setTimeout(() => {
                setModalShown(false);
            }, 100);
        };
    };

    const onModalFocus = () => {
        modalRef.current!.onfocus = () => {
            setModalShown(true);
        };
    };

    const getDaysOfCurrentMonth = () => {
        const date = new Date();
        date.setDate(1); // Set it to the first day of the month
        date.setMonth(currentMonth.getMonth());
        date.setFullYear(currentMonth.getFullYear());
        date.setHours(0, 0, 0, 0); // Set date to midnight: timestamp 00:00:00

        const listOfDays = [];
        while (date.getMonth() === currentMonth.getMonth()) {
            listOfDays.push({
                day: date.getDay(),
                date: date.getDate(),
                month: date.getMonth(),
                year: date.getFullYear(),
            });

            date.setDate(date.getDate() + 1);
        }
        setDaysOfMonth(listOfDays);
    };

    const updateCurrentMonth = (leap: number) => {
        const newMonth = currentMonth.setMonth(currentMonth.getMonth() + leap);
        setCurrentMonth(new Date(newMonth));
    };

    const toggleShow = (ev: MouseEvent) => {
        ev.preventDefault();
        if (modalShown) {
            return modalRef.current?.blur();
        }
        modalRef.current?.focus();

        const modalHeight = modalRef.current!.offsetHeight + 4; // 4px of spacing;

        const bottomCapacity =
            Number(mainButtonRef.current?.getBoundingClientRect().bottom) +
            modalHeight;
        const maxHeight = window.innerHeight;
        setModalPos({
            x: bottomCapacity >= maxHeight ? 34 : -modalHeight,
            y: 0,
        });
    };

    const onDateSelect = (date: IDate) => {
        const selectedDate = new Date(date.year, date.month, date.date);
        setDate(selectedDate);
        modalRef.current?.blur();
    };

    return (
        <div className={styles.container}>
            <button
                ref={mainButtonRef}
                onClick={toggleShow}
                className={styles.mainButton}
            >
                <DateIcon size={16} color={'#414552'} />
                <span>{formatDate(date, 'dd MMMM, yyyy', { locale: es })}</span>
            </button>
            <DatePickerModal
                ref={modalRef}
                days={daysOfMonth}
                position={modalPos}
                visibility={modalShown}
                onDateSelect={onDateSelect}
                prevMonth={() => updateCurrentMonth(-1)}
                nextMonth={() => updateCurrentMonth(1)}
            />
        </div>
    );
};

export default DatePicker;
