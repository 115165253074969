import styles from './day.module.scss';
import classNames from 'classnames';
import IDate from './date.type';

interface Props {
    day: IDate;
    idx: number;
    isFrom: boolean;
    isTo: boolean;
    isSelected: boolean;
    isInterval: boolean;
    onSelect?: (day: IDate) => void;
}

export const Day = (props: Props) => {
    const onSelect = () => {
        return props.onSelect?.(props.day);
    };

    return (
        <div
            key={props.idx}
            className={classNames({
                [styles.dayItem]: true,
                [styles.notAllowed]: false,
                [styles.from]: props.isFrom,
                [styles.to]: props.isTo,
            })}
            style={{
                marginLeft: `${props.idx === 0 ? 40 * props.day.day : 0}px`,
            }}
            onClick={onSelect}
        >
            <div
                className={classNames({
                    [styles.selected]: props.isSelected,
                    [styles.interval]: props.isInterval,
                })}
            >
                <span>{props.day.date}</span>
            </div>
        </div>
    );
};
