const ResponseCodes = {
    PROCESS_OK: 0,
    CREATED: 1,
    INVALID_REQUEST: -1,
    ENTITY_NOT_FOUND: -2,
    DATABASE_ERROR: -3,
    ENTITY_ALREADY_EXISTS: -4,
    BAD_REQUEST: -5,
    INVALID_CREDENTIALS: -6,
    FORBIDDEN: -7,
    INTERNAL_SERVER_ERROR: -999,
};

export default ResponseCodes;
