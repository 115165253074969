import { addQueryParams, getAuthToken, handleResponseError } from './service';
import axios, { AxiosError } from 'axios';
import { ServiceResponse } from '../types/service-response.type';

type QueryParams = {
    from: string;
    to: string;
};

const BI_BASE_URL = process.env.REACT_APP_BI_API;

const getUsersStats = async (
    queryParams?: QueryParams
): Promise<ServiceResponse> => {
    console.log('BI Service: getting users stats');
    try {
        const inlineParams = addQueryParams(queryParams);
        const response = await axios.get(
            `${BI_BASE_URL}/users${inlineParams}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getUserTotals = async (): Promise<ServiceResponse> => {
    console.log('BI Service: getting user totals');
    try {
        const response = await axios.get(`${BI_BASE_URL}/users/total`, {
            headers: { Authorization: getAuthToken() },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getTotalPetitions = async (
    queryParams?: QueryParams
): Promise<ServiceResponse> => {
    console.log('BI Service: getting total petitions within date range');
    try {
        const inlineParams = addQueryParams(queryParams);
        const response = await axios.get(
            `${BI_BASE_URL}/petitions/total${inlineParams}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getTotalInvoices = async (
    queryParams?: QueryParams
): Promise<ServiceResponse> => {
    console.log('BI Service: getting total invoices within date range');
    try {
        const inlineParams = addQueryParams(queryParams);
        const response = await axios.get(
            `${BI_BASE_URL}/invoices/total${inlineParams}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getTotalPayments = async (
    queryParams?: QueryParams
): Promise<ServiceResponse> => {
    console.log('BI Service: getting total payments within date range');
    try {
        const inlineParams = addQueryParams(queryParams);
        const response = await axios.get(
            `${BI_BASE_URL}/payments/total${inlineParams}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const downloadExcel = async (entity: string, period: number): Promise<any> => {
    console.log(`BI Service: downloading excel of ${entity}`);
    try {
        const response = await axios.get(
            `${BI_BASE_URL}/${entity}/excel?period=${period}`,
            {
                headers: {
                    Authorization: getAuthToken(),
                },
                responseType: 'blob',
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const downloadUsers = async (type: string): Promise<any | undefined> => {
    console.log(`BI Service: downloading users of type ${type}`);
    try {
        const response = await axios.get(
            `${BI_BASE_URL}/users/excel?type=${type}`,
            {
                headers: {
                    Authorization: getAuthToken(),
                },
                responseType: 'blob',
            }
        );
        return response.data;
    } catch (err) {
        console.error('Error downloading users excel:', err);
        return undefined;
    }
};

export const biSdk = {
    getTotalInvoices,
    getTotalPayments,
    getTotalPetitions,
    getUsersStats,
    getUserTotals,
    downloadExcel,
    downloadUsers,
};
