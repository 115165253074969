import ZelleIcon from '../assets/img/method-zelle-icon.png';
import MobilePaymentIcon from '../assets/img/method-pm-icon.png';
import CCIcon from '../assets/img/method-cc-icon.png';
import { PaymentMethodCodes } from '../constants/payment-method.codes';

export const mapPaymentMethodIcon = (method?: string): string => {
    if (!method) return '';

    switch (method) {
        case PaymentMethodCodes.CC:
            return CCIcon;
        case PaymentMethodCodes.MOBILE_PAYMENT:
            return MobilePaymentIcon;
        case PaymentMethodCodes.ZELLE:
        default:
            return ZelleIcon;
    }
};
