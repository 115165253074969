import React from 'react';
import styles from './modal.module.scss';
import IDate from './date.type';
import classNames from 'classnames';
import GoBackIcon from './go-back.icon';
import GoNextIcon from './go-next.icon';
import { format as formatDate } from 'date-fns';
import { es } from 'date-fns/locale';

const DAYS_OF_THE_WEEK = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];

interface Props {
    days: IDate[];
    position: { x: number; y: number };
    visibility: boolean;
    onDateSelect: (date: IDate) => void;
    prevMonth: () => void;
    nextMonth: () => void;
}

export const DatePickerModal = React.forwardRef((props: Props, ref: any) => {
    const showCurrentMonthAndYear = (): string => {
        if (props.days.length === 0) return '';

        return formatDate(
            new Date(
                props.days[0].year,
                props.days[0].month,
                props.days[0].date
            ),
            'MMMM yyyy',
            { locale: es }
        );
    };

    const buildCalendarRow = (days: IDate[]) => {
        if (days.length === 0) return;

        let itemPerRow = 0;
        const row = [];
        const table = [];

        for (const day of days) {
            // If current day does not match with its position on the row
            // Add as many empty column as needed to fill the space
            if (day.day !== itemPerRow) {
                row.push(
                    Array.from(Array(day.day).keys()).map((day, idx) => (
                        <td key={`${day}-${idx}`} />
                    ))
                );
                // Reset counter according to current day position in row
                itemPerRow = day.day;
            }

            row.push(
                <td key={day.date}>
                    <div
                        className={styles.day}
                        onClick={() => props.onDateSelect(day)}
                    >
                        {day.date}
                    </div>
                </td>
            );

            ++itemPerRow;
            if (itemPerRow >= 7) {
                table.push(<tr key={day.date}>{Array.from(row)}</tr>);
                row.length = 0;
                itemPerRow = 0;
            }
        }

        if (row.length !== 0) {
            table.push(<tr key={days[0].year}>{Array.from(row)}</tr>);
        }

        return table;
    };

    return (
        <div
            ref={ref}
            tabIndex={-1}
            className={classNames({
                [styles.modal]: true,
                [styles.shown]: props.visibility,
            })}
            style={{
                inset: `auto auto ${props.position.x}px ${props.position.y}px`,
            }}
        >
            <header>
                <div
                    className={styles.navArrow}
                    onClick={() => props.prevMonth()}
                >
                    <GoBackIcon size={16} />
                </div>
                <h5>{showCurrentMonthAndYear()}</h5>
                <div
                    className={styles.navArrow}
                    onClick={() => props.nextMonth()}
                >
                    <GoNextIcon size={16} />
                </div>
            </header>
            <ul>
                {DAYS_OF_THE_WEEK.map((day, idx) => (
                    <li key={idx}>{day}</li>
                ))}
            </ul>
            <table>
                <tbody>{buildCalendarRow(props.days)}</tbody>
            </table>
        </div>
    );
});
