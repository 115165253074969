interface Props {
    color?: string;
    size?: number;
}

const CheckIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 16}
            height={props.size || 13}
            viewBox="0 0 16 13"
            fill="none"
        >
            <path
                d="M2.34375 5.0625L0 7.5L6.1875 12.4688L16 2L13.5 0L6.1875 8.15625L2.34375 5.0625Z"
                fill={props.color || '#565454'}
            />
        </svg>
    );
};

export default CheckIcon;
