import { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './toast-notification.module.scss';

type ToastEventType = 'success' | 'alert' | 'error';

type ToastEventAttrs = {
    text: string;
    timeout?: number;
    toastType?: ToastEventType;
};

export class ToastEvent extends Event {
    text: string;
    timeout?: number;
    toastType?: ToastEventType;

    constructor(name: string, { text, timeout, toastType }: ToastEventAttrs) {
        super(name);
        this.text = text;
        this.timeout = timeout;
        this.toastType = toastType;
    }
}

export const ToastNotification = () => {
    const [text, setText] = useState('');
    const [shown, setShown] = useState(false);

    useEffect(() => {
        window.addEventListener('toastNotificationEvent', handleEvent);

        return () =>
            window.removeEventListener('toastNotificationEvent', handleEvent);
    }, []);

    const handleEvent = (ev: Event) => {
        const event = ev as ToastEvent;
        setText(event.text);
        setShown(true);

        // Remove notification after a timeout
        setTimeout(() => {
            setShown(false);
        }, event.timeout ?? 500);
    };

    return (
        <div
            className={classNames({
                [styles.toast]: true,
                [styles.shown]: shown,
            })}
        >
            <span>{text}</span>
        </div>
    );
};

export class TNController {
    static show(
        text: string,
        timeout?: number,
        toastType: ToastEventType = 'success'
    ) {
        window.dispatchEvent(
            new ToastEvent('toastNotificationEvent', {
                text,
                timeout,
                toastType,
            })
        );
    }
}
