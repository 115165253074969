import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';

import StatsCard from '../../components/stats-card/stats-card.component';
import Header from '../../components/header/header.component';
import { biSdk } from '../../services/bi.service';
import ResponseCodes from '../../constants/response.codes';
import DateUtil from '../../utils/date.util';
import { DateRange } from '../../types/date-range.type';
import { DateRangePicker } from '../../components/date-range-picker';
import NumberUtil from '../../utils/number.util';
import Separator from '../../components/separator/separator.component';
import { UserBi } from '../../types/user-bi.type';
import { useThemeStore } from '../../store';

import styles from './dashboard.module.scss';

type Stats = {
    usersStats: UserBi | undefined;
    totalInvoices: number;
    totalPetitions: number;
    totalPayments: number;
};

const DashboardView = () => {
    const { theme } = useThemeStore();
    const [dateRange, setDateRange] = useState<DateRange>({
        from: DateUtil.getFirstDayOfMonth(),
        to: DateUtil.getLastDayOfMonth(),
    });
    const [stats, setStats] = useState<Stats>({
        usersStats: undefined,
        totalInvoices: 0,
        totalPetitions: 0,
        totalPayments: 0,
    });
    //const chartLabels = DateUtil.get12MonthsFromNow();

    useEffect(() => {
        getStats();
    }, [dateRange]);

    const getStats = async () => {
        const usersStats = await getUsersStats();
        const totalInvoices = await getTotalInvoices();
        const totalPetitions = await getTotalPetitions();
        const totalPayments = await getTotalPayments();

        console.log('users:', usersStats);
        console.log('invoices:', totalInvoices);
        console.log('petitions:', totalPetitions);
        console.log('payments:', totalPayments);

        setStats({
            usersStats,
            totalInvoices,
            totalPetitions,
            totalPayments,
        });
    };

    const formatFromDate = (): string => {
        return format(dateRange.from, 'yyyy-MM-dd');
    };

    const formatToDate = (): string => {
        return format(dateRange.to, 'yyyy-MM-dd');
    };

    const getUsersStats = async (): Promise<UserBi | undefined> => {
        const response = await biSdk.getUsersStats({
            from: formatFromDate(),
            to: formatToDate(),
        });
        return response.code === ResponseCodes.PROCESS_OK
            ? response.data
            : undefined;
    };

    const getTotalInvoices = async (): Promise<number> => {
        const response = await biSdk.getTotalInvoices({
            from: formatFromDate(),
            to: formatToDate(),
        });
        return response.code === ResponseCodes.PROCESS_OK ? response.data : 0;
    };

    const getTotalPetitions = async (): Promise<number> => {
        const response = await biSdk.getTotalPetitions({
            from: formatFromDate(),
            to: formatToDate(),
        });
        return response.code === ResponseCodes.PROCESS_OK ? response.data : 0;
    };

    const getTotalPayments = async (): Promise<number> => {
        const response = await biSdk.getTotalPayments({
            from: formatFromDate(),
            to: formatToDate(),
        });

        return response.code === ResponseCodes.PROCESS_OK
            ? response.data.totalRevenue
            : 0;
    };

    const updateDates = (range: { from: Date; to: Date }) => {
        setDateRange(range);
    };

    // const buildChartDataset = (history?: any[]): any[] => {
    //     const dataset: any[] = [];
    //     if (!history) return [];
    //     chartLabels.map((month) => {
    //         let monthValue = 0;
    //         for (const monthData of history) {
    //             if (monthData.date === month) {
    //                 monthValue = monthData.count;
    //                 break;
    //             }
    //         }
    //         dataset.push(monthValue);
    //     });
    //     return dataset;
    // };

    return (
        <main className={classNames(styles.view, styles[theme])}>
            <Header
                mode={theme}
                title={'Dashboard'}
                subtitle={'¡Hola otra vez, Gustavo!'}
            />
            <div className={styles.row}>
                <DateRangePicker
                    mode={theme}
                    defaultRange={dateRange}
                    onSelect={updateDates}
                />
            </div>
            <div className={styles.cardsContainer}>
                <StatsCard
                    label={'Total nuevas facturas'}
                    value={String(stats.totalInvoices)}
                    deviation={-1.2}
                    icon={'invoice'}
                />
                <StatsCard
                    label={'Total ganancias'}
                    value={NumberUtil.formatCurrency(
                        stats.totalPayments,
                        'USD'
                    )}
                    deviation={-10.45}
                    icon={'profit'}
                />
                <StatsCard
                    label={'Nuevos usuarios'}
                    value={String(stats.usersStats?.newInRange ?? 0)}
                    deviation={0.8}
                    icon={'user'}
                />
                <StatsCard
                    label={'Total solicitudes'}
                    value={String(stats.totalPetitions)}
                    deviation={-10.45}
                    icon={'emergency'}
                />
            </div>
            <Separator />
            <section className={styles.chartsSection}>
                <h2 className={styles.chartTitle}>Resumen</h2>
            </section>
        </main>
    );
};

export default DashboardView;
