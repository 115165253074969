import styles from './loader.module.scss';
import { ReactElement } from 'react';

interface Props {
    color?: string;
    size?: number;
}

const Loader = (props: Props) => {
    const renderParts = (size = 46): ReactElement[] => {
        const parts = [];
        for (let i = 0; i < 4; i++) {
            parts.push(
                <div
                    key={i}
                    className={styles.part}
                    style={{
                        height: size,
                        width: size,
                        borderWidth: size * 0.13,
                        borderColor: `${
                            props.color ?? 'white'
                        } transparent transparent transparent`,
                    }}
                />
            );
        }

        return parts;
    };
    return (
        <div
            className={styles.loaderWrapper}
            style={{
                width: props.size ?? 46,
                height: props.size ?? 46,
            }}
        >
            {renderParts(props.size)}
        </div>
    );
};

export default Loader;
