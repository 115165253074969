import { addQueryParams, getAuthToken, handleResponseError } from './service';
import axios, { AxiosError } from 'axios';
import { ServiceResponse } from '../types/service-response.type';
import { PaginatedQuery } from '../types/paginated-query.type';
import { RegisterPayment } from '../types/register-payment.type';

type QueryParams = {
    madeBy: number;
    status: number;
    currency: string;
    createdAt: string;
} & PaginatedQuery;

const PAYMENTS_BASE_URL = process.env.REACT_APP_PAYMENTS_API;
const PAYMENTS_ADMIN_URL = process.env.REACT_APP_PAYMENTS_ADMIN_API;

const getAll = async (queryParams?: QueryParams): Promise<ServiceResponse> => {
    console.log('PaymentService: getAll');
    try {
        const inlineParams = addQueryParams(queryParams);
        const response = await axios.get(
            `${PAYMENTS_BASE_URL}/payments${inlineParams}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getById = async (id: number): Promise<ServiceResponse> => {
    console.log('PaymentService: getById');
    try {
        const response = await axios.get(
            `${PAYMENTS_BASE_URL}/payments/${id}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const updatePayment = async (
    id: number,
    update: { status?: number; referenceCode?: string; comments?: string }
): Promise<ServiceResponse> => {
    console.log('PaymentService: updatePayment');
    try {
        const response = await axios.patch(
            `${PAYMENTS_BASE_URL}/payments/${id}`,
            update,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const registerPayment = async (
    data: RegisterPayment
): Promise<ServiceResponse> => {
    console.log('PaymentService: createPayment');
    try {
        const response = await axios.post(
            `${PAYMENTS_BASE_URL}/payments/register`,
            data,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getMethods = async (queryParams?: {
    isActive: boolean;
}): Promise<ServiceResponse> => {
    try {
        const inlineParams = addQueryParams(queryParams);
        const response = await axios.get(
            `${PAYMENTS_BASE_URL}/gateways${inlineParams}`,
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getCurrencyConfig = async (): Promise<ServiceResponse> => {
    try {
        const response = await axios.get(
            `${PAYMENTS_ADMIN_URL}/currency-config`,
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const updateCurrency = async (data: {
    id: number;
    rate: number;
}): Promise<ServiceResponse> => {
    try {
        const response = await axios.patch(
            `${PAYMENTS_ADMIN_URL}/currency-config/${data.id}`,
            { rate: data.rate },
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const paymentSdk = {
    getAll,
    getById,
    updatePayment,
    registerPayment,
    getMethods,
    getCurrencyConfig,
    updateCurrency,
};
