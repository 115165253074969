import { useEffect, useState } from 'react';

import { generateUUID } from '../../utils/uuid.util';

import styles from './nid-input.module.scss';

const DEFAULT_TYPES = ['V', 'E', 'P'];

interface Props {
    types?: string[];
    value?: string;
    onInput?: (data: { type: string; document: string }) => void;
}

const NidInput = ({ types = [], value, onInput }: Props) => {
    const [nidType, setNidType] = useState<string>('V');
    const [nid, setNid] = useState<string | undefined>(value);

    useEffect(() => {
        nidType && nid && onInput?.({ type: nidType, document: nid });
    }, [nidType, nid]);

    return (
        <div className={styles.wrapper}>
            <select onChange={(ev) => setNidType(ev.target.value)}>
                {[...DEFAULT_TYPES, ...types].map((type) => (
                    <option key={generateUUID()} value={type}>
                        {type}
                    </option>
                ))}
            </select>
            <input
                type={'number'}
                onChange={(ev) => setNid(ev.target.value.trim())}
            />
        </div>
    );
};

export default NidInput;
