import { ReactElement, useEffect, useState } from 'react';
import { number, object, string } from 'yup';
import classNames from 'classnames';

import GiftCardGraphic from '../../components/gift-card-graphic/gift-card-graphic.component';
import CustomInput from '../../components/custom-input/custom-input.component';
import RadioSelector from '../../components/radio-selector/radio-selector.component';
import CircleCheckIcon from '../../icons/circle-check-icon/circle-check.icon';
import CustomSelect, {
    SelectOption,
} from '../../components/custom-select/custom-select.component';
import { Plan } from '../../types/plan.type';
import { GiftCardRequest } from '../../types/gift-card-request.type';
import Header from '../../components/header/header.component';
import BasicButton from '../../components/basic-button/basic-button.component';
import GoBackView from '../../components/go-back-view/go-back-view';
import { useThemeStore } from '../../store';

import styles from './create-gift-card.module.scss';

const formSchema = object().shape({
    email: string().email().required(),
    planId: number().min(1).required(),
    codeType: string().oneOf(['randmon', 'defined']).required(),
    code: string().when('codeType', {
        is: (codeType: string) => codeType === 'defined',
        then: (schema) => schema.min(4).required(),
        otherwise: (schema) => schema.optional(),
    }),
});

type Notification = {
    shown: boolean;
    text: string;
    icon: ReactElement;
};

const CreateGiftCardView = () => {
    const { theme } = useThemeStore();
    const [giftCard, setGiftCard] = useState<GiftCardRequest>({
        email: '',
        codeType: 'random',
        code: 'CODIGO',
        planId: 0,
    });
    const [plans, setPlans] = useState<Plan[]>([]);
    const [turnAround, setTurnAround] = useState(false);
    const [validForm, setValidForm] = useState(false);
    const [notification] = useState<Notification>({
        shown: false,
        text: 'Cupón creado exitosamente',
        icon: <CircleCheckIcon size={20} color={'#5CCDAD'} />,
    });
    const [creationButtonText] = useState('Crear');
    const [clearForm] = useState(false);

    useEffect(() => {
        getPlans();
    }, []);

    useEffect(() => {
        setValidForm(formSchema.isValidSync(giftCard));
    }, [giftCard]);

    const getPlans = async () => {
        setPlans([]);
    };

    const updateGiftCard = (attr: string, value: string) => {
        setGiftCard((prev) => ({ ...prev, [attr]: value }));
    };

    const mapPlansToOptions = (plans: Plan[]): SelectOption[] => {
        return plans.map((plan) => ({
            value: plan.id,
            displayText: plan.description,
        }));
    };

    // const sanitizeCode = (code: string): string => {
    //     return code.replace(/ /g, '').trim().toUpperCase();
    // };
    //
    // const showNotification = (text: string, success: boolean) => {
    //     setNotification({
    //         shown: true,
    //         text: text,
    //         icon: success ? (
    //             <CircleCheckIcon size={20} color={'#5CCDAD'} />
    //         ) : (
    //             <CircleErrorIcon size={20} color={'#fa6c6c'} />
    //         ),
    //     });
    //
    //     setTimeout(() => {
    //         setNotification((prev) => ({ ...prev, shown: false }));
    //     }, 6000);
    // };

    // const createGiftCard = async (ev: MouseEvent<HTMLButtonElement>) => {
    //     ev.preventDefault();
    //     setValidForm(false);
    //     setCreationButtonText('Cargando...');
    //
    //     const response = await couponSdk.createGiftCard({
    //         ...giftCard,
    //         code: giftCard.code ? sanitizeCode(giftCard.code) : undefined,
    //     });
    //     if (response.code !== ResponseCodes.PROCESS_OK) {
    //         showNotification(response.message, false);
    //         setValidForm(true);
    //         return;
    //     }
    //     showNotification('Gift card creada exitosamente', true);
    //     clearAllForm();
    // };
    //
    // const clearAllForm = () => {
    //     setCreationButtonText('Crear');
    //     setClearForm(true);
    //     setTimeout(() => {
    //         setClearForm(false);
    //     }, 200);
    // };

    return (
        <main className={styles.view}>
            <Header
                mode={theme}
                title={'Crear Gift Card'}
                subtitle={'Creemos una gift card'}
            />
            <section>
                <GoBackView />

                <div className={styles.giftCardContainer}>
                    <GiftCardGraphic data={giftCard} turnAround={turnAround} />
                </div>

                <form>
                    <div className={styles.block}>
                        <label>Correo electrónico de la persona</label>
                        <CustomInput
                            placeholder={'Correo'}
                            width={'100%'}
                            inputColor={'#fff'}
                            onChange={(email) => updateGiftCard('email', email)}
                            clear={clearForm}
                        />
                    </div>
                    <div className={styles.block}>
                        <label>Escoge el plan:</label>
                        <CustomSelect
                            options={mapPlansToOptions(plans)}
                            placeholder={'Escoge el plan'}
                            width={'100%'}
                            onSelect={(planId) =>
                                updateGiftCard('planId', planId)
                            }
                            clear={clearForm}
                        />
                    </div>
                    <div className={styles.block}>
                        <label>Código del Gift Card</label>
                        <RadioSelector
                            name={'code-type'}
                            options={[
                                {
                                    value: 'random',
                                    name: 'Aleatorio',
                                    checked: giftCard.codeType === 'random',
                                },
                                {
                                    value: 'defined',
                                    name: 'Quiero definirlo',
                                    checked: giftCard.codeType === 'defined',
                                },
                            ]}
                            onChange={(codeType) =>
                                updateGiftCard('codeType', codeType)
                            }
                        />
                        {giftCard.codeType === 'defined' && (
                            <CustomInput
                                placeholder={'Código'}
                                width={'100%'}
                                inputColor={'#fff'}
                                defaultValue={giftCard.code}
                                max={20}
                                onFocus={() => setTurnAround(true)}
                                onBlur={() => setTurnAround(false)}
                                onChange={(code) =>
                                    updateGiftCard('code', code)
                                }
                                clear={clearForm}
                            />
                        )}
                    </div>
                    <BasicButton
                        width={'100%'}
                        text={creationButtonText}
                        disabled={!validForm}
                        style={{ marginTop: '40px' }}
                    />
                </form>
            </section>
            <div
                className={classNames({
                    [styles.notification]: true,
                    [styles.shown]: notification.shown,
                })}
            >
                {notification.icon}
                <p>{notification.text}</p>
            </div>
        </main>
    );
};

export default CreateGiftCardView;
