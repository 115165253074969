import styles from './custom-input.module.scss';
import {
    ChangeEvent,
    KeyboardEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';
import EyeHideIcon from '../../icons/eye.hide.icon';
import EyeShowIcon from '../../icons/eye-show.icon';

interface Props {
    mode?: string;
    type?: 'text' | 'email' | 'password' | 'number';
    placeholder?: string;
    clear?: boolean;
    icon?: ReactElement;
    onChange?: (value: string) => void;
    defaultValue?: string;
    width?: string;
    inputColor?: string;
    fontSize?: number | string;
    min?: number;
    max?: number;
    onFocus?: () => void;
    onBlur?: () => void;
    onReveal?: () => void;
    onHide?: () => void;
    value?: string;
    hideClear?: boolean;
}

const CustomInput = (props: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [hasValue, setHasValue] = useState(false);
    const [focused, setFocused] = useState(false);
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        props.clear && clearInput();
    }, [props.clear]);

    const onFocus = () => {
        props.onFocus?.();
        setFocused(true);
    };

    const onBlur = () => {
        props.onBlur?.();
        setFocused(false);
    };

    const onInput = (ev: ChangeEvent<HTMLInputElement>) => {
        const valueExists =
            ev.target.value !== null && ev.target.value.trim().length > 0;
        setHasValue(valueExists);
        props.onChange?.(ev.target.value.trim());
    };

    const clearInput = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        setHasValue(false);
        props.onChange?.('');
    };

    const evalInput = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (props.type !== 'number' || !props.max) return;

        const futureValue = inputRef.current!.value + ev.key;
        if (Number(futureValue) > props.max) {
            ev.preventDefault();
        }
    };

    const toggleRevealText = () => {
        setHidden(!hidden);
    };

    return (
        <div
            className={styles.wrapper}
            style={{
                width: props.width || 'fit-content',
                background: props.inputColor || 'none',
            }}
        >
            <span
                className={classNames({
                    [styles.inputContainer]: true,
                    [styles.focused]: focused,
                })}
            >
                {props.icon}
                <input
                    ref={inputRef}
                    type={!hidden ? 'text' : props.type || 'text'}
                    placeholder={props.placeholder}
                    onChange={onInput}
                    defaultValue={props.defaultValue}
                    onKeyDown={evalInput}
                    min={props.min}
                    max={props.max}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    maxLength={props.max}
                    style={{ fontSize: props.fontSize ?? 14 }}
                    value={props.value}
                />
                {hasValue && props.type !== 'password' && !props.hideClear && (
                    <i
                        className={styles.clearInput}
                        onClick={clearInput}
                        data-target={'clear'}
                    >
                        ✖
                    </i>
                )}
                {hasValue && props.type === 'password' && (
                    <div className={styles.revealContainer}>
                        {hidden ? (
                            <EyeShowIcon onClick={toggleRevealText} />
                        ) : (
                            <EyeHideIcon onClick={toggleRevealText} />
                        )}
                    </div>
                )}
            </span>
        </div>
    );
};

export default CustomInput;
