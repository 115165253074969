import Avatar from 'boring-avatars';

import styles from './header.module.scss';

interface Props {
    mode?: string;
    title: string;
    subtitle?: string;
}

const Header = (props: Props) => {
    return (
        <header className={styles.header}>
            <div className={styles.welcome}>
                <h1>{props.title}</h1>
                {props.subtitle && <h3>{props.subtitle}</h3>}
            </div>
            <div className={'flex align-center'} style={{ gap: 20 }}>
                {/*<Notifications iconColor={'#565454'} />*/}
                <Avatar size={40} />
            </div>
        </header>
    );
};

export default Header;
