import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../components/header/header.component';
import { User } from '../../types/user.type';
import { userSdk } from '../../services/user.service';
import ResponseCodes from '../../constants/response.codes';
import Separator from '../../components/separator/separator.component';
import UserInvoicesDetails from '../../components/user-invoices-details/user-invoices-details.component';
import { invoiceSdk } from '../../services/invoice.service';
import { Invoice } from '../../types/invoice.type';
import {
    TNController,
    ToastNotification,
} from '../../components/toast-notification/toast-notification.component';
import GoBackView from '../../components/go-back-view/go-back-view';
import { Theme } from '../../types/theme.type';
import { removeEmptyFields } from '../../utils/entity.util';
import { subscriptionSdk } from '../../services/subscription.service';
import SubscriptionStatus from '../../constants/subscription.status';
import { UpdateUserBasicInfo } from '../../types/update-user-basic-info.type';

import Vehicles from './vehicles';
import AddVehicleModal from './add-vehicle-modal';
import UserSubscriptions from './containers/user-subscriptions';
import InactivateSubscriptionModal from './components/inactivate-subscription-modal';
import UserBasicInfo from './containers/user-basic-info';
import styles from './user.module.scss';
import { SubscriptionAction } from './components/subscription-details-popover';

interface Props {
    theme?: Theme;
}

const UserView = (props: Props) => {
    const { ['id']: userId } = useParams();

    const [user, setUser] = useState<User | undefined>();
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [vehicleModalShown, setVehicleModalShown] = useState(false);
    const [updatingBasicInfo, setUpdatingBasicInfo] = useState(false);
    const [inactivateSubscription, setInactivateSubscription] = useState({
        modalShown: false,
        subscriptionId: 0,
        confirmLoading: false,
    });

    useEffect(() => {
        fetchUser(Number(userId));
        fetchInvoices(Number(userId));
    }, [userId]);

    const fetchInvoices = async (userId: number) => {
        const response = await invoiceSdk.getAll({
            payerId: userId,
            page: 1,
            count: 20,
        });
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.error('[!] Error getting invoices of user:', response);
        }
        setInvoices(response.data.result);
    };

    const fetchUser = async (id: number) => {
        const response = await userSdk.getById(id, ['vehicles']);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('[!] Error getting user:', response);
        }
        const userModel = response.data as User;
        setUser(userModel);
    };

    const updateUserBasicInfo = async (update: UpdateUserBasicInfo) => {
        setUpdatingBasicInfo(true);
        const response = await userSdk.update(
            Number(userId),
            removeEmptyFields(update)
        );
        setUpdatingBasicInfo(false);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return TNController.show(response.message, 2500, 'error');
        }

        TNController.show('Usuario actualizado exitosamente', 2500);
    };

    const onVehicleCreated = () => {
        setVehicleModalShown(false);
        fetchUser(Number(userId));
    };

    const onInactivateSubscription = async () => {
        const { confirmLoading, subscriptionId: id } = inactivateSubscription;
        if (confirmLoading || id === 0) return;

        setInactivateSubscription((prev) => ({
            ...prev,
            confirmLoading: true,
        }));

        const response = await subscriptionSdk.updateSubscription(id, {
            status: SubscriptionStatus.INACTIVE,
        });
        if (response.code !== ResponseCodes.PROCESS_OK) {
            setInactivateSubscription((prev) => ({
                ...prev,
                confirmLoading: false,
            }));
            return TNController.show(response.message, 2500, 'error');
        }

        setInactivateSubscription({
            subscriptionId: 0,
            confirmLoading: false,
            modalShown: false,
        });
        fetchUser(Number(userId));
        TNController.show('Suscripción cancelada exitosamente', 2500);
    };

    const handleSubscriptionAction = (
        subscriptionId: number,
        action: SubscriptionAction
    ) => {
        switch (action) {
            case SubscriptionAction.INACTIVATE:
            default:
                return setInactivateSubscription({
                    modalShown: true,
                    subscriptionId,
                    confirmLoading: false,
                });
        }
    };

    return (
        <main className={styles.view}>
            <Header
                mode={props.theme}
                title={`Usuario: ${user?.firstName + ' ' + user?.lastName}`}
                subtitle={'Repasemos el detalle de este usuario'}
            />
            <div className={styles.container}>
                <GoBackView />
                {user && (
                    <UserBasicInfo
                        user={user}
                        onUpdate={updateUserBasicInfo}
                        updating={updatingBasicInfo}
                    />
                )}
                <Separator />
                <div className={styles.details}>
                    <Vehicles
                        vehicles={user?.vehicles}
                        onAdd={() => setVehicleModalShown(true)}
                    />
                    <UserSubscriptions
                        subscriptions={user?.subscriptions}
                        onAction={handleSubscriptionAction}
                    />
                    <UserInvoicesDetails
                        theme={props.theme}
                        invoices={invoices}
                    />
                </div>
            </div>
            {vehicleModalShown && (
                <AddVehicleModal
                    userId={Number(userId)}
                    onConfirm={onVehicleCreated}
                    onDiscard={() => setVehicleModalShown(false)}
                />
            )}
            {inactivateSubscription.modalShown && (
                <InactivateSubscriptionModal
                    confirmLoading={inactivateSubscription.confirmLoading}
                    onConfirm={onInactivateSubscription}
                    onDiscard={() =>
                        setInactivateSubscription({
                            modalShown: false,
                            subscriptionId: 0,
                            confirmLoading: false,
                        })
                    }
                />
            )}
            <ToastNotification />
        </main>
    );
};

export default UserView;
