import styles from './create-payment.module.scss';
import Header from '../../components/header/header.component';
import SearchUserInputComponent from '../../components/search-user-input/search-user-input.component';
import { useEffect, useState } from 'react';
import { User } from '../../types/user.type';
import ResponseCodes from '../../constants/response.codes';
import { userSdk } from '../../services/user.service';
import UserIcon from '../../icons/user-icon/user.icon';
import CustomInput from '../../components/custom-input/custom-input.component';
import PaymentIcon from '../../icons/payment-icon/payment.icon';
import PaperIcon from '../../icons/paper-icon/paper-icon';
import SearchInvoiceInput from '../../components/search-invoice-input/search-invoice-input.component';
import { invoiceSdk } from '../../services/invoice.service';
import { Invoice } from '../../types/invoice.type';
import BasicButton from '../../components/basic-button/basic-button.component';
import { number, object } from 'yup';
import CustomSelect from '../../components/custom-select/custom-select.component';
import { Gateway } from '../../types/gateway.type';
import GoBackView from '../../components/go-back-view/go-back-view';
import { paymentSdk } from '../../services/payment.service';
import Modal from '../../components/modal/modal.component';
import SuccessImage from '../../assets/img/check-image.png';

const CreatePaymentView = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<number>();

    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState<number>();

    const [methods, setMethods] = useState<Gateway[]>([]);

    const [amount, setAmount] = useState<number>();
    const [selectedMethod, setSelectedMethod] = useState<number>();

    const [validForm, setValidForm] = useState(false);
    const [modalShown, setModalShown] = useState(false);

    useEffect(() => {
        fetchMethods();
    }, []);

    useEffect(() => {
        validateForm();
    }, [selectedUserId, selectedInvoiceId, amount, selectedMethod]);

    const fetchMethods = async () => {
        const response = await paymentSdk.getMethods({ isActive: true });
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.error('[!] Error getting gateways:', response);
            return setMethods([]);
        }
        setMethods(response.data);
    };

    const searchUsers = async (value: string) => {
        const response = await userSdk.search(value);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.error('error fetching users:', response);
            return setUsers([]);
        }
        setUsers(response.data);
    };

    const searchInvoices = async (id: number) => {
        const response = await invoiceSdk.getById(id);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.error('error fetching invoices:', response);
            return setInvoices([]);
        }
        setInvoices([response.data]);
    };

    const validateForm = async () => {
        const schema = object({
            userId: number().min(1).required(),
            invoiceId: number().min(1).optional(),
            amount: number().min(1).required(),
            method: number().min(1).required(),
        });
        const valid = await schema.isValid({
            userId: selectedUserId,
            invoiceId: selectedInvoiceId,
            amount,
            method: selectedMethod,
        });
        setValidForm(valid);
    };

    const registerPayment = async () => {
        setValidForm(false);
        const data = {
            amount: amount!,
            currency: 'USD',
            madeBy: selectedUserId!,
            methodId: selectedMethod!,
            invoiceId: selectedInvoiceId,
        };
        const response = await paymentSdk.registerPayment(data);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.error('Error registering payment:', response);
            return setValidForm(true);
        }
        setModalShown(true);
    };

    return (
        <main className={styles.view}>
            <Header title={'Registrar nuevo pago'} />
            <GoBackView />
            <form>
                <div className={styles.formField}>
                    <div className={styles.formFieldTitle}>
                        <div className={styles.iconWrapper}>
                            <UserIcon size={16} color={'#07AF25'} />
                        </div>
                        <h3>¿Qué usuario realizó el pago?</h3>
                    </div>
                    <p className={styles.formFieldDescription}>
                        Busca el nombre del usuario que realizó el pago.
                    </p>
                    <SearchUserInputComponent
                        results={users}
                        onSearch={searchUsers}
                        onSelect={(user) => setSelectedUserId(user.id)}
                        onClear={() => setSelectedUserId(undefined)}
                    />
                </div>
                <div className={styles.formField}>
                    <div className={styles.formFieldTitle}>
                        <div className={styles.iconWrapper}>
                            <PaperIcon size={16} color={'#07AF25'} />
                        </div>
                        <h3>¿Pertenece a una factura?</h3>
                    </div>
                    <p className={styles.formFieldDescription}>
                        Si el pago está atado a una factura, busca el ID de la
                        factura al que pertenece. Este campo NO es obligatorio,
                        así que puedes dejarlo vacío.
                    </p>
                    <SearchInvoiceInput
                        results={invoices}
                        onSearch={searchInvoices}
                        onSelect={(invoice) => setSelectedInvoiceId(invoice.id)}
                        onClear={() => setSelectedInvoiceId(undefined)}
                    />
                </div>
                <div className={styles.formField}>
                    <div className={styles.formFieldTitle}>
                        <div className={styles.iconWrapper}>
                            <PaymentIcon size={16} color={'#07AF25'} />
                        </div>
                        <h3>Monto y método</h3>
                    </div>
                    <p className={styles.formFieldDescription}>
                        Indica el monto y el método de pago que se utilizaron.
                        El monto debe ser expresado en dólares únicamente.
                    </p>
                    <div className={'flex'} style={{ gap: 10 }}>
                        <CustomInput
                            type={'number'}
                            placeholder={'monto'}
                            onChange={(value) => setAmount(Number(value))}
                        />
                        <CustomSelect
                            options={methods.map((method) => ({
                                displayText: method.displayName,
                                value: method.id,
                            }))}
                            placeholder={'Método de pago'}
                            width={160}
                            onSelect={setSelectedMethod}
                        />
                    </div>
                </div>
                <BasicButton
                    text={'Registrar pago'}
                    disabled={!validForm}
                    onClick={registerPayment}
                />
            </form>
            {modalShown && (
                <Modal onConfirm={() => setModalShown(false)}>
                    <div className={styles.successModal}>
                        <h4>¡Pago registrado!</h4>
                        <img src={SuccessImage} />
                        <span>El pago fue registrado exitosamente.</span>
                    </div>
                </Modal>
            )}
        </main>
    );
};

export default CreatePaymentView;
