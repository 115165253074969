import InvoiceStatus from '../constants/invoice.status';

export const mapInvoiceStatus = (
    status: number
): {
    value: string;
    txtColor: string;
    bgColor: string;
} => {
    switch (status) {
        case InvoiceStatus.PARTIAL_PAYMENT:
            return {
                value: 'Pago parcial',
                txtColor: '#af6107',
                bgColor: '#f7e4d5',
            };
        case InvoiceStatus.PAID:
            return {
                value: 'Pagada',
                txtColor: '#07AF25',
                bgColor: '#D5F7DB',
            };
        case InvoiceStatus.CANCELLED:
            return {
                value: 'Cancelada',
                txtColor: '#af1507',
                bgColor: '#f7d5d5',
            };
        case InvoiceStatus.PAYMENT_EXCEEDED:
            return {
                value: 'Pago excedido',
                txtColor: '#af6107',
                bgColor: '#f7e4d5',
            };
        case InvoiceStatus.CREATED:
        case InvoiceStatus.UNPAID:
        default:
            return {
                value: 'Esperando pago',
                txtColor: '#af6107',
                bgColor: '#f7e4d5',
            };
    }
};
