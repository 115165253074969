import { useEffect, useState } from 'react';

import Header from '../../components/header/header.component';
import GoBackView from '../../components/go-back-view/go-back-view';
import { useParams } from 'react-router-dom';
import { userSdk } from '../../services/user.service';
import ResponseCodes from '../../constants/response.codes';
import { Company } from '../../types/company.type';
import HDivider from '../../components/h-divider/h-divider.component';

import styles from './company-details.module.scss';
import { User } from '../../types/user.type';
import LinkWithIcon from '../../components/link-with-icon/link-with-icon';

const CompanyDetailsView = () => {
    const { ['id']: companyId } = useParams();

    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState<Company>();
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        if (!companyId) return;
        fetchCompanyDetails(Number(companyId));
        fetchCompanyUsers(Number(companyId));
    }, [companyId]);

    const fetchCompanyDetails = async (id: number) => {
        setLoading(true);
        const response = await userSdk.getCompanyDetails(id);
        setLoading(false);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('Error getting companies:', response);
        }
        setCompany(response.data);
    };

    const fetchCompanyUsers = async (id: number) => {
        const response = await userSdk.getCompanyUsers(id);
        setUsers(response.data);
    };

    if (!company && !loading) {
        return (
            <main className={styles.view}>
                <Header title={`Detalles de compañía`} />
                <section className={styles.container}>
                    <GoBackView />
                    <span className={styles.noCompany}>
                        Esta empresa no existe 💀.
                    </span>
                </section>
            </main>
        );
    }

    return (
        <main className={styles.view}>
            <Header title={`Detalles de compañía`} />
            <section className={styles.container}>
                <GoBackView />
                <div className={styles.row}>
                    <label>Nombre de la companía:</label>
                    <input
                        defaultValue={company?.displayName ?? company?.name}
                        disabled
                    />
                </div>
                <HDivider />
                <h4>Información de contacto</h4>
                {company?.contact ? (
                    <CompanyContact company={company} />
                ) : (
                    <span className={styles.noContact}>
                        No existe información de contacto.
                    </span>
                )}
                <HDivider />
                <h4>Usuarios atados a esta compañía</h4>
                <UsersList users={users} />
            </section>
        </main>
    );
};

const CompanyContact = ({ company }: { company: Company }) => {
    return (
        <>
            <div className={styles.row}>
                <label>Persona contacto:</label>
                <input defaultValue={company?.contact?.name} disabled />
            </div>
            <div className={styles.row}>
                <label>Teléfono contacto:</label>
                <input defaultValue={company?.contact?.phoneNumber} disabled />
            </div>
            <div className={styles.row}>
                <label>Correo contacto:</label>
                <input defaultValue={company?.contact?.email} disabled />
            </div>
            <div className={styles.row}>
                <label>Dirección:</label>
                <input defaultValue={company?.contact?.address} disabled />
            </div>
        </>
    );
};

const UsersList = ({ users }: { users: User[] }) => {
    return (
        <ul className={styles.userList}>
            {users.map((user, idx) => (
                <li key={idx}>
                    <LinkWithIcon link={`/users/${user.id}`}>
                        {user.firstName} {user.lastName}
                    </LinkWithIcon>
                    <span className={styles.metadata}>
                        <b>Correo:</b> {user.email}
                    </span>
                    <span className={styles.metadata}>
                        <b>Cédula:</b> {user.documentType}-{user.document}
                    </span>
                </li>
            ))}
        </ul>
    );
};

export default CompanyDetailsView;
