interface Props {
    size?: number;
    color?: string;
    className?: string;
}

const PlusIcon = ({ size, color, className }: Props) => {
    return (
        <svg
            aria-hidden="true"
            className={className}
            height={size ?? 16}
            width={size ?? 16}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={color ?? 'white'}
                d="M8 .25c.483 0 .875.392.875.875v6h6a.875.875 0 0 1 0 1.75h-6v6a.875.875 0 0 1-1.75 0v-6h-6a.875.875 0 1 1 0-1.75h6v-6c0-.483.392-.875.875-.875Z"
            />
        </svg>
    );
};

export default PlusIcon;
