import styles from './not-found.module.scss';

const NotFoundView = () => {
    return (
        <main id={styles.notFound}>
            <h1>404</h1>
            <p>Houston, creo que estamos perdidos.</p>
        </main>
    );
};

export default NotFoundView;
