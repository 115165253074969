import {
    FocusEvent,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';

import styles from './custom-select.module.scss';

export type SelectOption = {
    displayText: string | JSX.Element;
    value: any;
};

interface Props {
    options: SelectOption[];
    placeholder?: string | JSX.Element;
    width?: number | string;
    clear?: boolean;
    onSelect?: (value: any) => void;
}

const CustomSelect = (props: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectedOption, setSelectedOption] = useState<SelectOption>();
    const [showList, setShowList] = useState(false);

    useEffect(() => {
        window.addEventListener('keydown', hideWithEscape);

        return () => window.removeEventListener('keydown', hideWithEscape);
    }, []);

    useEffect(() => {
        props.clear && setSelectedOption(undefined);
    }, [props.clear]);

    const onSelect = (option: SelectOption) => {
        setSelectedOption(option);
        props.onSelect?.(option.value);
        setShowList(false);
        containerRef.current?.blur();
    };

    const renderOptions = useCallback(
        (options: SelectOption[]): ReactElement[] => {
            return options.map((option, idx) => (
                <li key={idx} onClick={() => onSelect(option)}>
                    {option.displayText}
                </li>
            ));
        },
        [onSelect]
    );

    const hideWithEscape = (ev: KeyboardEvent) => {
        if (ev.key === 'Escape') {
            containerRef.current!.blur();
        }
    };

    const onFocus = () => {
        setShowList(true);
    };

    const onBlur = (ev: FocusEvent) => {
        if (containerRef.current!.contains(ev.relatedTarget)) {
            return ev.preventDefault();
        }
        setShowList(false);
    };

    return (
        <div
            ref={containerRef}
            className={styles.select}
            style={{ width: props.width || 'fit-content' }}
            tabIndex={0}
            onFocus={onFocus}
            onBlur={onBlur}
        >
            <span
                className={classNames({
                    [styles.selected]: true,
                    [styles.noBottomRadius]: showList,
                })}
            >
                {selectedOption?.displayText || props.placeholder}
            </span>
            {showList && (
                <ul className={styles.list}>{renderOptions(props.options)}</ul>
            )}
        </div>
    );
};

export default CustomSelect;
