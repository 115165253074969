import styles from './promotions.module.scss';
import Header from '../../components/header/header.component';
import ModuleSummary from '../../components/module-summary/module-summary.component';
import { useNavigate } from 'react-router-dom';
import { couponSdk } from '../../services/coupon.service';
import { useEffect, useState, MouseEvent } from 'react';
import { CouponTypes } from '../../constants/coupon.types';
import ResponseCodes from '../../constants/response.codes';
import { Coupon } from '../../types/coupon.type';
import { TableRow } from '../../types/table-row.type';
import Table from '../../components/table/table.component';
import FilteringButton from '../../components/filtering-button/filtering-button.component';
import { useThemeStore } from '../../store';

const PromotionsView = () => {
    const { theme } = useThemeStore();
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        countPerPage: 20,
    });
    const [filters, setFilters] = useState({
        type: CouponTypes.PROMOTIONAL,
        code: undefined,
    });
    const [tableRows, setTableRows] = useState<TableRow[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('filters changed:', filters);
        fetchCoupons();
    }, [pagination.currentPage, filters]);

    const fetchCoupons = async () => {
        console.log('fetching coupons');
        const response = await couponSdk.getMany({
            ...filters,
            page: pagination.currentPage,
            count: pagination.countPerPage,
        });
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.error('Error getting invoices:', response);
            return;
        }

        setPagination((pagination) => ({
            ...pagination,
            totalPages:
                response.data.total === 0
                    ? 1
                    : Math.ceil(response.data.total / pagination.countPerPage),
        }));
        mapRows(response.data.result);
    };

    const mapRows = (coupons: Coupon[]) => {
        const couponRows = coupons.map((coupon) => {
            return {
                id: coupon.id,
                data: [
                    { type: 'number', value: coupon.id },
                    {
                        type: 'string',
                        value: coupon.code,
                    },
                    {
                        type: 'string',
                        value: `-${coupon.discount}%`,
                    },
                    {
                        type: 'string',
                        value: `${coupon.quantity} ${
                            coupon.quantity === 1 ? 'disponible' : 'disponibles'
                        }`,
                    },
                    { type: 'string', value: coupon.description || '-' },
                    {
                        type: 'string',
                        value: coupon.plan.description,
                    },
                    {
                        type: 'date',
                        value: new Date(coupon.issueDate),
                    },
                ],
            };
        });
        setTableRows(couponRows);
    };

    const goToView = (ev: MouseEvent, url: string) => {
        ev.preventDefault();
        navigate(url);
    };

    const goToPage = (page: number) => {
        if (page < 1 || page > pagination.totalPages) return;

        setPagination((prev) => ({
            ...prev,
            currentPage: page,
        }));
    };

    const addFilter = (attr: string, value: string) => {
        if (!value || value.trim().length === 0) {
            setFilters((prev) => ({ ...prev, [attr]: undefined }));
            return;
        }
        setFilters((prev) => ({ ...prev, [attr]: value }));
    };

    const goToCouponDetails = (id: number) => {
        navigate(`/promotions/${id}`);
    };

    return (
        <main className={styles.view}>
            <Header
                mode={theme}
                title={'Promociones'}
                subtitle={
                    'Aquí puedes ver cupones, descuentos y demás promociones'
                }
            />
            <ModuleSummary
                mode={theme}
                summary={[
                    { label: 'Total descuentos realizadas', value: '-' },
                    { label: 'Monto total descontado', value: '-' },
                    { label: 'Total cupones canjeados este mes', value: '-' },
                    { label: 'Fecha último descuento', value: '-' },
                ]}
            />
            <section className={styles.creationButtons}>
                <button
                    onClick={(ev) => goToView(ev, '/promotions/coupons/create')}
                >
                    Crear cupón
                </button>
                <button
                    onClick={(ev) =>
                        goToView(ev, '/promotions/gift-card/create')
                    }
                >
                    Crear gift card
                </button>
            </section>
            <div className={'view-filtering-section'}>
                <span className={'filtering-label'}>Buscar por:</span>
                <FilteringButton
                    text={'Código de cupón'}
                    onSetFilter={(code) =>
                        addFilter('code', code.toUpperCase())
                    }
                />
            </div>
            <Table
                mode={theme}
                header={[
                    'N° Coupón',
                    'Código',
                    'Descuento',
                    'Cantidad restante',
                    'Descripción',
                    'Plan',
                    'Fecha de emisión',
                ]}
                data={tableRows}
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                onItemSelected={goToCouponDetails}
                onPageChange={goToPage}
            />
        </main>
    );
};

export default PromotionsView;
