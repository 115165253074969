import styles from './payment-details-card.module.scss';
import CreditCardIcon from './credit-card.svg';
import { Payment } from '../../types/payment.type';
import NumberUtil, { Currency } from '../../utils/number.util';
import {
    mapPaymentStatusColor,
    mapPaymentStatusDescription,
} from '../../utils/payment-status.util';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import NarrowRightIcon from '../../icons/narrow-right/narrow-right.icon';

interface Props {
    payment: Payment;
    onLink: (id: number) => void;
}

const PaymentDetailsCard = (props: Props) => {
    return (
        <div className={styles.card}>
            <img src={CreditCardIcon} />
            <div className={styles.details}>
                <span className={styles.amount}>
                    {NumberUtil.formatCurrency(
                        props.payment.amount,
                        props.payment.currency as Currency
                    )}{' '}
                </span>
                <span>
                    {format(
                        new Date(props.payment.createdAt),
                        'dd MMMM, yyyy',
                        { locale: es }
                    )}
                </span>
                <span>Ref: {props.payment.referenceCode}</span>
            </div>
            <div className={styles.details} style={{ alignItems: 'flex-end' }}>
                <div
                    className={styles.status}
                    style={{
                        backgroundColor: mapPaymentStatusColor(
                            props.payment.status
                        ),
                    }}
                >
                    <div
                        className={styles.expandedColor}
                        style={{
                            backgroundColor: mapPaymentStatusColor(
                                props.payment.status
                            ),
                        }}
                    />
                    <span className={styles.tooltip}>
                        {mapPaymentStatusDescription(props.payment.status)}
                    </span>
                </div>
                <div style={{ cursor: 'pointer' }}>
                    <NarrowRightIcon
                        size={24}
                        onClick={() => props.onLink(props.payment.id)}
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentDetailsCard;
