interface Props {
    color?: string;
    size?: number;
    onClick?: () => void;
    className?: string;
}

const EditIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            fill="none"
            onClick={() => props.onClick?.()}
            className={props.className}
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.71 4.04205C21.1 3.6521 21.1 3.02219 20.71 2.6322L18.37 0.29245C18.1832 0.105225 17.9295 0 17.665 0C17.4005 0 17.1469 0.105225 16.96 0.29245L15 2.25226L18.75 6.00186L20.71 4.04205ZM4 13.2511L14 3.25214L17.75 7.00174L7.75 17.0007H4V13.2511ZM6 15.0009H6.91998L14.92 7.00174L14 6.08185L6 14.081V15.0009ZM24 20.0004H0V24H24V20.0004Z"
                    fill={props.color || '#565454'}
                />
            </g>
        </svg>
    );
};

export default EditIcon;
