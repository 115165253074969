import { Company } from '../../types/company.type';
import { generateUUID } from '../../utils/uuid.util';

import styles from './company-search-input.module.scss';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

interface Props {
    placeholder?: string;
    companies: Company[];
    onSearch: (name: string) => void;
    onSelect: (id: number) => void;
}

const CompanySearchInput = ({
    placeholder,
    companies,
    onSearch,
    onSelect,
}: Props) => {
    let searchTimeout: NodeJS.Timeout;

    const [listShown, setListShown] = useState(false);
    const [companySelected, setCompanySelected] = useState<Company>();
    const [activeSearch, setActiveSearch] = useState(false);

    useEffect(() => {
        setListShown(true);
    }, [companies]);

    const search = (query: string) => {
        if (searchTimeout) clearTimeout(searchTimeout);
        if (!query || query.length < 3) {
            return clearCompany();
        }

        searchTimeout = setTimeout(() => {
            setActiveSearch(true);
            onSearch(query);
        }, 1500);
    };

    const selectCompany = (company: Company) => {
        setListShown(false);
        setCompanySelected(company);
        onSelect(company.id);
    };

    const clearCompany = () => {
        setListShown(false);
        setCompanySelected(undefined);
        setActiveSearch(false);
    };

    return (
        <div className={styles.container}>
            {companySelected ? (
                <div className={styles.selected}>
                    <span>{companySelected.displayName}</span>
                    <span className={styles.clear} onClick={clearCompany}>
                        ×
                    </span>
                </div>
            ) : (
                <input
                    className={classNames({
                        [styles.input]: true,
                        [styles.activeSearch]: activeSearch,
                    })}
                    placeholder={placeholder}
                    onChange={(ev) => search(ev.target.value.trim())}
                />
            )}
            {listShown && companies.length > 0 && (
                <ul className={styles.list}>
                    {companies.map((company) => (
                        <li
                            key={generateUUID()}
                            onClick={() => selectCompany(company)}
                        >
                            {company.displayName}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CompanySearchInput;
