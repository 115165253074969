import { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './menu-link.module.scss';
import { Link } from 'react-router-dom';

interface Props {
    here: boolean;
    path: string;
    icon: ReactElement;
    label: string;
}

const MenuLink = (props: Props) => {
    return (
        <li
            className={classNames({
                [styles.item]: true,
                [styles.selected]: props.here,
            })}
        >
            <Link to={props.path}>
                {props.icon}
                {props.label}
            </Link>
        </li>
    );
};

export default MenuLink;
