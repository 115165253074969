import { useCallback, useState } from 'react';

import Modal from '../../../components/modal/modal.component';
import CustomSelect from '../../../components/custom-select/custom-select.component';

import styles from './export-users-modal.module.scss';
import { ExportUsersFileType } from '../types';

interface Props {
    loading: boolean;
    onDownload: (type: ExportUsersFileType) => void;
    onDiscard: () => void;
}

const ExportUsersModal = ({ loading, onDownload, onDiscard }: Props) => {
    const [fileType, setFileType] = useState<ExportUsersFileType>();

    const handleConfirmation = useCallback(() => {
        fileType && onDownload(fileType);
    }, [fileType]);

    return (
        <Modal
            confirmText={'Descargar arhcivo'}
            confirmLoading={loading}
            onConfirm={handleConfirmation}
            onDiscard={onDiscard}
        >
            <div className={styles.header}>
                <h4>Crear usuario</h4>
            </div>
            <div className={styles.content}>
                <label>Selecciona el tipo de archivo</label>
                <CustomSelect
                    placeholder="Tipo de usuarios"
                    width="100%"
                    options={[
                        { value: 'active', displayText: 'Activos' },
                        { value: 'inactive', displayText: 'Inactivos' },
                        { value: 'no-sub', displayText: 'Sin suscripción' },
                    ]}
                    onSelect={setFileType}
                />
            </div>
        </Modal>
    );
};

export default ExportUsersModal;
