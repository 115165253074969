interface Props {
    color?: string;
    size?: number;
    onClick?: () => void;
}

const BooleanIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
            onClick={props.onClick}
        >
            <path
                d="M7 7h10a5 5 0 010 10H7A5 5 0 017 7zm10 8a3 3 0 100-6 3 3 0 000 6z"
                fillRule="evenodd"
                fill={props.color || '#565454'}
            />
        </svg>
    );
};

export default BooleanIcon;
