import { addQueryParams, getAuthToken, handleResponseError } from './service';
import axios, { AxiosError } from 'axios';
import { CouponRequest } from '../types/coupon-request.type';
import { GiftCardRequest } from '../types/gift-card-request.type';
import { PaginatedQuery } from '../types/paginated-query.type';
import { ServiceResponse } from '../types/service-response.type';

type QueryParams = {
    code?: string;
    type?: number;
    issueDate?: string;
    expiresAt?: string;
} & PaginatedQuery;

const PAYMENTS_BASE_URL = process.env.REACT_APP_PAYMENTS_API;
const PAYMENTS_ADMIN_URL = process.env.REACT_APP_PAYMENTS_ADMIN_API;

const createCoupon = async (data: CouponRequest): Promise<ServiceResponse> => {
    console.log('Coupon Service: create');
    console.log('data:', data);
    try {
        const response = await axios.post(
            `${PAYMENTS_ADMIN_URL}/coupons`,
            data,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const createGiftCard = async (
    data: GiftCardRequest
): Promise<ServiceResponse> => {
    console.log('Coupon Service: create');
    try {
        const response = await axios.post(
            `${PAYMENTS_BASE_URL}/coupons/gift-card`,
            data,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getMany = async (queryParams?: QueryParams): Promise<ServiceResponse> => {
    console.log('Coupon Service: getMany');
    try {
        const inlineParams = addQueryParams(queryParams);
        const response = await axios.get(
            `${PAYMENTS_BASE_URL}/coupons${inlineParams}`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getById = async (id: number): Promise<ServiceResponse> => {
    console.log('Coupon Service: getById');
    try {
        const response = await axios.get(`${PAYMENTS_BASE_URL}/coupons/${id}`, {
            headers: { Authorization: getAuthToken() },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const couponSdk = {
    createCoupon,
    createGiftCard,
    getMany,
    getById,
};
