import styles from './phone-input.module.scss';
import './flags.scss';
import countriesJson from './countries.json';
import {
    ChangeEvent,
    FocusEvent,
    useEffect,
    useState,
    ReactElement,
    useRef,
} from 'react';
import {
    PhoneNumber,
    PhoneNumberFormat as PNF,
    PhoneNumberUtil,
} from 'google-libphonenumber';
import classNames from 'classnames';
import StringUtil from '../../utils/string.util';

type Country = {
    name: string;
    code: string;
    dialCode: string;
};

interface Props {
    mode?: string;
    placeholder?: string;
    countryISOCode?: string;
    value?: string;
    width?: number | string;
    onChange?: (phoneNumber: string) => void;
}

const phoneUtil = PhoneNumberUtil.getInstance();

const PhoneInput = (props: Props) => {
    const modalRef = useRef<HTMLDivElement | null>(null);

    const [isoCode, setIsoCode] = useState('');
    const [dialCode, setDialCode] = useState('');
    const [phone, setPhone] = useState('');
    const [countryList, setCountryList] = useState<Country[]>(countriesJson);
    const [focused, setFocused] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [modalShown, setModalShown] = useState(false);

    useEffect(() => {
        if (!props.value || props.value.length < 3) return;
        const country = props.countryISOCode ?? '';
        const phoneNumber = phoneUtil.parse(props.value, country);
        setIsoCode(phoneUtil.getRegionCodeForNumber(phoneNumber)!);

        const [countryCode, phoneWithoutCode] = phoneUtil
            .format(phoneNumber, PNF.INTERNATIONAL)
            .split(' ');
        setDialCode(countryCode);
        setPhone(phoneWithoutCode);
    }, [props.value, props.countryISOCode]);

    const checkJustDigitsAllowed = (ev: React.KeyboardEvent) => {
        if (isNaN(Number(ev.key))) return ev.preventDefault();
    };

    const checkIsValid = (number: string): PhoneNumber | boolean => {
        if (number.length < 3) return false;
        try {
            const phoneNumber = phoneUtil.parse(
                `${dialCode}${number}`,
                props.countryISOCode ?? ''
            );
            if (!phoneUtil.isValidNumber(phoneNumber)) {
                return false;
            }

            return phoneNumber;
        } catch (err) {
            return false;
        }
    };

    const checkAndFormat = (ev: ChangeEvent<HTMLInputElement>) => {
        const userInput = ev.target.value.trim();
        const phoneNumber = checkIsValid(userInput);
        if (!phoneNumber) {
            return setPhone(userInput);
        }
        setIsValid(true);
        const intFormat = phoneUtil.format(
            phoneNumber as PhoneNumber,
            PNF.INTERNATIONAL
        );
        setPhone(intFormat.split(' ')[1]);
        props.onChange?.(intFormat);
    };

    const onBlur = (ev: FocusEvent) => {
        setFocused(false);
        const valid = checkIsValid((ev.target as HTMLInputElement).value);
        setIsValid(!!valid);
    };

    const selectCountry = (country: Country) => {
        setIsoCode(country.code);
        setDialCode(country.dialCode);
        setModalShown(false);
    };

    // TODO: filtering the country by user's input
    const filterCountry = (userInput: string) => {
        const searchPhrase = StringUtil.normalize(userInput);
        if (!searchPhrase || searchPhrase === '') setCountryList(countriesJson);

        const countries = (countriesJson as Country[]).filter((country) => {
            const countryName = StringUtil.normalize(country.name);
            return countryName.includes(searchPhrase);
        });
        setCountryList(countries);
    };

    const renderCountryList = (countries: Country[]): ReactElement[] => {
        return countries.map((country, idx) => {
            return (
                <li
                    className={styles.countryItem}
                    key={idx}
                    onClick={() => selectCountry(country)}
                >
                    <span
                        className={`${
                            styles.flag
                        } flag-${country.code.toLowerCase()}`}
                    />
                    <span>{country.name}</span>
                    <span>{country.dialCode}</span>
                </li>
            );
        });
    };

    const showModal = () => {
        setModalShown(true);
        setTimeout(() => modalRef.current!.focus(), 100);
    };

    return (
        <div
            className={classNames({
                [styles.container]: true,
                [styles.invalid]: !isValid,
                [styles.focused]: focused,
            })}
        >
            <div className={styles.countryCode} onClick={showModal}>
                <span
                    className={`${styles.flag} flag-${isoCode.toLowerCase()}`}
                />
                <span>{dialCode}</span>
                <div className={styles.arrowDown} />
            </div>
            <input
                type={'text'}
                className={styles.phoneNumber}
                placeholder={props.placeholder}
                onKeyPress={checkJustDigitsAllowed}
                onChange={checkAndFormat}
                value={phone}
                onFocus={() => setFocused(true)}
                onBlur={onBlur}
            />
            <div
                className={classNames({
                    [styles.modalBackground]: true,
                    [styles.shown]: modalShown,
                })}
                onClick={() => setModalShown(false)}
            >
                <div
                    ref={modalRef}
                    className={styles.modal}
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <input
                        className={styles.countryInput}
                        type={'text'}
                        placeholder={'Indica el país'}
                        onChange={(ev) => filterCountry(ev.target.value)}
                    />
                    <div className={styles.listContainer}>
                        <ul className={styles.countryList}>
                            {renderCountryList(countryList)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhoneInput;
