import { ReactElement } from 'react';

import styles from './modal.module.scss';
import Loader from '../loader/loader';
import classNames from 'classnames';

interface Props {
    target?: any;
    children: ReactElement | ReactElement[];
    onConfirm: (target?: any) => void;
    discardText?: string;
    onDiscard?: () => void;
    confirmText?: string;
    confirmLoading?: boolean;
}

const Modal = (props: Props) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.fade} onClick={props.onDiscard} />
            <div className={styles.modal}>
                {props.children}
                <div className={styles.buttons}>
                    {props.onDiscard && (
                        <button
                            className={styles.discardButton}
                            onClick={props.onDiscard}
                        >
                            {props.discardText ?? 'Descartar'}
                        </button>
                    )}
                    <button
                        className={classNames({
                            [styles.confirmButton]: true,
                            [styles.loading]: props.confirmLoading,
                        })}
                        onClick={() => props.onConfirm(props.target)}
                    >
                        {props.confirmLoading ? (
                            <>
                                <Loader size={14} /> Cargando...
                            </>
                        ) : (
                            props.confirmText ?? 'Confirmar'
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
