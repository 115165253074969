import { useEffect, useState } from 'react';

import styles from './stats-card.module.scss';
import StatsUpIcon from './icons/stats-up.svg';
import StatsDownIcon from './icons/stats-down.svg';

interface Props {
    mode?: string;
    label: string;
    value: string;
    icon:
        | 'invoice'
        | 'expenses'
        | 'profit'
        | 'user'
        | 'calendar'
        | 'stats'
        | 'emergency';
    deviation: number;
}

const StatsCard = (props: Props) => {
    const [positiveDeviation, setPositiveDeviation] = useState(true);
    const [deviationShown] = useState(false);

    useEffect(() => {
        setPositiveDeviation(props.deviation >= 0);
    }, [props.deviation]);

    const renderIcon = (icon: string): string => {
        switch (icon) {
            case 'expenses':
            case 'invoice':
                return '🧾';
            case 'profit':
                return '💸';
            case 'user':
                return '🧍';
            case 'calendar':
                return '📅';
            case 'emergency':
                return '🚨';
            case 'stats':
            default:
                return '📈';
        }
    };

    return (
        <div className={styles.card}>
            <header>
                <div className={styles.iconContainer}>
                    {renderIcon(props.icon)}
                </div>
                {deviationShown && (
                    <div className={styles.deviationContainer}>
                        <img
                            src={
                                positiveDeviation ? StatsUpIcon : StatsDownIcon
                            }
                        />
                        <b
                            className={
                                positiveDeviation
                                    ? styles.positive
                                    : styles.negative
                            }
                        >
                            {positiveDeviation && '+'}
                            {props.deviation}%
                        </b>
                    </div>
                )}
            </header>
            <label>{props.label}</label>
            <b className={styles.value}>{props.value}</b>
        </div>
    );
};

export default StatsCard;
