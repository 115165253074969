import { useEffect, useState, FocusEvent } from 'react';

import Header from '../../components/header/header.component';
import HDivider from '../../components/h-divider/h-divider.component';
import GoBackView from '../../components/go-back-view/go-back-view';
import { usePaymentStore, useThemeStore } from '../../store';
import BasicButton from '../../components/basic-button/basic-button.component';
import { Currency } from '../../types/currency.type';
import { paymentSdk } from '../../services/payment.service';

import styles from './currency-config.module.scss';

const CurrencyConfigView = () => {
    const { theme } = useThemeStore();
    const { currencies: defaultCurrencies, fetchCurrencies } =
        usePaymentStore();

    const [updatedCurrencies, setUpdatedCurrencies] = useState<
        (Currency & { beenModified: boolean; onUpdate: boolean })[]
    >([]);

    useEffect(() => {
        fetchCurrencies();
    }, []);

    useEffect(() => {
        setUpdatedCurrencies(
            defaultCurrencies.map((currency) => ({
                ...currency,
                beenModified: false,
                onUpdate: false,
            }))
        );
    }, [defaultCurrencies]);

    const isValidRate = (rate: number) => {
        return !isNaN(rate) && rate > 0;
    };

    const onRateUpdate = (data: { id: number; rate: number }) => {
        const { rate: defaultRate } = defaultCurrencies.find(
            (currency) => currency.id === data.id
        )!;

        setUpdatedCurrencies(
            updatedCurrencies.map((currency) => {
                if (currency.id === data.id) {
                    currency.beenModified =
                        isValidRate(data.rate) && defaultRate !== data.rate;
                    currency.rate = data.rate;
                }
                return currency;
            })
        );
    };

    const setCurrencyOnUpdate = (id: number, onUpdate: boolean) => {
        setUpdatedCurrencies(
            updatedCurrencies.map((currency) => {
                if (currency.id === id) currency.onUpdate = onUpdate;
                return currency;
            })
        );
    };

    const onUpdate = async (currencyId: number) => {
        const { rate } = updatedCurrencies.find(
            (currency) => currency.id === currencyId
        )!;
        setCurrencyOnUpdate(currencyId, true);
        await paymentSdk.updateCurrency({ id: currencyId, rate });
        setCurrencyOnUpdate(currencyId, false);
        setUpdatedCurrencies((prev) =>
            prev.map((currency) => {
                if (currency.id === currencyId) {
                    currency.beenModified = false;
                }
                return currency;
            })
        );
    };

    const onInputBlur = (ev: FocusEvent) => {
        const target = ev.target as HTMLInputElement;
        const value = Number(target.value);
        if (isNaN(value)) return;
        return (target.value = value.toFixed(2));
    };

    return (
        <main className={styles.view}>
            <Header
                mode={theme}
                title={'Configuración de monedas'}
                subtitle={
                    'Aquí puedes manejar la tasa de conversión de cada divisa para mostrar los precios de los diferentes planes de PANA en distintas monedas.'
                }
            />
            <HDivider />
            <GoBackView />
            <form>
                {updatedCurrencies.map((currency) => (
                    <div key={currency.id} className={styles.row}>
                        <span className={styles.name}>{currency.currency}</span>
                        <span>→</span>
                        <input
                            defaultValue={currency.rate.toFixed(2)}
                            className={styles.value}
                            onChange={(ev) =>
                                onRateUpdate({
                                    id: currency.id,
                                    rate: Number(ev.target.value),
                                })
                            }
                            disabled={currency.currency === 'USD'}
                            onBlur={onInputBlur}
                        />
                        {currency.beenModified && (
                            <BasicButton
                                text={'Actualizar'}
                                style={{ height: 28, marginLeft: 14 }}
                                disabled={currency.onUpdate}
                                onClick={() => onUpdate(currency.id)}
                            />
                        )}
                    </div>
                ))}
            </form>
        </main>
    );
};

export default CurrencyConfigView;
