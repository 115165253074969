import { create } from 'zustand';
import { Gateway } from './types/gateway.type';
import { paymentSdk } from './services/payment.service';
import ResponseCodes from './constants/response.codes';
import { Service } from './types/service.type';
import { subscriptionSdk } from './services/subscription.service';
import { Theme } from './types/theme.type';
import { Plan } from './types/plan.type';
import { Currency } from './types/currency.type';

interface ThemeState {
    theme: Theme;
    setTheme: (theme: Theme) => void;
}

export const useThemeStore = create<ThemeState>((set) => ({
    theme: 'light',
    setTheme: (theme: Theme) => set({ theme }),
}));

interface AuthState {
    user?: {
        id: number;
        email: string;
        accessToken: string;
    };
    login: (user: any) => void;
    logout: () => void;
}

export const useAuthStore = create<AuthState>((set) => ({
    user:
        localStorage.getItem('user') &&
        JSON.parse(localStorage.getItem('user')!),
    login: (user: any) => {
        set({ user });
        localStorage.setItem('user', JSON.stringify(user));
    },
    logout: () => {
        set({ user: undefined });
        localStorage.removeItem('user');
    },
}));

interface PaymentState {
    methods: Gateway[];
    currencies: Currency[];
    fetchMethod: () => void;
    fetchCurrencies: () => void;
    updateCurrency: (update: { id: number; rate: number }) => void;
}

export const usePaymentStore = create<PaymentState>((set, get) => ({
    methods: [],
    currencies: [],
    fetchMethod: async () => {
        if (get().methods.length > 0) return;
        const response = await paymentSdk.getMethods({ isActive: true });
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('[!] Error getting methods:', response);
        }
        set({ methods: response.data });
    },
    fetchCurrencies: async () => {
        const response = await paymentSdk.getCurrencyConfig();
        if (response.code !== ResponseCodes.PROCESS_OK) return;
        set({ currencies: response.data });
    },
    updateCurrency: async (update) => {
        const response = await paymentSdk.updateCurrency(update);
        if (response.code !== ResponseCodes.PROCESS_OK) return;
        const updatedCurrency = response.data as Currency;
        set({
            currencies: get().currencies.map((currency) => {
                if (currency.id === updatedCurrency.id) {
                    return updatedCurrency;
                }
                return currency;
            }),
        });
    },
}));

interface ServiceState {
    services: Service[];
    plans: Plan[];
    fetchServices: () => void;
    fetchPlans: (params?: Record<string, any>) => void;
}

export const useServiceStore = create<ServiceState>((set, get) => ({
    services: [],
    plans: [],
    fetchServices: async () => {
        if (get().services.length > 0) return;
        const response = await subscriptionSdk.getServices();
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('[!] Error getting services:', response);
        }
        set({ services: response.data });
    },
    fetchPlans: async (params?: Record<string, any>) => {
        if (get().plans.length > 0) return;
        const response = await subscriptionSdk.getPlans(params);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('[!] Error getting services:', response);
        }
        set({ plans: response.data });
    },
}));
