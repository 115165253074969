import { ReactElement, useRef, useState } from 'react';
import classNames from 'classnames';

import { Invoice } from '../../types/invoice.type';
import NumberUtil from '../../utils/number.util';

import styles from './search-invoice-input.module.scss';

interface Props {
    results: any[];
    mode?: string;
    placeholder?: string;
    width?: number;
    onSearch: (id: number) => void;
    onSelect: (invoice: Invoice) => void;
    onClear?: () => void;
}

const SearchInvoiceInput = (props: Props) => {
    let searchTimeout: NodeJS.Timeout;

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [activeSearch, setActiveSearch] = useState(false);

    const clearResults = () => {
        setActiveSearch(false);
    };

    const onSearch = (value: string) => {
        if (searchTimeout) clearTimeout(searchTimeout);
        if (!value) {
            props.onClear?.();
            return clearResults();
        }

        searchTimeout = setTimeout(() => {
            setActiveSearch(true);
            props.onSearch(Number(value));
        }, 800);
    };

    const selectInvoice = (invoice: Invoice) => {
        if (inputRef.current) {
            inputRef.current.value = `${invoice.id} - ${invoice.total}`;
        }
        props.onSelect(invoice);
        clearResults();
    };

    const renderResults = (
        invoices: Invoice[]
    ): ReactElement | ReactElement[] => {
        if (invoices.length === 0) {
            return (
                <li>
                    <span className={styles.name}>
                        No se encontraron facturas
                    </span>
                </li>
            );
        }
        return invoices.map((invoice, idx) => {
            return (
                <li key={idx} onClick={() => selectInvoice(invoice)}>
                    <span className={styles.foundElement}>
                        {invoice.id} -{' '}
                        {NumberUtil.formatCurrency(invoice.total)}
                    </span>
                </li>
            );
        });
    };

    return (
        <div
            className={styles.wrapper}
            style={{ width: props.width ?? '100%' }}
        >
            <input
                ref={inputRef}
                type={'text'}
                placeholder={props.placeholder}
                className={classNames({
                    [styles.searchInput]: true,
                    [styles.activeSearch]: activeSearch,
                })}
                onChange={(ev) => onSearch(ev.target.value.trim())}
            />
            {activeSearch && (
                <ul className={styles.resultList}>
                    {renderResults(props.results)}
                </ul>
            )}
        </div>
    );
};

export default SearchInvoiceInput;
