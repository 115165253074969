import { CSSProperties, MouseEvent, PropsWithChildren } from 'react';

import styles from './basic-button.module.scss';

interface Props extends PropsWithChildren {
    text?: string;
    width?: number | string;
    style?: CSSProperties;
    disabled?: boolean;
    mode?: string;
    onClick?: () => void;
    shortcut?: string;
}

const BasicButton = (props: Props) => {
    const onClick = (ev: MouseEvent) => {
        ev.preventDefault();
        props.onClick?.();
    };

    return (
        <button
            className={styles.basicButton}
            style={{ ...props.style, width: props.width ?? 'fit-content' }}
            disabled={props.disabled ?? false}
            onClick={onClick}
        >
            {props.text ?? props.children}
            {props.shortcut && (
                <span className={styles.shortcut}>{props.shortcut}</span>
            )}
        </button>
    );
};

export default BasicButton;
