import { LegacyRef } from 'react';

interface Props {
    inputRef: LegacyRef<HTMLInputElement>;
    onChange: (value: string) => void;
}

const DateFilter = ({ inputRef, onChange }: Props) => {
    const onValueUpdate = (value: string) => {
        const days = Number(value);
        if (isNaN(days) || days < 1) {
            return onChange('');
        }
        return onChange(days.toString());
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <span>En los últimos:</span>
            <div style={{ display: 'flex', gap: 10 }}>
                <input
                    ref={inputRef}
                    type={'number'}
                    onChange={(ev) => onValueUpdate(ev.target.value.trim())}
                />
                <span> días</span>
            </div>
        </div>
    );
};

export default DateFilter;
