type ShortcutCallback = () => void;

const activeShortcutActions = new Map<string, any>();

const initShortcut = (key: string, action: ShortcutCallback) => {
    const listener = (ev: KeyboardEvent) => ev.key === key && action();
    activeShortcutActions.set(JSON.stringify(action), listener);
    window.addEventListener('keypress', listener, true);
};

const removeShortcut = (action: ShortcutCallback) => {
    const listener = activeShortcutActions.get(JSON.stringify(action));
    window.removeEventListener('keypress', listener, true);
    activeShortcutActions.delete(JSON.stringify(action));
};

export { initShortcut, removeShortcut };
