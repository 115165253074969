import { handleResponseError } from './service';
import axios, { AxiosError } from 'axios';
import { Credentials } from '../types/credentials.type';
import { ServiceResponse } from '../types/service-response.type';

const AUTH_BASE_URL = process.env.REACT_APP_AUTH_URL;

const logIn = async (credentials: Credentials): Promise<ServiceResponse> => {
    try {
        const response = await axios.post(
            `${AUTH_BASE_URL}/operators`,
            credentials
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const authSdk = {
    logIn,
};
