import axios, { AxiosError } from 'axios';
import { ServiceResponse } from '../types/service-response.type';
import { CreateSubscription } from '../types/create-subscription.type';
import { addQueryParams, getAuthToken, handleResponseError } from './service';

const SUBSCRIPTIONS_BASE_URL = process.env.REACT_APP_SUBSCRIPTIONS_API;
const SUBSCRIPTIONS_ADMIN_URL = process.env.REACT_APP_SUBSCRIPTIONS_ADMIN_API;

interface UpdateData {
    status?: number;
    suspendedAt?: string;
}

const getServices = async (): Promise<ServiceResponse> => {
    console.log('SubscriptionService: getServices');
    try {
        const response = await axios.get(
            `${SUBSCRIPTIONS_BASE_URL}/services/active`,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const create = async (data: CreateSubscription): Promise<ServiceResponse> => {
    console.log('SubscriptionService: create');
    try {
        const response = await axios.post(
            `${SUBSCRIPTIONS_BASE_URL}/subscriptions`,
            data,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const updateSubscription = async (
    id: number,
    data: UpdateData
): Promise<ServiceResponse> => {
    console.log('SubscriptionService: update');
    try {
        const response = await axios.patch(
            `${SUBSCRIPTIONS_ADMIN_URL}/subscriptions/${id}`,
            data,
            { headers: { Authorization: getAuthToken() } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

type PlanFilters = {
    active?: boolean;
    isPublic?: boolean;
    isForBusiness?: boolean;
};

const getPlans = async (filters?: PlanFilters): Promise<ServiceResponse> => {
    console.log('SubscriptionService: getPlans');
    try {
        const params = addQueryParams(filters);
        const response = await axios.get(
            `${SUBSCRIPTIONS_ADMIN_URL}/plans${params}`,
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

type SubmitBulkUpload = {
    company?: number;
    action: number;
    planId?: number;
    file: File;
};

const bulkUpload = async (data: SubmitBulkUpload): Promise<ServiceResponse> => {
    console.log('SubscriptionService: getPlans');
    const form = new FormData();
    form.append('file', data.file);
    form.append('action', data.action.toString());

    data.planId && form.append('planId', data.planId.toString());
    data.company && form.append('teamId', data.company.toString());

    try {
        const response = await axios.post(
            `${SUBSCRIPTIONS_ADMIN_URL}/subscriptions/bulk-upload`,
            form,
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

type UserInclusion = {
    userId: number;
    companyId: number;
    planId: number;
};

const includeUserToCompany = async (
    data: UserInclusion
): Promise<ServiceResponse> => {
    console.log('SubscriptionService: includeUserToCompany');
    try {
        const response = await axios.post(
            `${SUBSCRIPTIONS_ADMIN_URL}/companies/${data.companyId}/users`,
            {
                userId: data.userId,
                planId: data.planId,
            },
            {
                headers: { Authorization: getAuthToken() },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const subscriptionSdk = {
    getServices,
    create,
    updateSubscription,
    getPlans,
    bulkUpload,
    includeUserToCompany,
};
