import { MouseEvent, useRef, useState } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import { Subscription } from '../../../types/subscription.type';
import HDivider from '../../../components/h-divider/h-divider.component';
import { mapSubscriptionStatus } from '../../../utils/user-subscription.util';
import { generateUUID } from '../../../utils/uuid.util';
import ThreeDotsIcon from '../../../icons/three-dots.icon';
import {
    SubscriptionAction,
    SubscriptionDetailsPopover,
} from '../components/subscription-details-popover';

import styles from './user-subscriptions.module.scss';

interface UserSubscriptionsProps {
    subscriptions?: Subscription[];
    onAction: (subscriptionId: number, action: SubscriptionAction) => void;
}

const UserSubscriptions = ({
    subscriptions,
    onAction,
}: UserSubscriptionsProps) => {
    const containerRef = useRef<HTMLTableSectionElement | null>(null);
    const [popoverProps, setPopoverProps] = useState({
        shown: false,
        top: 0,
    });
    const [selectedSubscription, setSelectedSubscription] =
        useState<Subscription>();

    const hasSubscriptions = (subscriptions?: Subscription[]): boolean => {
        return !!subscriptions && subscriptions.length > 0;
    };

    const showPopover = (ev: MouseEvent, subscription: Subscription) => {
        ev.stopPropagation();
        if (popoverProps.shown) {
            setPopoverProps({ shown: false, top: 0 });
            setSelectedSubscription(subscription);
            return;
        }
        const targetRect = (ev.target as HTMLElement).getBoundingClientRect();
        const containerRect = containerRef.current!.getBoundingClientRect();

        setPopoverProps({
            shown: true,
            top: targetRect.top - containerRect.top + 30,
        });
        setSelectedSubscription(subscription);
    };

    const closePopover = () => {
        setPopoverProps({ shown: false, top: 0 });
        setSelectedSubscription(undefined);
    };

    const handleAction = (action: SubscriptionAction) => {
        switch (action) {
            case SubscriptionAction.INACTIVATE:
            default:
                onAction(selectedSubscription!.id, action);
                break;
        }
        closePopover();
    };

    return (
        <section className={styles.subscriptions} ref={containerRef}>
            <h3>Suscripciones</h3>
            {!hasSubscriptions(subscriptions) ? (
                <div className={styles.noSubscription}>
                    No posee ninguna suscripción
                </div>
            ) : (
                <>
                    <HDivider />
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>Plan</span>
                                </th>
                                <th>
                                    <span>Frecuencia</span>
                                </th>
                                <th>
                                    <span>Vencimiento</span>
                                </th>
                                <th style={{ width: '100%' }}>
                                    <span />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {subscriptions!.map((subscription) => (
                                <Row
                                    key={generateUUID()}
                                    subscription={subscription}
                                    onDetails={showPopover}
                                />
                            ))}
                        </tbody>
                    </table>
                    <SubscriptionDetailsPopover
                        shown={popoverProps.shown}
                        top={popoverProps.top}
                        subscriptionStatus={selectedSubscription?.status}
                        onDiscard={closePopover}
                        onAction={handleAction}
                    />
                </>
            )}
        </section>
    );
};

interface RowProps {
    subscription: Subscription;
    onDetails: (ev: MouseEvent, subscription: Subscription) => void;
}

const Row = ({ subscription, onDetails }: RowProps) => {
    const status = mapSubscriptionStatus(subscription.status);

    return (
        <tr>
            <td>
                <div>
                    <span>{subscription.service.name}</span>
                    <span
                        className={styles.status}
                        style={{
                            color: status.color,
                            backgroundColor: status.background,
                        }}
                    >
                        {status.text}
                    </span>
                </div>
            </td>
            <td>
                <span>Facturación anual</span>
            </td>
            <td>
                <span>
                    {format(
                        new Date(subscription.expiresAt),
                        "dd 'de' MMMM, yyyy",
                        {
                            locale: es,
                        }
                    )}
                </span>
            </td>
            <td style={{ width: '100%' }}>
                <div className={styles.moreDetailsContainer}>
                    <div
                        className={styles.moreDetailsIcon}
                        onClick={(ev) => onDetails(ev, subscription)}
                    >
                        <ThreeDotsIcon size={16} />
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default UserSubscriptions;
