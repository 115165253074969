import { User } from '../../types/user.type';
import { mapUserHasSubscription } from '../../utils/user-subscription.util';

const mapRows = (users: User[]) => {
    const userRow = users.map((user) => {
        return {
            id: user.id,
            data: [
                { type: 'number', value: user.id },
                {
                    type: 'string',
                    value: `${user.firstName} ${user.lastName ?? ''}`,
                },
                {
                    type: 'string',
                    value: `${user.email ?? '-'}`,
                },
                {
                    type: 'string',
                    value: `${user.phoneNumber ?? '-'}`,
                },
                {
                    type: 'string',
                    value: `${user.documentType ?? '?'}-${
                        user.document ?? '?'
                    }`,
                },
                {
                    type: 'status',
                    value: mapUserHasSubscription(user),
                },
                {
                    type: 'date',
                    value: new Date(user.createdAt),
                },
            ],
        };
    });

    return userRow;
};

const totalsMapper: { [key: string]: string } = {
    total: 'Total usuarios',
    activeUsers: 'Usuarios activos',
    inactiveUsers: 'Usuarios inactivos',
    noSubscriptionUsers: 'Sin suscripción',
};

export { mapRows, totalsMapper };
