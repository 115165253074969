interface Props {
    color?: string;
    size?: number;
}

const CircleCheckIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37256 18.6274 0 12 0C5.37256 0 0 5.37256 0 12C0 18.6274 5.37256 24 12 24ZM17 4.5L19 6.5L14 12L19 17.5L17 19.5L12 14L7 19.5L5 17.5901L10 12L4.5 7L6.5 5L12 10L17 4.5Z"
                fill={props.color || '#565454'}
            />
        </svg>
    );
};

export default CircleCheckIcon;
