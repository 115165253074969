import Modal from '../../components/modal/modal.component';

import styles from './add-vehicle-modal.module.scss';
import CustomInput from '../../components/custom-input/custom-input.component';
import { object, string } from 'yup';
import { useState } from 'react';
import { VehicleCreation } from '../../types/vehicle.type';
import { userSdk } from '../../services/user.service';
import ResponseCodes from '../../constants/response.codes';

type VehicleForm = {
    brand?: string;
    model?: string;
    color?: string;
    plate?: string;
};

const formSchema = object().shape({
    brand: string().min(3).required(),
    model: string().min(3).required(),
    color: string().min(3).required(),
    plate: string().min(3).required(),
});

interface Props {
    userId: number;
    onConfirm: (vehicle: VehicleCreation) => void;
    onDiscard: () => void;
}

const AddVehicleModal = ({ userId, onConfirm, onDiscard }: Props) => {
    const [form, setForm] = useState<VehicleForm>({});
    const [error, setError] = useState('');

    const addFormField = (attr: string, value: string) => {
        setForm((prev) => ({ ...prev, [attr]: value }));
    };

    const onCreate = async () => {
        setError('');
        const isValid = formSchema.isValidSync(form);
        if (!isValid) {
            return setError(
                'Datos inválidos o incompletos. Revísalos nuevamente'
            );
        }

        const response = await userSdk.addVehicle(userId, form);
        console.log('response:', response);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return setError(response.message);
        }

        onConfirm(form);
    };

    return (
        <Modal onConfirm={onCreate} onDiscard={onDiscard}>
            <div className={styles.modal}>
                <h4>Añadir nuevo vehículo</h4>
                <div className={styles.row}>
                    <div className={styles.inputWrapper}>
                        <label>Marca</label>
                        <CustomInput
                            type={'text'}
                            onChange={(brand) => addFormField('brand', brand)}
                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <label>Modelo</label>
                        <CustomInput
                            type={'text'}
                            onChange={(model) => addFormField('model', model)}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.inputWrapper}>
                        <label>Color</label>
                        <CustomInput
                            type={'text'}
                            onChange={(color) => addFormField('color', color)}
                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <label>Placa</label>
                        <CustomInput
                            type={'text'}
                            max={7}
                            onChange={(plate) => addFormField('plate', plate)}
                        />
                    </div>
                </div>
                {error && <span className={styles.errorMessage}>{error}</span>}
            </div>
        </Modal>
    );
};

export default AddVehicleModal;
