import DashboardIcon from '../../icons/dashboard-icon/dashboard.icon';
import PaperIcon from '../../icons/paper-icon/paper-icon';
import PaymentIcon from '../../icons/payment-icon/payment.icon';
import UserIcon from '../../icons/user-icon/user.icon';
import ConfigIcon from '../../icons/config-icon/config.icon';

export const menuItems = [
    {
        path: '/',
        icon: <DashboardIcon />,
        label: 'Dashboard',
    },
    {
        path: '/invoices',
        icon: <PaperIcon />,
        label: 'Facturas',
    },
    {
        path: '/payments',
        icon: <PaymentIcon />,
        label: 'Pagos',
    },
    {
        path: '/promotions',
        icon: <PaymentIcon />,
        label: 'Promociones',
    },
    {
        path: '/users',
        icon: <UserIcon />,
        label: 'Usuarios',
    },
    {
        path: '/companies',
        icon: <UserIcon />,
        label: 'Compañías',
    },
    {
        path: '/employees',
        icon: <UserIcon />,
        label: 'Empleados',
    },
    {
        path: '/currency-config',
        icon: <ConfigIcon />,
        label: 'Configuración de monedas',
    },
    {
        path: '/remote-config',
        icon: <ConfigIcon />,
        label: 'Configuración remota',
    },
    {
        path: '/settings',
        icon: <ConfigIcon />,
        label: 'Ajustes',
    },
];
