import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { ReactElement } from 'react';
import styles from './table-row.module.scss';

interface Props {
    id?: any;
    mode?: string;
    data: ColumnType[];
    onClick?: (id?: any) => void;
}

type StatusType = {
    bgColor: string;
    txtColor: string;
    value: string;
};

type ColumnType = {
    type: 'text' | 'number' | 'status' | 'date';
    value: string | number | Date | StatusType;
};

const TableRow = (props: Props) => {
    const buildRow = (data: ColumnType[]): ReactElement[] => {
        return data.map((column, idx) => {
            switch (column.type) {
                case 'date':
                    return (
                        <td key={idx}>
                            {format(
                                new Date(column.value.toString()),
                                "dd 'de' MMMM 'de' yyyy",
                                { locale: es }
                            )}
                        </td>
                    );
                case 'status':
                    const status = column.value as StatusType;
                    return (
                        <td key={idx}>
                            <span
                                className={styles.status}
                                style={{
                                    background: status.bgColor,
                                    color: status.txtColor,
                                }}
                            >
                                {status.value}
                            </span>
                        </td>
                    );
                case 'number':
                    return <td key={idx}>{Number(column.value)}</td>;
                case 'text':
                default:
                    return <td key={idx}>{column.value.toString()}</td>;
            }
        });
    };

    return (
        <tr className={styles.row} onClick={() => props.onClick?.(props.id)}>
            {buildRow(props.data)}
        </tr>
    );
};

export default TableRow;
