interface Props {
    color?: string;
    size?: number;
}

const PaymentIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM4 8H20V6H4V8Z"
                    fill={props.color || '#565454'}
                />
            </g>
        </svg>
    );
};

export default PaymentIcon;
