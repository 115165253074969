import { useState } from 'react';
import classNames from 'classnames';

import { RemoteConfigParam } from '../../types/remote-config-param.type';
import CustomSelect from '../custom-select/custom-select.component';
import StringIcon from '../../icons/string.icon';
import NumberIcon from '../../icons/number.icon';
import BooleanIcon from '../../icons/boolean.icon';
import ObjectIcon from '../../icons/object.icon';
import BasicButton from '../basic-button/basic-button.component';

import styles from './edit-remote-config.module.scss';

interface Props {
    param: RemoteConfigParam;
    onDismiss: () => void;
    onUpdate: (id: string, data: any) => void;
}

const typeOptions = [
    {
        displayText: (
            <span className={styles.typeOption}>
                <StringIcon />
                Texto
            </span>
        ),
        value: 'text',
    },
    {
        displayText: (
            <span className={styles.typeOption}>
                <NumberIcon />
                Número
            </span>
        ),
        value: 'number',
    },
    {
        displayText: (
            <span className={styles.typeOption}>
                <BooleanIcon />
                Buleano
            </span>
        ),
        value: 'boolean',
    },
    {
        displayText: (
            <span className={styles.typeOption}>
                <ObjectIcon />
                Objeto
            </span>
        ),
        value: 'object',
    },
];

const EditRemoteConfig = (props: Props) => {
    const [hasChanged, setHasChanged] = useState(false);
    const [newParamData, setNewParamData] = useState({
        key: props.param.key,
        value: props.param.value,
        type: props.param.type,
    });
    const [validValue, setValidValue] = useState(true);

    const updateField = (field: string, value: string) => {
        setNewParamData((prev) => ({ ...prev, [field]: value }));
    };

    const validateValue = (type: string, value: string): boolean => {
        switch (type) {
            case 'number':
                return /^[0-9]+$/.test(value);
            case 'boolean':
                return value === 'true' || value === 'false';
            case 'object':
                try {
                    return !!JSON.parse(value);
                } catch (err) {
                    return false;
                }
            case 'string':
            default:
                return true;
        }
    };

    const parseParamValue = (value: any): any => {
        switch (newParamData.type) {
            case 'number':
                return Number(value);
            case 'object':
                return JSON.parse(value);
            case 'boolean':
                return value === 'true';
            case 'string':
            default:
                return value;
        }
    };

    const onUpdateParam = () => {
        props.onUpdate(props.param.id, newParamData);
        props.onDismiss();
    };

    const updateValue = (input: string) => {
        if (input.trim().length === 0) return;
        setValidValue(validateValue(newParamData.type, input));
        const newValue = parseParamValue(input.trim());

        console.log('new value:', newValue);
        console.log('prev value:', props.param.value);

        if (props.param.type === 'object') {
            if (
                JSON.stringify(props.param.value) !== JSON.stringify(newValue)
            ) {
                setHasChanged(true);
                return updateField('value', newValue);
            } else {
                return setHasChanged(false);
            }
        }

        if (props.param.value === newValue) {
            return setHasChanged(false);
        }
        setHasChanged(true);
        return updateField('value', newValue);
    };

    const updateType = (type: string) => {
        setValidValue(validateValue(type, newParamData.value));
        updateField('type', type);
    };

    return (
        <aside className={styles.container}>
            <div className={styles.fade} onClick={props.onDismiss} />
            <div className={styles.modal}>
                <h4>Editar parámetro</h4>
                <form>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <label>Nombre del parámetro</label>
                            <input
                                defaultValue={props.param.key}
                                onChange={(input) =>
                                    updateField(
                                        'key',
                                        input.target.value.trim()
                                    )
                                }
                            />
                        </div>
                        <div className={styles.col}>
                            <label>Tipo de valor</label>
                            <CustomSelect
                                width={120}
                                options={typeOptions}
                                placeholder={
                                    typeOptions.find(
                                        (opt) => opt.value === props.param.type
                                    )?.displayText
                                }
                                onSelect={(type) => updateType(type)}
                            />
                        </div>
                    </div>

                    <div className={styles.col}>
                        <label>Valor del parámetro</label>
                        <textarea
                            className={classNames({
                                [styles.invalid]: !validValue,
                            })}
                            defaultValue={JSON.stringify(
                                props.param.value,
                                null,
                                4
                            )}
                            onChange={(input) =>
                                updateValue(input.target.value.trim())
                            }
                        />
                    </div>
                    <div>
                        <BasicButton
                            text={'Actualizar'}
                            disabled={!hasChanged}
                            onClick={onUpdateParam}
                        />
                    </div>
                </form>
            </div>
        </aside>
    );
};

export default EditRemoteConfig;
