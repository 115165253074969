import { PropsWithChildren } from 'react';

import styles from './flat-button.module.scss';

interface Props extends PropsWithChildren {
    disabled?: boolean;
    onClick?: () => void;
}

const FlatButton = ({ children, disabled, onClick }: Props) => {
    return (
        <button
            className={styles.flatButton}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default FlatButton;
