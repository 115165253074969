import { useEffect, useState } from 'react';
import classNames from 'classnames';

import Header from '../../components/header/header.component';
import HDivider from '../../components/h-divider/h-divider.component';
import GoBackView from '../../components/go-back-view/go-back-view';
import { remoteConfigSdk } from '../../services/remote-config.service';
import ResponseCodes from '../../constants/response.codes';
import ObjectIcon from '../../icons/object.icon';
import EditIcon from '../../icons/edit-icon/edit.icon';
import DeleteIcon from '../../icons/delete-icon/delete.icon';
import StringIcon from '../../icons/string.icon';
import BooleanIcon from '../../icons/boolean.icon';
import NumberIcon from '../../icons/number.icon';
import EditRemoteConfig from '../../components/edit-remote-config/edit-remote-config';
import { RemoteConfigParam } from '../../types/remote-config-param.type';
import Modal from '../../components/modal/modal.component';

import styles from './remote-config.module.scss';

const RemoteConfigView = () => {
    const [config, setConfig] = useState<any[]>([]);
    const [editingParam, setEditingParam] = useState<RemoteConfigParam>();
    const [deletionRequest, setDeletionRequest] = useState<string>();

    useEffect(() => {
        fetchRemoteConfig();
    }, []);

    const fetchRemoteConfig = async () => {
        const response = await remoteConfigSdk.getAll();
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error(
                '[!] Error getting remote config params:',
                response
            );
        }
        setConfig(response.data);
    };

    const renderTypeIcon = (type: string) => {
        switch (type) {
            case 'string':
                return <StringIcon />;
            case 'number':
                return <NumberIcon />;
            case 'object':
                return <ObjectIcon />;
            case 'boolean':
                return <BooleanIcon />;
        }
    };

    const renderRow = (items: any[]) => {
        return items.map((item) => (
            <div key={item.id} className={styles.row}>
                <div
                    className={classNames({
                        [styles.col]: true,
                        [styles.key]: true,
                    })}
                >
                    {renderTypeIcon(item.type)}
                    <span>{item.key}</span>
                </div>
                <div
                    className={classNames({
                        [styles.col]: true,
                        [styles.value]: true,
                    })}
                >
                    {JSON.stringify(item.value)}
                </div>
                <div
                    className={classNames({
                        [styles.col]: true,
                        [styles.updatedBy]: true,
                    })}
                >
                    <span>Gustavo Mora, hace 2 días</span>
                </div>
                <div
                    className={classNames({
                        [styles.col]: true,
                        [styles.actions]: true,
                    })}
                >
                    <EditIcon
                        size={20}
                        className={styles.editIcon}
                        onClick={() => setEditingParam(item)}
                    />
                    <DeleteIcon
                        size={20}
                        className={styles.deleteIcon}
                        onClick={() => setDeletionRequest(item.id)}
                    />
                </div>
            </div>
        ));
    };

    const updateParam = async (id: string, newParam: any) => {
        await remoteConfigSdk.update(id, newParam);
        fetchRemoteConfig();
    };

    const deleteParam = async () => {
        if (!deletionRequest) return;
        setDeletionRequest(undefined);
        await remoteConfigSdk.deleteParam(deletionRequest);
        fetchRemoteConfig();
    };

    return (
        <main className={styles.view}>
            <Header
                title={'Configuración Remota'}
                subtitle={
                    'Aquí puedes personalizar aquellos valores que se desean dinámicos en las distintas aplicaciones: móviles y webs. Los parámetros de configuración remote también permiten gestionar pruebas de tipo A/B para realizar pruebas de mercado.'
                }
            />
            <HDivider />
            <GoBackView />
            <section>
                <div className={styles.table}>
                    <div className={styles.searchSection}>
                        <input placeholder={'Buscar parámetro'} />
                    </div>
                    <div className={styles.tableHead}>
                        <span style={{ maxWidth: 160 }}>Nombre</span>
                        <span>Valor</span>
                        <span style={{ maxWidth: 240 }}>
                            Última actualización
                        </span>
                        <span style={{ maxWidth: 60 }}>{''}</span>
                    </div>
                    {renderRow(config)}
                </div>
            </section>
            {editingParam && (
                <EditRemoteConfig
                    param={editingParam}
                    onDismiss={() => setEditingParam(undefined)}
                    onUpdate={updateParam}
                />
            )}
            {deletionRequest && (
                <Modal
                    onConfirm={deleteParam}
                    onDiscard={() => setDeletionRequest(undefined)}
                    confirmText={'Sí, eliminar'}
                    discardText={'No, mantener'}
                >
                    <div className={styles.deletionModal}>
                        <h4>¿Estás seguro?</h4>
                        <span>
                            Estás a punto de eliminar un parámetro de
                            configuración. Confirma si realmente deseas realizar
                            esta acción.
                        </span>
                    </div>
                </Modal>
            )}
        </main>
    );
};

export default RemoteConfigView;
