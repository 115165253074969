import Modal from '../../../components/modal/modal.component';
import HDivider from '../../../components/h-divider/h-divider.component';

import styles from './cancel-subscription.module.scss';

interface Props {
    onConfirm: () => void;
    onDiscard: () => void;
    confirmLoading?: boolean;
}

const InactivateSubscriptionModal = ({
    onConfirm,
    onDiscard,
    confirmLoading,
}: Props) => {
    return (
        <Modal
            onConfirm={onConfirm}
            onDiscard={onDiscard}
            discardText={'No, volver'}
            confirmText={'Sí, cancelar suscripción'}
            confirmLoading={confirmLoading}
        >
            <div className={styles.modal}>
                <h4>Cancelar suscripción</h4>
                <HDivider />
                <p>
                    Si confirmas esta acción la suscripción se cancelará
                    inmediatamente y, en caso de querer ser reactivada,
                    necesitarás contactar a IT.{' '}
                    <span className={styles.bold}>
                        ¿Estás seguro de querer continuar?
                    </span>
                </p>
                <HDivider />
            </div>
        </Modal>
    );
};

export default InactivateSubscriptionModal;
