import styles from './file-input.module.scss';
import { useRef, useState, DragEvent } from 'react';
import PaperIcon from '../../icons/paper-icon/paper-icon';
import CloseIcon from '../../icons/close-icon/close.icon';

type FileState = 'idle' | 'onDrag' | 'filePlaced';

interface Props {
    placeholder?: string;
    accept: string[];
    onFile: (file?: File) => void;
}

const FileInput = ({ placeholder, accept, onFile }: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [fileState, setFileState] = useState<FileState>('idle');
    const [file, setFile] = useState<File>();

    const onPress = () => {
        if (file) return;
        inputRef.current?.click();
    };

    const onFileUploaded = () => {
        const input = inputRef.current!;
        if (!input.files || input.files.length === 0) return;

        const file = input.files[0];
        if (!accept.includes(file.type)) {
            setFileState('idle');
            onFile(undefined);
            return console.error('Invalid file type');
        }

        setFileState('filePlaced');
        setFile(file);
        onFile(file);
    };

    const onRemoveFile = () => {
        setFileState('idle');
        setFile(undefined);
        inputRef.current!.files = null;
        inputRef.current!.value = '';
    };

    const onDrag = (ev: DragEvent) => {
        // Prevent default behaviour to avoid browser to download the file
        ev.preventDefault();
        setFileState('onDrag');
    };

    const onDrop = (ev: DragEvent) => {
        ev.preventDefault();

        const files: DataTransferItemList = ev.dataTransfer.items;
        if (!files || files.length === 0) return;

        const file = files[0].getAsFile()!;
        if (!accept.includes(file.type)) {
            setFileState('idle');
            onFile(undefined);
            return console.error('Invalid file type');
        }

        setFileState('filePlaced');
        setFile(file);
        onFile(file);
    };

    const stateMapper = {
        idle: <span>+ {placeholder ?? 'Añadir archivo'}</span>,
        onDrag: (
            <>
                <span>Suelta el archivo aquí</span>
            </>
        ),
        filePlaced: (
            <>
                <PaperIcon size={16} />
                <span>{file?.name} </span>
                <CloseIcon
                    size={16}
                    stroke={2}
                    className={styles.closeIcon}
                    onClick={onRemoveFile}
                />
            </>
        ),
    };

    return (
        <div
            className={styles.container}
            onClick={onPress}
            onDragOver={onDrag}
            onDragLeave={() => setFileState('idle')}
            onDrop={onDrop}
        >
            <div className={styles.content}>{stateMapper[fileState]}</div>
            <input type={'file'} ref={inputRef} onChange={onFileUploaded} />
        </div>
    );
};

export default FileInput;
