import { ReactElement } from 'react';

import DashboardView from './dashboard/dashboard.view';
import InvoicesView from './invoices/invoices.view';
import InvoiceView from './invoice/invoice.view';
import PaymentsView from './payments/payments.view';
import CreatePaymentView from './create-payment/create-payment.view';
import PaymentView from './payment/payment.view';
import PromotionsView from './promotions/promotions.view';
import CouponDetailsView from './coupon-details/coupon-details.view';
import CreateSubscriptionView from './create-subscription/create-subscription.view';
import UsersView from './users/users.view';
import UserView from './user/user.view';
import CreateCouponView from './create-coupon/create-coupon.view';
import RemoteConfigView from './remote-config/remote-config.view';
import CurrencyConfigView from './currency-config/currency-config.view';
import CompaniesView from './companies/companies.view';
import CompanyDetailsView from './company-details/company-details.view';
import CreateGiftCardView from './create-gift-card/create-gift-card.view';
import AddUserToCompanyView from './add-user-to-comapny/add-user-to-company.view';
import CreateInvoiceView from './create-invoice/create-invoice.view';

type Screen = {
    path: string;
    element: ReactElement;
};

export const screens: Screen[] = [
    {
        path: '/',
        element: <DashboardView />,
    },
    {
        path: '/invoices',
        element: <InvoicesView />,
    },
    {
        path: '/invoices/create',
        element: <CreateInvoiceView />,
    },
    {
        path: '/invoices/:id',
        element: <InvoiceView />,
    },
    {
        path: '/payments',
        element: <PaymentsView />,
    },
    {
        path: '/payments/create',
        element: <CreatePaymentView />,
    },
    {
        path: '/payments/:id',
        element: <PaymentView />,
    },
    {
        path: '/promotions',
        element: <PromotionsView />,
    },
    {
        path: '/promotions/coupons/create',
        element: <CreateCouponView />,
    },
    {
        path: '/promotions/gift-card/create',
        element: <CreateGiftCardView />,
    },
    {
        path: '/promotions/:id',
        element: <CouponDetailsView />,
    },
    {
        path: '/subscriptions/create',
        element: <CreateSubscriptionView />,
    },
    {
        path: '/users',
        element: <UsersView />,
    },
    {
        path: '/users/:id',
        element: <UserView />,
    },
    {
        path: '/companies',
        element: <CompaniesView />,
    },
    {
        path: '/companies/:id',
        element: <CompanyDetailsView />,
    },
    {
        path: '/companies/add-user',
        element: <AddUserToCompanyView />,
    },
    {
        path: '/remote-config',
        element: <RemoteConfigView />,
    },
    {
        path: '/currency-config',
        element: <CurrencyConfigView />,
    },
];
