import styles from './edit-invoice.module.scss';
import NumberUtil from '../../utils/number.util';
import DeleteIcon from '../../icons/delete-icon/delete.icon';
import { ReactElement, useEffect, useState } from 'react';
import { InvoiceItem } from '../../types/invoice-item.type';
import { Invoice } from '../../types/invoice.type';
import RemoveInvoiceItemModal from '../remove-invoice-item-modal/remove-invoice-item-modal.component';
import { invoiceSdk } from '../../services/invoice.service';
import NewInvoiceItemModal from '../new-invoice-item-modal/new-invoice-item-modal.component';
import { EditableInvoiceItem } from '../../types/editable-invoice-item.type';
import EditIcon from '../../icons/edit-icon/edit.icon';
import Modal from '../modal/modal.component';
import InvoiceStatus from '../../constants/invoice.status';
import ResponseCodes from '../../constants/response.codes';

interface Props {
    mode: string;
    invoice: Invoice;
    onCancelling: () => void;
}

const EditInvoice = (props: Props) => {
    const [editableInvoice, setEditableInvoice] = useState<Invoice>();
    const [items, setItems] = useState<EditableInvoiceItem[]>([]);
    const [newItemModalShown, setNewItemModalShown] = useState(false);
    const [itemToRemove, setItemToRemove] = useState<EditableInvoiceItem>();
    const [isUpdated, setIsUpdated] = useState(false);
    const [cancellingInvoice, setCancellingInvoice] = useState(false);

    useEffect(() => {
        setEditableInvoice(props.invoice);
        mapItems(props.invoice.items);
    }, []);

    useEffect(() => {
        recalculateTotals();
        hasBeenUpdated();
    }, [items]);

    const mapItems = (invoiceItems: InvoiceItem[]) => {
        console.log(invoiceItems);
        setItems(
            invoiceItems.map((item) => ({
                ...item,
                removed: false,
                added: false,
            }))
        );
    };

    const buildItemRows = (
        items: EditableInvoiceItem[]
    ): (ReactElement | undefined)[] => {
        return items.map((item, idx) => {
            if (!item.removed) {
                return (
                    <tr key={idx} className={styles.itemRow}>
                        <td className={styles.itemDescriptionCol}>
                            <span>{item.description || item.itemableType}</span>
                        </td>
                        <td className={styles.itemNumberCol}>
                            <span>{item.quantity}</span>
                        </td>
                        <td className={styles.itemNumberCol}>
                            <b>
                                {NumberUtil.formatCurrency(
                                    item.subtotal,
                                    'USD'
                                )}
                            </b>
                        </td>
                        <td className={styles.itemActionCol}>
                            <DeleteIcon
                                className={styles.itemAction}
                                size={18}
                                onClick={() => setItemToRemove(item)}
                            />
                        </td>
                    </tr>
                );
            }
        });
    };

    const dismissRemoveModal = () => {
        setItemToRemove(undefined);
    };

    const removeItem = (itemId: number | string) => {
        const updatedItemList = items.map((item) => {
            if (item.temporalId && item.temporalId === itemId) {
                item.removed = true;
            } else if (item.id === itemId) {
                item.removed = true;
            }

            return item;
        });
        setItems(updatedItemList);
        setItemToRemove(undefined);
    };

    const dismissNewItemModal = () => {
        setNewItemModalShown(false);
    };

    const addNewItem = (newItem: EditableInvoiceItem) => {
        setItems((prev) => [...prev, newItem]);
    };

    const recalculateTotals = () => {
        console.log('recalculating totals...');
        let invoiceSubtotal = 0;
        let invoiceTax = 0;
        let invoiceTotal = 0;

        for (const item of items) {
            invoiceSubtotal += Number(item.subtotal);
            invoiceTax += Number(item.tax);
            invoiceTotal += Number(item.total);
        }

        setEditableInvoice((prev) => ({
            ...prev!,
            subtotal: invoiceSubtotal,
            tax: invoiceTax,
            total: invoiceTotal,
        }));
    };

    const hasBeenUpdated = (): void => {
        for (const item of items) {
            if (item.added || item.removed) {
                return setIsUpdated(true);
            }
        }

        return setIsUpdated(false);
    };

    const requestInvoiceCancelling = () => {
        setCancellingInvoice(true);
    };

    const discardInvoiceCancelling = () => {
        setCancellingInvoice(false);
    };

    const cancelInvoice = async () => {
        setCancellingInvoice(false);
        const response = await invoiceSdk.updateStatus(
            props.invoice.id,
            InvoiceStatus.CANCELLED
        );
        if (response.code === ResponseCodes.PROCESS_OK) {
            props.onCancelling();
        }
    };

    return (
        <main className={styles.wrapper}>
            <div className={styles.invoice}>
                <div className={styles.who}>
                    <h5>Factura para</h5>
                    <h4>
                        {props.invoice.payer.firstName}{' '}
                        {props.invoice.payer.lastName ?? ''}
                    </h4>
                </div>
                <table>
                    <tbody>
                        <tr className={styles.itemRow}>
                            <td className={styles.itemDescriptionCol}>
                                <label>Items:</label>
                            </td>
                            <td className={styles.itemNumberCol}>
                                <label>Cantidad:</label>
                            </td>
                            <td className={styles.itemNumberCol}>
                                <label>Subtotal:</label>
                            </td>
                        </tr>
                        {buildItemRows(items)}
                        <tr style={{ marginTop: '10px' }}>
                            <td style={{ width: '100%' }}>
                                <button
                                    className={styles.addNewItemButton}
                                    onClick={() => setNewItemModalShown(true)}
                                >
                                    <span>+</span>
                                    <span>Añadir</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody className={styles.totals}>
                        <tr>
                            <td>
                                <span>Subtotal:</span>
                            </td>
                            <td>
                                <b>
                                    {NumberUtil.formatCurrency(
                                        editableInvoice?.subtotal || 0,
                                        'USD'
                                    )}
                                </b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Impuestos:</span>
                            </td>
                            <td>
                                <b>
                                    {NumberUtil.formatCurrency(
                                        editableInvoice?.tax || 0,
                                        'USD'
                                    )}
                                </b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Total:</span>
                            </td>
                            <td>
                                <b>
                                    {NumberUtil.formatCurrency(
                                        editableInvoice?.total || 0,
                                        'USD'
                                    )}
                                </b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className={styles.invoiceButtons}>
                {isUpdated && (
                    <button className={styles.updateButton}>
                        <EditIcon color={'white'} size={14} />
                        <span>Actualizar factura</span>
                    </button>
                )}
                <button
                    className={styles.cancelButton}
                    onClick={requestInvoiceCancelling}
                >
                    <DeleteIcon color={'white'} size={16} />
                    <span>Cancelar factura</span>
                </button>
            </div>

            {/* Remove item modal */}
            {itemToRemove && (
                <RemoveInvoiceItemModal
                    item={itemToRemove}
                    onDiscard={dismissRemoveModal}
                    onConfirm={removeItem}
                />
            )}

            {/* New item modal */}
            {newItemModalShown && (
                <NewInvoiceItemModal
                    onDiscard={dismissNewItemModal}
                    onConfirm={addNewItem}
                />
            )}

            {/* Cancel invoice modal */}
            {cancellingInvoice && (
                <Modal
                    onDiscard={discardInvoiceCancelling}
                    onConfirm={cancelInvoice}
                    confirmText={'Sí, cancelar factura'}
                    discardText={'No, mantener'}
                >
                    <div className={styles.cancelInvoiceModal}>
                        <h3>¿Seguro que deseas cancelar esta factura?</h3>
                        <span>Al cancelar una factura, no podrás:</span>
                        <ul>
                            <li>Modificar sus items</li>
                            <li>Añadir nuevos pagos</li>
                        </ul>
                    </div>
                </Modal>
            )}
        </main>
    );
};
export default EditInvoice;
