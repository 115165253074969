import { useLocation } from 'react-router-dom';

import MenuLink from '../menu-link/menu-link.component';
import LogoutIcon from '../../icons/logout.icon';
import { Theme } from '../../types/theme.type';
import PanaIcon from 'assets/img/pana-icon.svg';

import { menuItems } from './menu-item-config';
import styles from './menu.module.scss';
import { generateUUID } from '../../utils/uuid.util';

interface Props {
    theme?: Theme;
    onLogout: () => void;
}

const Menu = (props: Props) => {
    const location = useLocation();

    const isCurrentPath = (path: string) => {
        const currentPath = location.pathname;
        return currentPath === path;
    };

    return (
        <nav className={styles.menu}>
            <div>
                <header className={'flex align-center'}>
                    <img src={PanaIcon} alt={'pana-icon'} />
                    <h3>Backoffice</h3>
                </header>
                <ul>
                    {menuItems.map((item) => (
                        <MenuLink
                            key={generateUUID()}
                            here={isCurrentPath(item.path)}
                            path={item.path}
                            icon={item.icon}
                            label={item.label}
                        />
                    ))}
                </ul>
            </div>
            <button className={styles.logoutButton} onClick={props.onLogout}>
                <LogoutIcon size={18} />
                <span>Cerrar sesión</span>
            </button>
        </nav>
    );
};

export default Menu;
