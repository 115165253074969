import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { object, string } from 'yup';

import { authSdk } from '../../services/auth.service';
import { Credentials } from '../../types/credentials.type';
import ResponseCodes from '../../constants/response.codes';
import { useAuthStore, useThemeStore } from '../../store';
import CustomInput from '../../components/custom-input/custom-input.component';

import styles from './login.module.scss';

const loginSchema = object().shape({
    email: string().email().required(),
    password: string().min(8).required(),
});

const LoginView = () => {
    const navigate = useNavigate();
    const { user: authUser, login } = useAuthStore();
    const { theme } = useThemeStore();
    const [credentials, setCredentials] = useState<Credentials>({
        email: '',
        password: '',
    });
    const [buttonState, setButtonState] = useState({
        disabled: true,
        text: 'Iniciar sesión',
    });
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (authUser?.accessToken) {
            navigate('/', { replace: true });
        }
    }, [authUser]);

    useEffect(() => {
        checkCredentials();
    }, [credentials]);

    const logIn = async (ev: React.MouseEvent) => {
        ev.preventDefault();
        setErrorMessage('');
        setButtonState({
            text: 'Cargando...',
            disabled: true,
        });
        const response = await authSdk.logIn(credentials);
        if (response.code === 0) {
            login(response.data);
            navigate('/', { replace: true });
            return;
        }

        switch (response.code) {
            case ResponseCodes.INVALID_CREDENTIALS:
                setErrorMessage('Credenciales inválidas');
                break;
            default:
                setErrorMessage('Error inesperado. Inténtelo nuevamente.');
                break;
        }
        setButtonState({
            text: 'Iniciar sesión',
            disabled: false,
        });
    };

    const addCredential = (key: string, value: string) => {
        setCredentials((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const checkCredentials = () => {
        const isValid = loginSchema.isValidSync(credentials);
        setButtonState((prev) => ({
            ...prev,
            disabled: !isValid,
        }));
    };

    return (
        <main className={classNames(styles.view, styles[theme])}>
            <section>
                <article>
                    <h2>PANA BACKOFFICE</h2>
                    <h1>Veamos qué exploraremos hoy.</h1>
                    <h3>
                        Facturación, métricas y análisis de datos a tu
                        disposición. Todo en <u>tiempo real.</u>
                    </h3>

                    <div className={styles.listContainer}>
                        <ul>
                            <li>
                                <img />
                                <div>
                                    <h4>Usuarios</h4>
                                    <span>
                                        Aumento en la tasa de conversión
                                    </span>
                                </div>
                            </li>
                        </ul>
                        <div className={styles.dotsList}>
                            <span
                                className={classNames(
                                    styles.dot,
                                    styles.active
                                )}
                            />
                            <span className={styles.dot} />
                        </div>
                    </div>
                </article>
                <div className={styles.formWrapper}>
                    <header>
                        <h1>Inicio de sesión</h1>
                        <p>
                            Si no tienes una cuenta, pide ayuda a tu superior.
                        </p>
                    </header>

                    <form>
                        <div className={styles.inputContainer}>
                            <label>Email</label>
                            <CustomInput
                                type={'email'}
                                placeholder={'Correo'}
                                width={'100%'}
                                onChange={(email) =>
                                    addCredential('email', email)
                                }
                            />
                        </div>

                        <div className={styles.inputContainer}>
                            <label>Clave</label>
                            <CustomInput
                                type={'password'}
                                placeholder={'Contraseña'}
                                width={'100%'}
                                onChange={(password) =>
                                    addCredential('password', password)
                                }
                            />
                        </div>
                        <button onClick={logIn} disabled={buttonState.disabled}>
                            {buttonState.text}
                        </button>
                    </form>
                    {errorMessage && (
                        <span className={styles.errorMessage}>
                            {errorMessage}
                        </span>
                    )}
                </div>
            </section>
        </main>
    );
};

export default LoginView;
