import styles from './payments.module.scss';
import Header from '../../components/header/header.component';
import ModuleSummary from '../../components/module-summary/module-summary.component';
import { useEffect, useState } from 'react';
import ResponseCodes from '../../constants/response.codes';
import { paymentSdk } from '../../services/payment.service';
import NumberUtil, { Currency } from '../../utils/number.util';
import { Payment } from '../../types/payment.type';
import Table from '../../components/table/table.component';
import { TableRow } from '../../types/table-row.type';
import { mapPaymentStatus } from '../../utils/payment-status.util';
import { useNavigate } from 'react-router-dom';
import { biSdk } from '../../services/bi.service';
import BasicButton from '../../components/basic-button/basic-button.component';
import FilteringButton from '../../components/filtering-button/filtering-button.component';
import { cleanObject } from '../../utils/object.util';
import { useThemeStore } from '../../store';
import DownloadExcelButtonComponent from '../../components/download-excel-button/download-excel-button.component';

const TotalsMapper: { [key: string]: string } = {
    totalApproved: 'Pagos aprobados de la semana',
    totalPending: 'Total pagos pendientes de la semana',
    totalInMonth: 'Total pagos de la semana',
    totalRevenue: 'Revenue total',
};

const PaymentsView = () => {
    const navigate = useNavigate();
    const { theme } = useThemeStore();

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        count: 10,
    });
    const [tableRows, setTableRows] = useState<TableRow[]>([]);
    const [totalSummary, setTotalSummary] = useState([
        { label: 'Pagos aprobados de la semana', value: '-' },
        { label: 'Total pagos pendientes', value: '-' },
        { label: 'Total pagos de la semana', value: '-' },
        { label: 'Fecha último pago', value: '-' },
    ]);
    const [filters, setFilters] = useState<any>({});

    useEffect(() => {
        getTotals();
    }, []);

    useEffect(() => {
        fetchPayments({
            page: pagination.currentPage,
            count: pagination.count,
            ...cleanObject(filters),
        });
    }, [filters]);

    const getTotals = async () => {
        const response = await biSdk.getTotalPayments();
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('Error getting payments:', response);
        }
        const totals = response.data;
        const summary = Object.keys(totals).map((key) => ({
            label: TotalsMapper[key],
            value: totals[key],
        }));
        setTotalSummary(summary);
    };

    const fetchPayments = async (queryParams?: any) => {
        const response = await paymentSdk.getAll(queryParams);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('Error getting payments:', response);
        }
        setPagination((pagination) => ({
            ...pagination,
            currentPage: Number(response.data.page),
            totalPages:
                response.data.total === 0
                    ? 1
                    : Math.ceil(response.data.total / pagination.count),
        }));
        mapRows(response.data.result);
    };

    const mapRows = (payments: Payment[]) => {
        const paymentRow = payments.map((payment) => {
            return {
                id: payment.id,
                data: [
                    { type: 'number', value: payment.id },
                    {
                        type: 'string',
                        value: `${payment.payer?.firstName} ${
                            payment.payer?.lastName ?? ''
                        }`,
                    },
                    {
                        type: 'string',
                        value: NumberUtil.formatCurrency(
                            payment.amount * payment.exchangeRate,
                            (payment.currency as Currency) ?? 'USD'
                        ),
                    },
                    { type: 'status', value: mapPaymentStatus(payment.status) },
                    {
                        type: 'date',
                        value: new Date(payment.createdAt),
                    },
                ],
            };
        });

        setTableRows(paymentRow);
    };

    const goToPage = (page: number) => {
        if (page < 1 || page > pagination.totalPages) return;

        fetchPayments({
            page: page,
            count: 10,
        });
    };

    const goToCreatePayment = () => {
        return navigate('/payments/create');
    };

    const addFilter = (attr: string, value: string) => {
        if (!value || value.toString().trim().length === 0) {
            setFilters((prev: any) => ({ ...prev, [attr]: undefined }));
            return;
        }
        setFilters((prev: any) => ({ ...prev, [attr]: value }));
    };

    return (
        <main className={styles.view}>
            <Header
                mode={theme}
                title={'Pagos'}
                subtitle={'Aquí puedes ver el estado de los pagos'}
            />
            <ModuleSummary mode={theme} summary={totalSummary} />
            <article className={styles.mainButtonsContainer}>
                <BasicButton
                    text={'Registrar nuevo pago'}
                    onClick={goToCreatePayment}
                />
                <DownloadExcelButtonComponent entity={'payments'} />
            </article>
            <div className={'view-filtering-section'}>
                <span className={'filtering-label'}>Buscar por:</span>
                <FilteringButton
                    text={'N° Pago'}
                    onSetFilter={(id) => addFilter('id', id)}
                />
                <FilteringButton
                    text={'Monto'}
                    onSetFilter={(amount) => addFilter('amount', amount)}
                />
                <FilteringButton
                    text={'Fecha'}
                    type={'date'}
                    onSetFilter={(days) => addFilter('days', days)}
                />
            </div>
            <Table
                mode={theme}
                header={[
                    'N° Pago',
                    'Pagador',
                    'Total',
                    'Estado',
                    'Fecha creación',
                ]}
                data={tableRows}
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                onPageChange={goToPage}
                onItemSelected={(id) => navigate(`/payments/${id}`)}
            />
        </main>
    );
};

export default PaymentsView;
