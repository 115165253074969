import styles from './date-input.module.scss';
import { KeyboardEvent, useRef, useState } from 'react';
import classNames from 'classnames';

interface Props {
    height?: number;
    onDate?: (date: Date) => void;
}

const DateInput = (props: Props) => {
    const dayInputRef = useRef<HTMLInputElement | null>(null);
    const monthInputRef = useRef<HTMLInputElement | null>(null);
    const yearInputRef = useRef<HTMLInputElement | null>(null);

    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [isFocused, setIsFocused] = useState(false);

    const checkValidNumber = (ev: KeyboardEvent) => {
        if (isNaN(Number(ev.key))) return ev.preventDefault();
    };

    const checkValueOnPress = (ev: KeyboardEvent, maxValue: number) => {
        console.log(ev.target);
        checkValidNumber(ev);

        const currentValue = (ev.target as HTMLInputElement).value;
        const newNumber = ev.key.toString();
        if (Number(currentValue + newNumber) > maxValue) ev.preventDefault();
    };

    const checkDay = () => {
        const value = dayInputRef.current!.value;

        if (value.length >= 2 || Number(value) > 3) {
            dayInputRef.current!.blur();
            monthInputRef.current!.focus();
        }

        dayInputRef.current!.value = value;
        setDay(value);
    };

    const checkMonth = () => {
        const value = monthInputRef.current!.value;

        if (value.length >= 2 || Number(value) > 1) {
            monthInputRef.current!.blur();
            yearInputRef.current!.focus();
        }
        monthInputRef.current!.value = value;
        setMonth(value);
    };

    const onDayBlur = () => {
        setIsFocused(false);
        if (dayInputRef.current!.value === '') return;

        const value = `0${dayInputRef.current!.value}`.slice(-2);
        setTimeout(() => {
            dayInputRef.current!.value = value;
            setDay(value);
        }, 100);

        checkDateIsValid();
    };

    const onMonthBlur = () => {
        setIsFocused(false);
        if (monthInputRef.current!.value === '') return;

        const value = `0${monthInputRef.current!.value}`.slice(-2);
        setTimeout(() => {
            monthInputRef.current!.value = value;
            setMonth(value);
        }, 100);

        checkDateIsValid();
    };

    const onYearBlur = () => {
        setIsFocused(false);
        checkDateIsValid();
    };

    const checkDateIsValid = () => {
        if (day === '' || month === '' || year === '') {
            setIsValid(false);
            return;
        }

        const date = new Date(Number(year), Number(month), Number(day));
        setIsValid(true);
        props.onDate?.(date);
    };

    return (
        <div className={styles.container}>
            <span
                className={classNames({
                    [styles.allDateInput]: true,
                    [styles.error]: !isValid,
                    [styles.focused]: isFocused,
                })}
                style={{ height: props.height ?? 50 }}
            >
                <input
                    ref={dayInputRef}
                    className={styles.day}
                    type={'text'}
                    maxLength={2}
                    placeholder={'DD'}
                    onKeyPress={(ev) => checkValueOnPress(ev, 31)}
                    onKeyUp={checkDay}
                    onBlur={onDayBlur}
                    onFocus={() => setIsFocused(true)}
                />
                <span className={styles.slash}>/</span>
                <input
                    ref={monthInputRef}
                    className={styles.month}
                    type={'text'}
                    maxLength={2}
                    placeholder={'MM'}
                    onKeyPress={(ev) => checkValueOnPress(ev, 12)}
                    onKeyUp={checkMonth}
                    onBlur={onMonthBlur}
                    onFocus={() => setIsFocused(true)}
                />
                <span className={styles.slash}>/</span>
                <input
                    ref={yearInputRef}
                    className={styles.year}
                    type={'text'}
                    maxLength={4}
                    placeholder={'YYYY'}
                    onKeyPress={(ev) => checkValueOnPress(ev, 9999)}
                    onChange={(ev) => setYear(ev.target.value)}
                    onBlur={onYearBlur}
                    onFocus={() => setIsFocused(true)}
                />
            </span>
        </div>
    );
};

export default DateInput;
