import styles from './protected-route.module.scss';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Menu from '../menu/menu.component';
import { useAuthStore, useThemeStore } from '../../store';

const ProtectedRoute = () => {
    const navigate = useNavigate();
    const { theme } = useThemeStore();
    const { user: authUser, logout } = useAuthStore();

    useEffect(() => {
        if (!authUser?.accessToken) {
            navigate('/login', { replace: true });
        }
    }, [authUser]);

    const onLogout = () => {
        logout();
        return navigate('/login');
    };

    return authUser?.accessToken ? (
        <div id={styles.layout}>
            <Menu onLogout={onLogout} theme={theme} />
            <Outlet />
        </div>
    ) : (
        <Navigate to={'/login'} replace />
    );
};

export default ProtectedRoute;
