import { useState } from 'react';
import { object, string } from 'yup';

import Modal from '../modal/modal.component';
import CustomInput from '../custom-input/custom-input.component';
import HDivider from '../h-divider/h-divider.component';
import { CompanyCreation } from '../../types/company-creation.type';

import styles from './create-company.module.scss';

interface Props {
    onDiscard: () => void;
    onConfirm: (data: CompanyCreation) => void;
}

const schema = object({
    name: string().min(3).required(),
    contactName: string().min(3).required(),
    contactEmail: string().email().required(),
    contactNumber: string().min(5).required(),
    contactAddress: string().min(5).required(),
});

const CreateCompany = ({ onConfirm, onDiscard }: Props) => {
    const [form, setForm] = useState({
        name: '',
        contactName: '',
        contactEmail: '',
        contactNumber: '',
        contactAddress: '',
    });
    const [invalidForm, setInvalidForm] = useState<boolean>();

    const onCreateCompany = async () => {
        setInvalidForm(false);
        const valid = await schema.isValidSync(form);
        if (!valid) {
            return setInvalidForm(true);
        }
        onConfirm({
            name: form.name,
            contact: {
                name: form.contactName,
                email: form.contactEmail,
                phoneNumber: form.contactNumber,
                address: form.contactAddress,
            },
        });
    };

    const updateFormField = (key: string, value: string) => {
        setInvalidForm(undefined);
        setForm((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    return (
        <Modal
            onDiscard={onDiscard}
            onConfirm={onCreateCompany}
            confirmText={'Crear compañía'}
            discardText={'Descartar'}
        >
            <div className={styles.content}>
                <h3>Crear compañía</h3>
                <div className={styles.formElement}>
                    <label>Nombre de la companía</label>
                    <CustomInput
                        placeholder={'Introduce el nombre'}
                        width={'100%'}
                        fontSize={13}
                        onChange={(name) => updateFormField('name', name)}
                    />
                </div>
                <div className={styles.formElement}>
                    <label>Dirección de la companía</label>
                    <CustomInput
                        placeholder={'Introduce la dirección física'}
                        width={'100%'}
                        fontSize={13}
                        onChange={(address) =>
                            updateFormField('contactAddress', address)
                        }
                    />
                </div>
                <HDivider />
                <h5>Información de contacto</h5>
                <div className={styles.formRow}>
                    <div className={styles.formElement}>
                        <label>Nombre</label>
                        <CustomInput
                            placeholder={'Nombre del contacto'}
                            fontSize={13}
                            width={'100%'}
                            onChange={(contactName) =>
                                updateFormField('contactName', contactName)
                            }
                        />
                    </div>
                    <div className={styles.formElement}>
                        <label>Teléfono</label>
                        <CustomInput
                            placeholder={'Teléfono de contacto'}
                            fontSize={13}
                            width={'100%'}
                            onChange={(number) =>
                                updateFormField('contactNumber', number)
                            }
                        />
                    </div>
                </div>
                <div className={styles.formElement}>
                    <label>Correo electrónico</label>
                    <CustomInput
                        placeholder={'Correo de contacto'}
                        fontSize={13}
                        width={'100%'}
                        onChange={(email) =>
                            updateFormField('contactEmail', email)
                        }
                    />
                </div>
                {invalidForm && (
                    <span className={styles.invalidForm}>
                        Error en los campos. Por favor revisa e intenta
                        nuevamente.
                    </span>
                )}
            </div>
        </Modal>
    );
};

export default CreateCompany;
