import styles from './coupon-graphic.module.scss';
import { useEffect, useRef, useState } from 'react';

interface Props {
    discount: number;
    code?: string;
}

const CouponGraphic = (props: Props) => {
    const hiddenCodeValueRef = useRef<HTMLHeadingElement | null>(null);
    const [codeScale, setCodeScale] = useState(1);

    useEffect(() => {
        recalculateCodeValueWidth();
    }, [props.code]);

    /**
     * This helps to keep the code value in the same box
     * without overflows the container with
     */
    const recalculateCodeValueWidth = () => {
        const textWidth =
            hiddenCodeValueRef.current!.getBoundingClientRect().width;
        if (textWidth <= 150) {
            setCodeScale(1);
            return;
        }
        setCodeScale(150 / textWidth);
    };

    return (
        <div className={styles.coupon}>
            <span className={styles.reverseText}>CUPÓN</span>
            <h3 className={styles.discount}>{props.discount}% OFF</h3>
            <div className={styles.code}>
                <h2 ref={hiddenCodeValueRef} className={styles.hiddenValue}>
                    {props.code ? props.code.toUpperCase() : '(ALEATORIO)'}
                </h2>
                <h2
                    style={{
                        transform: `scaleX(${codeScale})`,
                    }}
                >
                    {props.code ? props.code.toUpperCase() : '(ALEATORIO)'}
                </h2>
            </div>
        </div>
    );
};

export default CouponGraphic;
