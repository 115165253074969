import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import CouponGraphic from '../../components/coupon-graphic/coupon-graphic.component';
import CouponCreationForm from '../../components/coupon-creation-form/coupon-creation-form.component';
import { CouponRequest } from '../../types/coupon-request.type';
import CircleCheckIcon from '../../icons/circle-check-icon/circle-check.icon';
import CircleErrorIcon from '../../icons/circle-error-icon/circle-error.icon';
import Header from '../../components/header/header.component';
import GoBackView from '../../components/go-back-view/go-back-view';
import { useServiceStore, useThemeStore } from '../../store';
import ResponseCodes from '../../constants/response.codes';
import { couponSdk } from '../../services/coupon.service';

import styles from './crete-coupon.module.scss';

type Notification = {
    shown: boolean;
    text: string;
    icon: ReactElement;
};

const CreateCouponView = () => {
    const navigate = useNavigate();
    const { theme } = useThemeStore();
    const { plans, fetchPlans } = useServiceStore();
    const [code, setCode] = useState<string | undefined>('');
    const [discount, setDiscount] = useState(0);
    const [clearForm, setClearForm] = useState(false);
    const [notification, setNotification] = useState<Notification>({
        shown: false,
        text: 'Cupón creado exitosamente',
        icon: <CircleCheckIcon size={20} color={'#5CCDAD'} />,
    });
    const [creationError, setCreationError] = useState<boolean | undefined>();

    useEffect(() => {
        fetchPlans({ active: true, isPublic: true });
    }, []);

    const showNotification = (text: string, success: boolean) => {
        setNotification({
            shown: true,
            text: text,
            icon: success ? (
                <CircleCheckIcon size={20} color={'#5CCDAD'} />
            ) : (
                <CircleErrorIcon size={20} color={'#fa6c6c'} />
            ),
        });

        setTimeout(() => {
            setNotification((prev) => ({ ...prev, shown: false }));
        }, 6000);
    };

    const createCoupon = async (coupon: CouponRequest) => {
        setCreationError(false);
        const response = await couponSdk.createCoupon(coupon);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            showNotification(response.message, false);
            setCreationError(true);
            return;
        }
        showNotification('Cupón creado exitosamente', true);
        clearAllForm();
        navigate(-1);
    };

    const clearAllForm = () => {
        setClearForm(true);
        setTimeout(() => {
            setClearForm(false);
        }, 100);
    };

    return (
        <main className={styles.view}>
            <Header
                mode={theme}
                title={'Crear cupón'}
                subtitle={'Creemos un cupón promocional'}
            />
            <section>
                <GoBackView />
                <div className={styles.couponContainer}>
                    <CouponGraphic discount={discount} code={code} />
                </div>

                <CouponCreationForm
                    plans={plans}
                    onChangeCode={setCode}
                    onChangeDiscount={setDiscount}
                    onRequestCreation={createCoupon}
                    clearForm={clearForm}
                    hasError={creationError}
                />
            </section>
            <div
                className={classNames({
                    [styles.notification]: true,
                    [styles.shown]: notification.shown,
                })}
            >
                {notification.icon}
                <p>{notification.text}</p>
            </div>
        </main>
    );
};

export default CreateCouponView;
