import styles from './search-user-input.module.scss';
import { ReactElement, useRef, useState } from 'react';
import { User } from '../../types/user.type';
import classNames from 'classnames';

interface Props {
    results: any[];
    mode?: string;
    placeholder?: string;
    width?: number;
    onSearch: (value: string) => void;
    onSelect: (user: User) => void;
    onClear?: () => void;
}

const SearchUserInput = (props: Props) => {
    let searchTimeout: NodeJS.Timeout;

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [activeSearch, setActiveSearch] = useState(false);

    const clearResults = () => {
        setActiveSearch(false);
    };

    const onSearch = (value: string) => {
        if (searchTimeout) clearTimeout(searchTimeout);
        if (!value || value.length < 3) {
            props.onClear?.();
            return clearResults();
        }

        searchTimeout = setTimeout(() => {
            setActiveSearch(true);
            props.onSearch(value);
        }, 800);
    };

    const renderResults = (users: User[]): ReactElement | ReactElement[] => {
        if (users.length === 0) {
            return (
                <li>
                    <span className={styles.name}>
                        No se encontraron usuarios
                    </span>
                </li>
            );
        }
        return users.map((user, idx) => {
            return (
                <li key={idx} onClick={() => selectUser(user)}>
                    <span className={styles.name}>
                        {user.firstName} {user.lastName}
                    </span>
                    <span className={styles.email}>
                        {user.email || '(Sin correo)'}
                    </span>
                </li>
            );
        });
    };

    const selectUser = (user: User) => {
        if (inputRef.current) {
            inputRef.current.value = `${user.firstName} ${user.lastName}`;
        }
        props.onSelect(user);
        clearResults();
    };

    return (
        <div
            className={styles.wrapper}
            style={{ width: props.width ?? '100%' }}
        >
            <input
                ref={inputRef}
                type={'text'}
                placeholder={props.placeholder}
                className={classNames({
                    [styles.searchUserInput]: true,
                    [styles.activeSearch]: activeSearch,
                })}
                onChange={(ev) => onSearch(ev.target.value.trim())}
            />
            {activeSearch && (
                <ul className={styles.resultList}>
                    {renderResults(props.results)}
                </ul>
            )}
        </div>
    );
};

export default SearchUserInput;
