import styles from './separator.module.scss';

interface Props {
    height?: number;
}

const Separator = (props: Props) => {
    return (
        <div
            className={styles.separator}
            style={{ height: props.height ?? 1 }}
        />
    );
};

export default Separator;
