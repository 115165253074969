import styles from './invoice.module.scss';
import Header from '../../components/header/header.component';
import { useEffect, useState } from 'react';
import { Invoice } from '../../types/invoice.type';
import { useParams } from 'react-router-dom';
import { invoiceSdk } from '../../services/invoice.service';
import ResponseCodes from '../../constants/response.codes';
import InvoiceDetails from '../../components/invoice-details/invoice-details.component';
import EditInvoice from '../../components/edit-invoice/edit-invoice.component';
import InvoiceStatus from '../../constants/invoice.status';
import Modal from '../../components/modal/modal.component';
import GoBackView from '../../components/go-back-view/go-back-view';
import { useThemeStore } from '../../store';

const InvoiceView = () => {
    const { ['id']: invoiceId } = useParams();
    const { theme } = useThemeStore();

    const [invoice, setInvoice] = useState<Invoice>();
    const [editing, setEditing] = useState(false);
    const [errorEditing, setErrorEditing] = useState(false);

    useEffect(() => {
        fetchInvoiceDetails(Number(invoiceId));
    }, [invoiceId]);

    const fetchInvoiceDetails = async (id: number) => {
        const response = await invoiceSdk.getById(id);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.log('error getting invoice details:', response);
            return;
        }

        setInvoice(response.data as Invoice);
    };

    const showInvoiceEdition = () => {
        if (invoice && invoice.status === InvoiceStatus.CANCELLED) {
            return setErrorEditing(true);
        }

        setEditing(true);
    };

    const onCancelling = () => {
        setEditing(false);
        fetchInvoiceDetails(Number(invoiceId));
    };

    return (
        <main className={styles.view}>
            <Header
                mode={theme}
                title={'Facturas'}
                subtitle={'Aquí puedes ver el estado de todas las facturas'}
            />
            <div className={styles.container}>
                <GoBackView />
                <h2>📃 Factura: {invoice?.id}</h2>

                <div className={styles.mainContent}>
                    {/* Invoice details */}
                    {invoice && !editing && (
                        <InvoiceDetails
                            invoice={invoice}
                            onEditing={showInvoiceEdition}
                        />
                    )}

                    {/* Invoice editing */}
                    {invoice && editing && (
                        <EditInvoice
                            mode={theme}
                            invoice={invoice}
                            onCancelling={onCancelling}
                        />
                    )}
                </div>
            </div>

            {/* Modal when trying to edit a cancelled invoice */}
            {errorEditing && (
                <Modal
                    onConfirm={() => setErrorEditing(false)}
                    confirmText={'Entendido'}
                >
                    <span className={styles.errorEditingModal}>
                        Esta factura está cancelada. Para poder editarla
                        comunícate con el departamento de IT.
                    </span>
                </Modal>
            )}
        </main>
    );
};

export default InvoiceView;
