import styles from './payment.module.scss';
import Header from '../../components/header/header.component';
import GoBackView from '../../components/go-back-view/go-back-view';
import { Payment } from '../../types/payment.type';
import { ReactElement, useEffect, useState } from 'react';
import { paymentSdk } from '../../services/payment.service';
import { useParams } from 'react-router-dom';
import ResponseCodes from '../../constants/response.codes';
import { mapPaymentMethodIcon } from '../../utils/payment-methods.util';
import NumberUtil, { Currency } from '../../utils/number.util';
import classNames from 'classnames';
import { mapPaymentStatusToComponent } from '../../utils/payment-status.util';
import PaymentStatus from '../../constants/payment.status';
import CheckIcon from '../../icons/check.icon';
import LinkWithIcon from '../../components/link-with-icon/link-with-icon';
import RevertIcon from '../../icons/revert-icon/revert.icon';
import CloseIcon from '../../icons/close-icon/close.icon';
import Modal from '../../components/modal/modal.component';
import Loader from '../../components/loader/loader';
import NotFoundImage from '../../assets/img/payment-not-found.png';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useThemeStore } from '../../store';

const PaymentView = () => {
    const { ['id']: paymentId } = useParams();
    const { theme } = useThemeStore();

    const [payment, setPayment] = useState<Payment | undefined>();
    const [notFound, setNotFound] = useState(false);
    const [nextStatus, setNextStatus] = useState<number | undefined>();
    const [confirmationModalShown, setConfirmationModalShown] = useState(false);
    const [loaderModalShown, setLoaderModalShown] = useState(false);

    useEffect(() => {
        fetchPayment();
    }, []);

    const fetchPayment = async () => {
        const response = await paymentSdk.getById(Number(paymentId));
        if (response.code !== ResponseCodes.PROCESS_OK) {
            setNotFound(response.code === ResponseCodes.ENTITY_NOT_FOUND);
            return console.error(response);
        }
        setPayment(response.data);
    };

    const formatAmount = (amount?: number, currency?: string): string => {
        return NumberUtil.formatCurrency(
            amount ?? 0,
            (currency as Currency) ?? 'USD'
        );
    };

    const showStatusUpdateOptions = (status?: number): ReactElement | null => {
        if (
            status === undefined ||
            ![PaymentStatus.WAITING_APPROVAL, PaymentStatus.CREATED].includes(
                status
            )
        ) {
            return null;
        }
        return (
            <div className={styles.statusUpdateOptions}>
                <span
                    className={styles.statusOption}
                    onClick={() =>
                        onRequestStatusUpdate(PaymentStatus.APPROVED)
                    }
                >
                    Confirmar
                    <div
                        className={styles.iconContainer}
                        style={{ backgroundColor: '#D5F7DB' }}
                    >
                        <CheckIcon size={12} color={'#07AF25'} />
                    </div>
                </span>
                <span
                    className={styles.statusOption}
                    onClick={() =>
                        onRequestStatusUpdate(PaymentStatus.REJECTED)
                    }
                >
                    Rechazar
                    <div
                        className={styles.iconContainer}
                        style={{ backgroundColor: '#f7d5d5' }}
                    >
                        <CloseIcon size={14} stroke={3} color={'#af1507'} />
                    </div>
                </span>
                <span
                    className={styles.statusOption}
                    onClick={() =>
                        onRequestStatusUpdate(PaymentStatus.REFUNDED)
                    }
                >
                    Reembolsar
                    <div
                        className={styles.iconContainer}
                        style={{ backgroundColor: '#dfdeff' }}
                    >
                        <RevertIcon size={14} color={'#635bff'} stroke={3} />
                    </div>
                </span>
            </div>
        );
    };

    const onRequestStatusUpdate = (status: number) => {
        setNextStatus(status);
        setConfirmationModalShown(true);
    };

    const onConfirmStatusUpdate = async (status: number) => {
        setConfirmationModalShown(false);
        setLoaderModalShown(true);

        const response = await paymentSdk.updatePayment(payment!.id, {
            status,
        });
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.error('err:', response);
        }

        setLoaderModalShown(false);
        setPayment(response.data);
    };

    const renderNotFound = (): JSX.Element => {
        return (
            <div className={'flex-column align-center'} style={{ gap: 20 }}>
                <img
                    src={NotFoundImage}
                    style={{ width: 80 }}
                    alt={'payment-not-found'}
                />
                <h5 className={styles.notFound}>El pago no existe</h5>
            </div>
        );
    };

    return (
        <main className={styles.view}>
            <Header mode={theme} title={'Detalles del pago'} />
            <GoBackView />
            <div className={styles.container}>
                {notFound ? (
                    renderNotFound()
                ) : (
                    <section className={styles.paymentWrapper}>
                        <header className={styles.header}>
                            <div className={'row space-between'}>
                                <div className={'column'}>
                                    <div
                                        className={'row align-center'}
                                        style={{ gap: 14 }}
                                    >
                                        <img
                                            className={styles.methodIcon}
                                            alt={'payment-method-icon'}
                                            src={mapPaymentMethodIcon(
                                                payment?.gateway.code
                                            )}
                                        />
                                        <span className={styles.title}>
                                            {payment?.gateway.displayName}
                                        </span>
                                    </div>
                                </div>
                                <div className={'column'}>
                                    <span className={styles.date}>
                                        {payment?.createdAt &&
                                            format(
                                                new Date(payment.createdAt),
                                                'dd MMMM, yyyy',
                                                { locale: es }
                                            )}
                                    </span>
                                </div>
                            </div>
                            <div
                                className={classNames({
                                    row: true,
                                    [styles.amount]: true,
                                })}
                            >
                                {formatAmount(
                                    (payment?.amount ?? 0) *
                                        (payment?.exchangeRate ?? 1),
                                    payment?.currency
                                )}
                            </div>
                            <div className={'row'}>
                                <span className={styles.status}>
                                    {mapPaymentStatusToComponent(
                                        payment?.status
                                    )}
                                </span>
                            </div>
                        </header>

                        <section className={styles.moreDetails}>
                            <div
                                className={'row align-center'}
                                style={{ gap: 6 }}
                            >
                                <b>Pagador:</b>
                                <LinkWithIcon
                                    link={`/users/${payment?.payer?.id}`}
                                >
                                    {'' + payment?.payer?.firstName}
                                </LinkWithIcon>
                            </div>
                            <div className={'row'}>
                                <span>
                                    <b>Código de referencia:</b>{' '}
                                    {payment?.referenceCode}
                                </span>
                            </div>
                        </section>

                        {showStatusUpdateOptions(payment?.status)}
                    </section>
                )}
            </div>
            {confirmationModalShown && (
                <Modal
                    target={nextStatus}
                    onConfirm={onConfirmStatusUpdate}
                    onDiscard={() => setConfirmationModalShown(false)}
                >
                    <div className={styles.confirmationModal}>
                        <h4>¿Estás seguro?</h4>
                        <span>
                            Estás a punto de actualizar el estado del pago.
                            Confirma si deseas realizar esta acción.
                        </span>
                    </div>
                </Modal>
            )}
            {loaderModalShown && (
                <div className={styles.loaderModal}>
                    <div className={styles.modal}>
                        <Loader color={'#999'} />
                    </div>
                </div>
            )}
        </main>
    );
};

export default PaymentView;
