const generateDownloadableFile = (file: any, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.setAttribute('download', fileName);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
};

export { generateDownloadableFile };
