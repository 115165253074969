import styles from './user-invoices-details.module.scss';
import { Invoice } from '../../types/invoice.type';
import NumberUtil from '../../utils/number.util';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { mapInvoiceStatus } from '../../utils/invoice-status.util';
import { Theme } from '../../types/theme.type';

interface Props {
    theme?: Theme;
    invoices?: Invoice[];
}

const UserInvoicesDetails = (props: Props) => {
    const renderStatus = (status: number) => {
        const statusObj = mapInvoiceStatus(status);

        return (
            <span
                className={styles.status}
                style={{
                    backgroundColor: statusObj.bgColor,
                    color: statusObj.txtColor,
                }}
            >
                {statusObj.value}
            </span>
        );
    };

    const renderInvoices = (invoices?: Invoice[]) => {
        if (!invoices || invoices.length === 0)
            return (
                <p className={styles.noInvoices}>
                    No hay historial de facturas.
                </p>
            );

        return (
            <ul className={styles.list}>
                {invoices.map((invoice, idx) => {
                    return (
                        <li key={idx}>
                            <div className={styles.row}>
                                <h4>
                                    {NumberUtil.formatCurrency(
                                        invoice.total,
                                        'USD'
                                    )}
                                </h4>
                                {renderStatus(invoice.status)}
                            </div>
                            <div className={styles.row}>
                                <span className={styles.date}>
                                    {format(
                                        new Date(invoice.createdAt),
                                        'dd MMMM, yyyy',
                                        { locale: es }
                                    )}
                                </span>
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <section className={styles.wrapper}>
            <h3>Facturas</h3>
            {renderInvoices(props.invoices)}
        </section>
    );
};

export default UserInvoicesDetails;
