import styles from './create-invoice.module.scss';
import Header from '../../components/header/header.component';
import UserIcon from '../../icons/user-icon/user.icon';
import SearchUserInputComponent from '../../components/search-user-input/search-user-input.component';
import ResponseCodes from '../../constants/response.codes';
import { userSdk } from '../../services/user.service';
import { useEffect, useState } from 'react';
import { User } from '../../types/user.type';
import PaymentIcon from '../../icons/payment-icon/payment.icon';
import PaperIcon from '../../icons/paper-icon/paper-icon';
import CustomSelect from '../../components/custom-select/custom-select.component';
import CustomInput from '../../components/custom-input/custom-input.component';
import { usePaymentStore, useServiceStore, useThemeStore } from '../../store';
import { Service } from '../../types/service.type';
import BasicButton from '../../components/basic-button/basic-button.component';
import { number, object, string } from 'yup';
import PaymentStatus from '../../constants/payment.status';

const CreateInvoiceView = () => {
    const { theme } = useThemeStore();
    const paymentStore = usePaymentStore();
    const serviceStore = useServiceStore();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [itemId, setItemId] = useState(0);
    const [payment, setPayment] = useState({
        amount: 0,
        currency: '',
        status: PaymentStatus.APPROVED,
        methodId: 0,
    });
    const [validForm, setValidForm] = useState(false);

    useEffect(() => {
        paymentStore.fetchMethod();
        serviceStore.fetchServices();
    }, []);

    useEffect(() => {
        checkForm();
    }, [selectedUser, itemId, payment]);

    const checkForm = async () => {
        const schema = object({
            userId: number().min(1).required(),
            itemId: number().min(1).optional(),
            payment: object({
                amount: number().min(1).required(),
                methodId: number().min(1).required(),
                currency: string().min(3).required(),
            }),
        });
        console.log({
            userId: selectedUser?.id,
            itemId: itemId,
            payment: payment,
        });
        const valid = await schema.isValid({
            userId: selectedUser?.id,
            itemId: itemId,
            payment: payment,
        });
        setValidForm(valid);
    };

    const searchUsers = async (value: string) => {
        const response = await userSdk.search(value);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return console.error('error fetching users:', response);
        }
        setUsers(response.data);
    };

    const mapItems = (
        services: Service[]
    ): { displayText: string; value: any }[] => {
        const plans = [];
        for (const service of services) {
            for (const plan of service.plans!) {
                plans.push({
                    displayText: plan.description,
                    value: plan.id,
                });
            }
        }

        return plans;
    };

    const createInvoice = async () => {
        const data = {
            userId: selectedUser?.id,
            itemId: itemId,
            payment: payment,
        };
        console.log('data:', data);
    };

    return (
        <main className={styles.view}>
            <Header mode={theme} title={'Crear factura'} />
            <section className={styles.formWrapper}>
                <form>
                    <div className={styles.formField}>
                        <div className={styles.formFieldTitle}>
                            <div className={styles.iconWrapper}>
                                <UserIcon size={16} color={'#07AF25'} />
                            </div>
                            <h3>A nombre de</h3>
                        </div>
                        <p className={styles.formFieldDescription}>
                            Indica para quién va diriga la factura. Puedes
                            buscar al cliente por nombre, correo, cédula o
                            teléfono
                        </p>
                        <SearchUserInputComponent
                            mode={theme}
                            results={users}
                            onSearch={searchUsers}
                            onSelect={setSelectedUser}
                            onClear={() => setSelectedUser(undefined)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <div className={styles.formFieldTitle}>
                            <div className={styles.iconWrapper}>
                                <PaperIcon size={16} color={'#07AF25'} />
                            </div>
                            <h3>Items</h3>
                        </div>
                        <p className={styles.formFieldDescription}>
                            Selecciona un producto
                        </p>
                        <CustomSelect
                            options={mapItems(serviceStore.services)}
                            placeholder={'Selecciona un ítem'}
                            onSelect={setItemId}
                            width={200}
                        />
                    </div>
                    <div className={styles.formField}>
                        <div className={styles.formFieldTitle}>
                            <div className={styles.iconWrapper}>
                                <PaymentIcon size={16} color={'#07AF25'} />
                            </div>
                            <h3>Pago</h3>
                        </div>
                        <p className={styles.formFieldDescription}>
                            Crea un nuevo pago para esta factura
                        </p>
                        <div className={'flex'} style={{ gap: 10 }}>
                            <CustomInput
                                type={'number'}
                                placeholder={'monto'}
                                onChange={(amount) =>
                                    setPayment((prev) => ({
                                        ...prev,
                                        amount: Number(amount),
                                    }))
                                }
                            />
                            <CustomSelect
                                options={paymentStore.currencies.map(
                                    (currency) => ({
                                        displayText: currency.currency,
                                        value: currency.id,
                                    })
                                )}
                                placeholder={'Moneda'}
                                onSelect={(currency) =>
                                    setPayment((prev) => ({
                                        ...prev,
                                        currency,
                                    }))
                                }
                            />
                            <CustomSelect
                                options={paymentStore.methods.map((method) => ({
                                    value: method.id,
                                    displayText: method.displayName,
                                }))}
                                placeholder={'Método de pago'}
                                width={160}
                                onSelect={(methodId) =>
                                    setPayment((prev) => ({
                                        ...prev,
                                        methodId,
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <BasicButton
                        text={'Crear factura'}
                        disabled={!validForm}
                        onClick={createInvoice}
                    />
                </form>
            </section>
        </main>
    );
};

export default CreateInvoiceView;
